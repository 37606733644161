export default {
  data() {
    return {
      middlePlatformAdTrackingUrl: `${process.env.MIDDLE_PLATFORM_URL}/integration/ad-tracking/hash`,  
      middlePlatformLGMTrackingUrl: `${process.env.MIDDLE_PLATFORM_URL}/integration/lead-conversion-tracking/hash`,  
    };
  },
  mounted() {
    //
  },
  methods: {
    async getAdTrackingHash() {
      const uid = this.getUid();
      const adsId = this.getAdsId();
      const lgmadsId = this.getLgmadsId();
      try {
        if(!uid && !adsId && !lgmadsId) throw new Error('Params missing. uid, adsId and lgmadsId are missing.')
        const data ={
          "atk_id": adsId ? `${adsId}` : undefined,
          "up_id": uid,
          "apm": lgmadsId ? `${lgmadsId}` : undefined,
        }
        const response = await this.$axios.post(this.middlePlatformAdTrackingUrl, data)
        const hash = response.data.hash;
        return hash;
      } catch (error) {
        console.error('error: ', error)
        this.$sentry.captureException(error);
      }
    },
    async getLGMTrackingHash(lgmParams) {
      const uid = this.getUid();
      const adsId = this.getAdsId();
      const lgmadsId = this.getLgmadsId();
      const utmVisual = this.getUtmVisual();
      try {
        const data ={
          "atk_id": adsId ? `${adsId}` : undefined,
          "up_id": uid,
          "xr_id": lgmParams?.xr_id ? `${lgmParams.xr_id}` : undefined,
          "apm": lgmadsId ? `${lgmadsId}` : undefined,
          "epg": lgmParams.epg ? lgmParams.epg : undefined,
          "dpg": lgmParams.dpg ? lgmParams.dpg : undefined,
          "uvs": utmVisual ? utmVisual : undefined,
          "lm": lgmParams.lgmMeta ? lgmParams.lgmMeta : undefined,
          "ir": lgmParams?.ir ? `${lgmParams.ir}` : undefined,
        }
        const response = await this.$axios.post(this.middlePlatformLGMTrackingUrl, data)
        const hash = response.data.hash;
        return hash;
      } catch (error) {
        console.error('error: ', error)
        this.$sentry.captureException(error);
      }
    },
    getUid() {
      const gaID = this.$cookies.get('_ga');
      try {
        const match = gaID.match(/.*\.[\d]\.(\d+\.\d+)$/);
        return match[1];
      } catch (error) {
        console.error('error: ', error)
        this.$sentry.captureException(error);
      }
      return undefined;
    },
    getAdsId() {
      return this.$cookies.get('_10life_ads')
    },
    getLgmadsId() {
      return this.$cookies.get('_10life_lgmads')
    },
    getUtmVisual() {
      return this.$cookies.get('_10life_utmVisual')
    },
  },
}