
import globalEventListenersMixin from '~/mixins/globalEventListeners.js';
import fetchNavigationData from '~/mixins/fetchNavigationData.js';
import defaultHead from '~/mixins/defaultHead.js';
import globalCookies from '~/mixins/globalCookies.js';
import popupBannerMixin from '~/mixins/popupBannerMixins.js';

export default {
  name: 'WhiteLayout',
  mixins: [globalEventListenersMixin, fetchNavigationData, defaultHead, globalCookies, popupBannerMixin],

  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-apps',
          title: 'Welcome',
          to: '/',
        },
        {
          icon: 'mdi-chart-bubble',
          title: 'Inspire',
          to: '/inspire',
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js',
    };
  },

  // in case you want to prevent Nuxt from handling the error
  // you can un-comment below and execute the logic you want
  // ref: https://github.com/nuxt/docs/issues/1748
  /*
  errorCaptured(error, vm, info) {
    console.debug("error intercepted");
    console.error(error, vm, info);
    return false; // prevent Nuxt from handling the error
  },
  */
};
