

  export default {
    name: 'VideoComponent',

    props: {
        /**
         * videoSource: The source of the video. To be used to determine which video to display.
         * possible values: self_hosted, other, youtube
         */
        videoSource: {
            type: String,
            default: "",
        },

        videoLink: {
            type: String,
            default: "",
        },

        self_hosted_video_url: {
            type: String,
            default: "",
        },
        other_video_link: {
            type: String,
            default: "",
        },
        youtube_link: {
            type: String,
            default: "https://www.youtube.com/embed/9bEEZmVJggI",
        },
        
    },

    methods: {
        getVideoSrcLink() {
            if (this.videoSource === 'self_hosted' || this.videoSource === 'other' || this.videoSource === 'youtube') {
                return this.videoLink;
            } else {
                if (this.self_hosted_video_url) return this.self_hosted_video_url;
                if (this.other_video_link) return this.other_video_link;
                if (this.youtube_link) return this.youtube_link;
                return null;
            }
        }
    },

    data() {
      return {
      }
    },
  }
