
export default {
  props: {
    navigationContentType: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({
        AboutUs: {},
        Broker: {},
        Decoder: {},
        Lifestage: {},
      }),
    },
  },
};
