import popupBannerQuery from '@/apollo/queries/general/popupBannerQuery';

export default {
  apollo: {
    $loadingKey: 'loading',
    popupBanner: {
      query: popupBannerQuery,
      prefetch: true,
      variables() {
        return { currentlocale: this.$normalizeLocale(this.$i18n.locale) };
      },
      update(data) {
        return this.$normalizeStrapi(data.popupBanner);
      }
    }
  },

  computed: {

  },

  methods: {
    showPopupBanner() {
      if (process.client) {
        // Check if the modal has already been shown during the current session
        let modalShown = sessionStorage.getItem('popupBannerShown');

        // If the modal has not been shown, schedule it to be shown after certain time
        if (!modalShown) {
          if (this.popupBanner && this.popupBanner.seconds) {
            const showTime = this.popupBanner?.seconds * 1000;

            // Set a flag in session storage to indicate that the modal has been shown
            // This needs to be set before setTimeout to prevent showing more than 1 time when I go to other page before the modal shows
            sessionStorage.setItem('popupBannerShown', 'true');

            setTimeout(() => {
              // Show the modal
              this.$store.commit('modal/setShowPopupBannerModal', true);
            }, showTime)
          }
        }
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.showPopupBanner();
    });
  }
}
