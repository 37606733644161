import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _22139184 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _2ee03552 = () => interopDefault(import('../pages/5star-products/index.vue' /* webpackChunkName: "pages/5star-products/index" */))
const _5ed9f8e6 = () => interopDefault(import('../pages/5star-products-2025/index.vue' /* webpackChunkName: "pages/5star-products-2025/index" */))
const _e9b98470 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _bf9f6aa4 = () => interopDefault(import('../pages/advisor-service.vue' /* webpackChunkName: "pages/advisor-service" */))
const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _442485af = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _0c5a8d6b = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _24c6c615 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _42cff4fc = () => interopDefault(import('../pages/happy-kids-2024/index.vue' /* webpackChunkName: "pages/happy-kids-2024/index" */))
const _30465947 = () => interopDefault(import('../pages/latest-promotion.vue' /* webpackChunkName: "pages/latest-promotion" */))
const _00591dd7 = () => interopDefault(import('../pages/life-stage.vue' /* webpackChunkName: "pages/life-stage" */))
const _72c0d926 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _0fb615b5 = () => interopDefault(import('../pages/my-favourite/index.vue' /* webpackChunkName: "pages/my-favourite/index" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _6a13e351 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _4b6e9eef = () => interopDefault(import('../pages/qdap/index.vue' /* webpackChunkName: "pages/qdap/index" */))
const _78f5fa2e = () => interopDefault(import('../pages/scoring-methodology/index.vue' /* webpackChunkName: "pages/scoring-methodology/index" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _e8e59c78 = () => interopDefault(import('../pages/transition-checkout.vue' /* webpackChunkName: "pages/transition-checkout" */))
const _23a60a40 = () => interopDefault(import('../pages/hong-kong-insurance/index.vue' /* webpackChunkName: "pages/hong-kong-insurance/index" */))
const _416a27b5 = () => interopDefault(import('../pages/blog/404.vue' /* webpackChunkName: "pages/blog/404" */))
const _5f22b63b = () => interopDefault(import('../pages/blog/search.vue' /* webpackChunkName: "pages/blog/search" */))
const _49dbb7a0 = () => interopDefault(import('../pages/products/deferred-annuity/index.vue' /* webpackChunkName: "pages/products/deferred-annuity/index" */))
const _2d9d6e9a = () => interopDefault(import('../pages/products/home/index.vue' /* webpackChunkName: "pages/products/home/index" */))
const _76798220 = () => interopDefault(import('../pages/products/immediate-annuity/index.vue' /* webpackChunkName: "pages/products/immediate-annuity/index" */))
const _fdec55ea = () => interopDefault(import('../pages/products/long-term-savings/index.vue' /* webpackChunkName: "pages/products/long-term-savings/index" */))
const _72d8ab5a = () => interopDefault(import('../pages/products/medical/index.vue' /* webpackChunkName: "pages/products/medical/index" */))
const _3858083e = () => interopDefault(import('../pages/products/personal-accident/index.vue' /* webpackChunkName: "pages/products/personal-accident/index" */))
const _c913dcc6 = () => interopDefault(import('../pages/products/short-term-savings/index.vue' /* webpackChunkName: "pages/products/short-term-savings/index" */))
const _03c3897a = () => interopDefault(import('../pages/products/term-cancer/index.vue' /* webpackChunkName: "pages/products/term-cancer/index" */))
const _2ffea4d6 = () => interopDefault(import('../pages/products/term-critical-illness/index.vue' /* webpackChunkName: "pages/products/term-critical-illness/index" */))
const _1c3aca82 = () => interopDefault(import('../pages/products/term-life/index.vue' /* webpackChunkName: "pages/products/term-life/index" */))
const _4ae78aee = () => interopDefault(import('../pages/products/travel/index.vue' /* webpackChunkName: "pages/products/travel/index" */))
const _42188faf = () => interopDefault(import('../pages/products/whole-life-critical-illness/index.vue' /* webpackChunkName: "pages/products/whole-life-critical-illness/index" */))
const _2b6135f6 = () => interopDefault(import('../pages/products/whole-life-protection/index.vue' /* webpackChunkName: "pages/products/whole-life-protection/index" */))
const _6d068d30 = () => interopDefault(import('../pages/happy-kids-2024/cost-of-raising-a-kid-game/index.vue' /* webpackChunkName: "pages/happy-kids-2024/cost-of-raising-a-kid-game/index" */))
const _384ead06 = () => interopDefault(import('../pages/products/home/compare.vue' /* webpackChunkName: "pages/products/home/compare" */))
const _d723819a = () => interopDefault(import('../pages/products/immediate-annuity/compare.vue' /* webpackChunkName: "pages/products/immediate-annuity/compare" */))
const _3c35835e = () => interopDefault(import('../pages/products/long-term-savings/compare.vue' /* webpackChunkName: "pages/products/long-term-savings/compare" */))
const _469f26a6 = () => interopDefault(import('../pages/products/medical/compare.vue' /* webpackChunkName: "pages/products/medical/compare" */))
const _58c3b5d1 = () => interopDefault(import('../pages/products/personal-accident/compare.vue' /* webpackChunkName: "pages/products/personal-accident/compare" */))
const _6c84e370 = () => interopDefault(import('../pages/products/short-term-savings/compare.vue' /* webpackChunkName: "pages/products/short-term-savings/compare" */))
const _746d88d4 = () => interopDefault(import('../pages/products/term-cancer/compare.vue' /* webpackChunkName: "pages/products/term-cancer/compare" */))
const _7e4f3d30 = () => interopDefault(import('../pages/products/term-critical-illness/compare.vue' /* webpackChunkName: "pages/products/term-critical-illness/compare" */))
const _4c18a7dc = () => interopDefault(import('../pages/products/term-life/compare.vue' /* webpackChunkName: "pages/products/term-life/compare" */))
const _057d4c81 = () => interopDefault(import('../pages/products/travel/compare.vue' /* webpackChunkName: "pages/products/travel/compare" */))
const _16ffb5fc = () => interopDefault(import('../pages/products/whole-life-critical-illness/compare.vue' /* webpackChunkName: "pages/products/whole-life-critical-illness/compare" */))
const _a38f54ee = () => interopDefault(import('../pages/products/whole-life-protection/compare.vue' /* webpackChunkName: "pages/products/whole-life-protection/compare" */))
const _1636ef6c = () => interopDefault(import('../pages/happy-kids-2024/cost-of-raising-a-kid-game/test.vue' /* webpackChunkName: "pages/happy-kids-2024/cost-of-raising-a-kid-game/test" */))
const _01c4dc2f = () => interopDefault(import('../pages/products/deferred-annuity/growth_retirement_income/compare.vue' /* webpackChunkName: "pages/products/deferred-annuity/growth_retirement_income/compare" */))
const _ac89b4f6 = () => interopDefault(import('../pages/products/deferred-annuity/longevity/compare.vue' /* webpackChunkName: "pages/products/deferred-annuity/longevity/compare" */))
const _6dc20b46 = () => interopDefault(import('../pages/products/deferred-annuity/savings/compare.vue' /* webpackChunkName: "pages/products/deferred-annuity/savings/compare" */))
const _17c182a3 = () => interopDefault(import('../pages/products/deferred-annuity/stable_retirement_income/compare.vue' /* webpackChunkName: "pages/products/deferred-annuity/stable_retirement_income/compare" */))
const _5fd48cd4 = () => interopDefault(import('../pages/products/deferred-annuity/growth_retirement_income/_slug.vue' /* webpackChunkName: "pages/products/deferred-annuity/growth_retirement_income/_slug" */))
const _d34d98ac = () => interopDefault(import('../pages/products/deferred-annuity/longevity/_slug.vue' /* webpackChunkName: "pages/products/deferred-annuity/longevity/_slug" */))
const _ec4742fc = () => interopDefault(import('../pages/products/deferred-annuity/savings/_slug.vue' /* webpackChunkName: "pages/products/deferred-annuity/savings/_slug" */))
const _e41f7f70 = () => interopDefault(import('../pages/products/deferred-annuity/stable_retirement_income/_slug.vue' /* webpackChunkName: "pages/products/deferred-annuity/stable_retirement_income/_slug" */))
const _4ff73f59 = () => interopDefault(import('../pages/blog/author/_slug.vue' /* webpackChunkName: "pages/blog/author/_slug" */))
const _28b03768 = () => interopDefault(import('../pages/blog/category/_slug.vue' /* webpackChunkName: "pages/blog/category/_slug" */))
const _310ab12a = () => interopDefault(import('../pages/products/home/_slug.vue' /* webpackChunkName: "pages/products/home/_slug" */))
const _74c2e0d8 = () => interopDefault(import('../pages/products/immediate-annuity/_slug.vue' /* webpackChunkName: "pages/products/immediate-annuity/_slug" */))
const _7f5333c3 = () => interopDefault(import('../pages/products/long-term-savings/_slug.vue' /* webpackChunkName: "pages/products/long-term-savings/_slug" */))
const _7645edea = () => interopDefault(import('../pages/products/medical/_slug.vue' /* webpackChunkName: "pages/products/medical/_slug" */))
const _36a166f6 = () => interopDefault(import('../pages/products/personal-accident/_slug.vue' /* webpackChunkName: "pages/products/personal-accident/_slug" */))
const _cc811f56 = () => interopDefault(import('../pages/products/short-term-savings/_slug.vue' /* webpackChunkName: "pages/products/short-term-savings/_slug" */))
const _0730cc0a = () => interopDefault(import('../pages/products/term-cancer/_slug.vue' /* webpackChunkName: "pages/products/term-cancer/_slug" */))
const _336be766 = () => interopDefault(import('../pages/products/term-critical-illness/_slug.vue' /* webpackChunkName: "pages/products/term-critical-illness/_slug" */))
const _1fa80d12 = () => interopDefault(import('../pages/products/term-life/_slug.vue' /* webpackChunkName: "pages/products/term-life/_slug" */))
const _4930e9a6 = () => interopDefault(import('../pages/products/travel/_slug.vue' /* webpackChunkName: "pages/products/travel/_slug" */))
const _4061ee67 = () => interopDefault(import('../pages/products/whole-life-critical-illness/_slug.vue' /* webpackChunkName: "pages/products/whole-life-critical-illness/_slug" */))
const _29aa94ae = () => interopDefault(import('../pages/products/whole-life-protection/_slug.vue' /* webpackChunkName: "pages/products/whole-life-protection/_slug" */))
const _692218c5 = () => interopDefault(import('../pages/5star-products-2025/_any/index.vue' /* webpackChunkName: "pages/5star-products-2025/_any/index" */))
const _f29edb38 = () => interopDefault(import('../pages/5star-products/_year/index.vue' /* webpackChunkName: "pages/5star-products/_year/index" */))
const _9d03b872 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _0aa3ec23 = () => interopDefault(import('../pages/career/_slug.vue' /* webpackChunkName: "pages/career/_slug" */))
const _401fba57 = () => interopDefault(import('../pages/faq/_slug.vue' /* webpackChunkName: "pages/faq/_slug" */))
const _0c849694 = () => interopDefault(import('../pages/happy-kids-2024/_any/index.vue' /* webpackChunkName: "pages/happy-kids-2024/_any/index" */))
const _6bfbfe6c = () => interopDefault(import('../pages/insurance-guide/_slug.vue' /* webpackChunkName: "pages/insurance-guide/_slug" */))
const _1a845f3e = () => interopDefault(import('../pages/insurer/_code/index.vue' /* webpackChunkName: "pages/insurer/_code/index" */))
const _762e1bb6 = () => interopDefault(import('../pages/media/_slug.vue' /* webpackChunkName: "pages/media/_slug" */))
const _d6d88c08 = () => interopDefault(import('../pages/qdap/_year/index.vue' /* webpackChunkName: "pages/qdap/_year/index" */))
const _e9f9ca2e = () => interopDefault(import('../pages/scoring-methodology/_slug/index.vue' /* webpackChunkName: "pages/scoring-methodology/_slug/index" */))
const _6bafc00d = () => interopDefault(import('../pages/5star-products/_year/insurer/index.vue' /* webpackChunkName: "pages/5star-products/_year/insurer/index" */))
const _0b0a455e = () => interopDefault(import('../pages/5star-products/_year/insurer/_insurer/index.vue' /* webpackChunkName: "pages/5star-products/_year/insurer/_insurer/index" */))
const _31e77da8 = () => interopDefault(import('../pages/qdap/_year/products/_slug/index.vue' /* webpackChunkName: "pages/qdap/_year/products/_slug/index" */))
const _1fc91f9b = () => interopDefault(import('../pages/5star-products-2025/_any/_any2/index.vue' /* webpackChunkName: "pages/5star-products-2025/_any/_any2/index" */))
const _34bd969b = () => interopDefault(import('../pages/5star-products/_year/_productCat/index.vue' /* webpackChunkName: "pages/5star-products/_year/_productCat/index" */))
const _9536e5c4 = () => interopDefault(import('../pages/insurer/_code/_cat/index.vue' /* webpackChunkName: "pages/insurer/_code/_cat/index" */))
const _2fbaeee0 = () => interopDefault(import('../pages/5star-products/_year/_productCat/_productSubCat/index.vue' /* webpackChunkName: "pages/5star-products/_year/_productCat/_productSubCat/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/zh-CN",
    component: _2dfb1658,
    name: "index___zh-CN"
  }, {
    path: "/zh-HK",
    component: _2dfb1658,
    name: "index___zh-HK"
  }, {
    path: "/en/404",
    component: _22139184,
    name: "404___en"
  }, {
    path: "/en/5star-products",
    component: _2ee03552,
    name: "5star-products___en"
  }, {
    path: "/en/5star-products-2025",
    component: _5ed9f8e6,
    name: "5star-products-2025___en"
  }, {
    path: "/en/about-us",
    component: _e9b98470,
    name: "about-us___en"
  }, {
    path: "/en/advisor-service",
    component: _bf9f6aa4,
    name: "advisor-service___en"
  }, {
    path: "/en/blog",
    component: _999675e2,
    name: "blog___en"
  }, {
    path: "/en/callback",
    component: _442485af,
    name: "callback___en"
  }, {
    path: "/en/career",
    component: _0c5a8d6b,
    name: "career___en"
  }, {
    path: "/en/cookies",
    component: _24c6c615,
    name: "cookies___en"
  }, {
    path: "/en/happy-kids-2024",
    component: _42cff4fc,
    name: "happy-kids-2024___en"
  }, {
    path: "/en/latest-promotion",
    component: _30465947,
    name: "latest-promotion___en"
  }, {
    path: "/en/life-stage",
    component: _00591dd7,
    name: "life-stage___en"
  }, {
    path: "/en/media",
    component: _72c0d926,
    name: "media___en"
  }, {
    path: "/en/my-favourite",
    component: _0fb615b5,
    name: "my-favourite___en"
  }, {
    path: "/en/privacy",
    component: _37e8fae4,
    name: "privacy___en"
  }, {
    path: "/en/products",
    component: _6a13e351,
    name: "products___en"
  }, {
    path: "/en/qdap",
    component: _4b6e9eef,
    name: "qdap___en"
  }, {
    path: "/en/scoring-methodology",
    component: _78f5fa2e,
    name: "scoring-methodology___en"
  }, {
    path: "/en/terms",
    component: _500e56e6,
    name: "terms___en"
  }, {
    path: "/en/transition-checkout",
    component: _e8e59c78,
    name: "transition-checkout___en"
  }, {
    path: "/zh-CN/404",
    component: _22139184,
    name: "404___zh-CN"
  }, {
    path: "/zh-CN/5star-products-2025",
    component: _5ed9f8e6,
    name: "5star-products-2025___zh-CN"
  }, {
    path: "/zh-CN/about-us",
    component: _e9b98470,
    name: "about-us___zh-CN"
  }, {
    path: "/zh-CN/advisor-service",
    component: _bf9f6aa4,
    name: "advisor-service___zh-CN"
  }, {
    path: "/zh-CN/blog",
    component: _999675e2,
    name: "blog___zh-CN"
  }, {
    path: "/zh-CN/callback",
    component: _442485af,
    name: "callback___zh-CN"
  }, {
    path: "/zh-CN/cookies",
    component: _24c6c615,
    name: "cookies___zh-CN"
  }, {
    path: "/zh-CN/hong-kong-insurance",
    component: _23a60a40,
    name: "hong-kong-insurance___zh-CN"
  }, {
    path: "/zh-CN/life-stage",
    component: _00591dd7,
    name: "life-stage___zh-CN"
  }, {
    path: "/zh-CN/media",
    component: _72c0d926,
    name: "media___zh-CN"
  }, {
    path: "/zh-CN/privacy",
    component: _37e8fae4,
    name: "privacy___zh-CN"
  }, {
    path: "/zh-CN/qdap",
    component: _4b6e9eef,
    name: "qdap___zh-CN"
  }, {
    path: "/zh-CN/scoring-methodology",
    component: _78f5fa2e,
    name: "scoring-methodology___zh-CN"
  }, {
    path: "/zh-CN/terms",
    component: _500e56e6,
    name: "terms___zh-CN"
  }, {
    path: "/zh-CN/transition-checkout",
    component: _e8e59c78,
    name: "transition-checkout___zh-CN"
  }, {
    path: "/zh-HK/404",
    component: _22139184,
    name: "404___zh-HK"
  }, {
    path: "/zh-HK/5star-products",
    component: _2ee03552,
    name: "5star-products___zh-HK"
  }, {
    path: "/zh-HK/5star-products-2025",
    component: _5ed9f8e6,
    name: "5star-products-2025___zh-HK"
  }, {
    path: "/zh-HK/about-us",
    component: _e9b98470,
    name: "about-us___zh-HK"
  }, {
    path: "/zh-HK/advisor-service",
    component: _bf9f6aa4,
    name: "advisor-service___zh-HK"
  }, {
    path: "/zh-HK/blog",
    component: _999675e2,
    name: "blog___zh-HK"
  }, {
    path: "/zh-HK/callback",
    component: _442485af,
    name: "callback___zh-HK"
  }, {
    path: "/zh-HK/career",
    component: _0c5a8d6b,
    name: "career___zh-HK"
  }, {
    path: "/zh-HK/cookies",
    component: _24c6c615,
    name: "cookies___zh-HK"
  }, {
    path: "/zh-HK/happy-kids-2024",
    component: _42cff4fc,
    name: "happy-kids-2024___zh-HK"
  }, {
    path: "/zh-HK/latest-promotion",
    component: _30465947,
    name: "latest-promotion___zh-HK"
  }, {
    path: "/zh-HK/life-stage",
    component: _00591dd7,
    name: "life-stage___zh-HK"
  }, {
    path: "/zh-HK/media",
    component: _72c0d926,
    name: "media___zh-HK"
  }, {
    path: "/zh-HK/my-favourite",
    component: _0fb615b5,
    name: "my-favourite___zh-HK"
  }, {
    path: "/zh-HK/privacy",
    component: _37e8fae4,
    name: "privacy___zh-HK"
  }, {
    path: "/zh-HK/products",
    component: _6a13e351,
    name: "products___zh-HK"
  }, {
    path: "/zh-HK/qdap",
    component: _4b6e9eef,
    name: "qdap___zh-HK"
  }, {
    path: "/zh-HK/scoring-methodology",
    component: _78f5fa2e,
    name: "scoring-methodology___zh-HK"
  }, {
    path: "/zh-HK/terms",
    component: _500e56e6,
    name: "terms___zh-HK"
  }, {
    path: "/zh-HK/transition-checkout",
    component: _e8e59c78,
    name: "transition-checkout___zh-HK"
  }, {
    path: "/en/blog/404",
    component: _416a27b5,
    name: "blog-404___en"
  }, {
    path: "/en/blog/search",
    component: _5f22b63b,
    name: "blog-search___en"
  }, {
    path: "/en/products/deferred-annuity",
    component: _49dbb7a0,
    name: "products-deferred-annuity___en"
  }, {
    path: "/en/products/home",
    component: _2d9d6e9a,
    name: "products-home___en"
  }, {
    path: "/en/products/immediate-annuity",
    component: _76798220,
    name: "products-immediate-annuity___en"
  }, {
    path: "/en/products/long-term-savings",
    component: _fdec55ea,
    name: "products-long-term-savings___en"
  }, {
    path: "/en/products/medical",
    component: _72d8ab5a,
    name: "products-medical___en"
  }, {
    path: "/en/products/personal-accident",
    component: _3858083e,
    name: "products-personal-accident___en"
  }, {
    path: "/en/products/short-term-savings",
    component: _c913dcc6,
    name: "products-short-term-savings___en"
  }, {
    path: "/en/products/term-cancer",
    component: _03c3897a,
    name: "products-term-cancer___en"
  }, {
    path: "/en/products/term-critical-illness",
    component: _2ffea4d6,
    name: "products-term-critical-illness___en"
  }, {
    path: "/en/products/term-life",
    component: _1c3aca82,
    name: "products-term-life___en"
  }, {
    path: "/en/products/travel",
    component: _4ae78aee,
    name: "products-travel___en"
  }, {
    path: "/en/products/whole-life-critical-illness",
    component: _42188faf,
    name: "products-whole-life-critical-illness___en"
  }, {
    path: "/en/products/whole-life-protection",
    component: _2b6135f6,
    name: "products-whole-life-protection___en"
  }, {
    path: "/zh-CN/blog/404",
    component: _416a27b5,
    name: "blog-404___zh-CN"
  }, {
    path: "/zh-CN/blog/search",
    component: _5f22b63b,
    name: "blog-search___zh-CN"
  }, {
    path: "/zh-HK/blog/404",
    component: _416a27b5,
    name: "blog-404___zh-HK"
  }, {
    path: "/zh-HK/blog/search",
    component: _5f22b63b,
    name: "blog-search___zh-HK"
  }, {
    path: "/zh-HK/happy-kids-2024/cost-of-raising-a-kid-game",
    component: _6d068d30,
    name: "happy-kids-2024-cost-of-raising-a-kid-game___zh-HK"
  }, {
    path: "/zh-HK/products/deferred-annuity",
    component: _49dbb7a0,
    name: "products-deferred-annuity___zh-HK"
  }, {
    path: "/zh-HK/products/home",
    component: _2d9d6e9a,
    name: "products-home___zh-HK"
  }, {
    path: "/zh-HK/products/immediate-annuity",
    component: _76798220,
    name: "products-immediate-annuity___zh-HK"
  }, {
    path: "/zh-HK/products/long-term-savings",
    component: _fdec55ea,
    name: "products-long-term-savings___zh-HK"
  }, {
    path: "/zh-HK/products/medical",
    component: _72d8ab5a,
    name: "products-medical___zh-HK"
  }, {
    path: "/zh-HK/products/personal-accident",
    component: _3858083e,
    name: "products-personal-accident___zh-HK"
  }, {
    path: "/zh-HK/products/short-term-savings",
    component: _c913dcc6,
    name: "products-short-term-savings___zh-HK"
  }, {
    path: "/zh-HK/products/term-cancer",
    component: _03c3897a,
    name: "products-term-cancer___zh-HK"
  }, {
    path: "/zh-HK/products/term-critical-illness",
    component: _2ffea4d6,
    name: "products-term-critical-illness___zh-HK"
  }, {
    path: "/zh-HK/products/term-life",
    component: _1c3aca82,
    name: "products-term-life___zh-HK"
  }, {
    path: "/zh-HK/products/travel",
    component: _4ae78aee,
    name: "products-travel___zh-HK"
  }, {
    path: "/zh-HK/products/whole-life-critical-illness",
    component: _42188faf,
    name: "products-whole-life-critical-illness___zh-HK"
  }, {
    path: "/zh-HK/products/whole-life-protection",
    component: _2b6135f6,
    name: "products-whole-life-protection___zh-HK"
  }, {
    path: "/en/products/home/compare",
    component: _384ead06,
    name: "products-home-compare___en"
  }, {
    path: "/en/products/immediate-annuity/compare",
    component: _d723819a,
    name: "products-immediate-annuity-compare___en"
  }, {
    path: "/en/products/long-term-savings/compare",
    component: _3c35835e,
    name: "products-long-term-savings-compare___en"
  }, {
    path: "/en/products/medical/compare",
    component: _469f26a6,
    name: "products-medical-compare___en"
  }, {
    path: "/en/products/personal-accident/compare",
    component: _58c3b5d1,
    name: "products-personal-accident-compare___en"
  }, {
    path: "/en/products/short-term-savings/compare",
    component: _6c84e370,
    name: "products-short-term-savings-compare___en"
  }, {
    path: "/en/products/term-cancer/compare",
    component: _746d88d4,
    name: "products-term-cancer-compare___en"
  }, {
    path: "/en/products/term-critical-illness/compare",
    component: _7e4f3d30,
    name: "products-term-critical-illness-compare___en"
  }, {
    path: "/en/products/term-life/compare",
    component: _4c18a7dc,
    name: "products-term-life-compare___en"
  }, {
    path: "/en/products/travel/compare",
    component: _057d4c81,
    name: "products-travel-compare___en"
  }, {
    path: "/en/products/whole-life-critical-illness/compare",
    component: _16ffb5fc,
    name: "products-whole-life-critical-illness-compare___en"
  }, {
    path: "/en/products/whole-life-protection/compare",
    component: _a38f54ee,
    name: "products-whole-life-protection-compare___en"
  }, {
    path: "/zh-HK/happy-kids-2024/cost-of-raising-a-kid-game/test",
    component: _1636ef6c,
    name: "happy-kids-2024-cost-of-raising-a-kid-game-test___zh-HK"
  }, {
    path: "/zh-HK/products/home/compare",
    component: _384ead06,
    name: "products-home-compare___zh-HK"
  }, {
    path: "/zh-HK/products/immediate-annuity/compare",
    component: _d723819a,
    name: "products-immediate-annuity-compare___zh-HK"
  }, {
    path: "/zh-HK/products/long-term-savings/compare",
    component: _3c35835e,
    name: "products-long-term-savings-compare___zh-HK"
  }, {
    path: "/zh-HK/products/medical/compare",
    component: _469f26a6,
    name: "products-medical-compare___zh-HK"
  }, {
    path: "/zh-HK/products/personal-accident/compare",
    component: _58c3b5d1,
    name: "products-personal-accident-compare___zh-HK"
  }, {
    path: "/zh-HK/products/short-term-savings/compare",
    component: _6c84e370,
    name: "products-short-term-savings-compare___zh-HK"
  }, {
    path: "/zh-HK/products/term-cancer/compare",
    component: _746d88d4,
    name: "products-term-cancer-compare___zh-HK"
  }, {
    path: "/zh-HK/products/term-critical-illness/compare",
    component: _7e4f3d30,
    name: "products-term-critical-illness-compare___zh-HK"
  }, {
    path: "/zh-HK/products/term-life/compare",
    component: _4c18a7dc,
    name: "products-term-life-compare___zh-HK"
  }, {
    path: "/zh-HK/products/travel/compare",
    component: _057d4c81,
    name: "products-travel-compare___zh-HK"
  }, {
    path: "/zh-HK/products/whole-life-critical-illness/compare",
    component: _16ffb5fc,
    name: "products-whole-life-critical-illness-compare___zh-HK"
  }, {
    path: "/zh-HK/products/whole-life-protection/compare",
    component: _a38f54ee,
    name: "products-whole-life-protection-compare___zh-HK"
  }, {
    path: "/en/products/deferred-annuity/growth_retirement_income/compare",
    component: _01c4dc2f,
    name: "products-deferred-annuity-growth_retirement_income-compare___en"
  }, {
    path: "/en/products/deferred-annuity/longevity/compare",
    component: _ac89b4f6,
    name: "products-deferred-annuity-longevity-compare___en"
  }, {
    path: "/en/products/deferred-annuity/savings/compare",
    component: _6dc20b46,
    name: "products-deferred-annuity-savings-compare___en"
  }, {
    path: "/en/products/deferred-annuity/stable_retirement_income/compare",
    component: _17c182a3,
    name: "products-deferred-annuity-stable_retirement_income-compare___en"
  }, {
    path: "/zh-CN/products/deferred-annuity/growth_retirement_income/compare",
    component: _01c4dc2f,
    name: "products-deferred-annuity-growth_retirement_income-compare___zh-CN"
  }, {
    path: "/zh-CN/products/deferred-annuity/stable_retirement_income/compare",
    component: _17c182a3,
    name: "products-deferred-annuity-stable_retirement_income-compare___zh-CN"
  }, {
    path: "/zh-HK/products/deferred-annuity/growth_retirement_income/compare",
    component: _01c4dc2f,
    name: "products-deferred-annuity-growth_retirement_income-compare___zh-HK"
  }, {
    path: "/zh-HK/products/deferred-annuity/longevity/compare",
    component: _ac89b4f6,
    name: "products-deferred-annuity-longevity-compare___zh-HK"
  }, {
    path: "/zh-HK/products/deferred-annuity/savings/compare",
    component: _6dc20b46,
    name: "products-deferred-annuity-savings-compare___zh-HK"
  }, {
    path: "/zh-HK/products/deferred-annuity/stable_retirement_income/compare",
    component: _17c182a3,
    name: "products-deferred-annuity-stable_retirement_income-compare___zh-HK"
  }, {
    path: "/en/products/deferred-annuity/growth_retirement_income/:slug",
    component: _5fd48cd4,
    name: "products-deferred-annuity-growth_retirement_income-slug___en"
  }, {
    path: "/en/products/deferred-annuity/longevity/:slug",
    component: _d34d98ac,
    name: "products-deferred-annuity-longevity-slug___en"
  }, {
    path: "/en/products/deferred-annuity/savings/:slug",
    component: _ec4742fc,
    name: "products-deferred-annuity-savings-slug___en"
  }, {
    path: "/en/products/deferred-annuity/stable_retirement_income/:slug",
    component: _e41f7f70,
    name: "products-deferred-annuity-stable_retirement_income-slug___en"
  }, {
    path: "/zh-CN/products/deferred-annuity/growth_retirement_income/:slug",
    component: _5fd48cd4,
    name: "products-deferred-annuity-growth_retirement_income-slug___zh-CN"
  }, {
    path: "/zh-CN/products/deferred-annuity/stable_retirement_income/:slug",
    component: _e41f7f70,
    name: "products-deferred-annuity-stable_retirement_income-slug___zh-CN"
  }, {
    path: "/zh-HK/products/deferred-annuity/growth_retirement_income/:slug",
    component: _5fd48cd4,
    name: "products-deferred-annuity-growth_retirement_income-slug___zh-HK"
  }, {
    path: "/zh-HK/products/deferred-annuity/longevity/:slug",
    component: _d34d98ac,
    name: "products-deferred-annuity-longevity-slug___zh-HK"
  }, {
    path: "/zh-HK/products/deferred-annuity/savings/:slug",
    component: _ec4742fc,
    name: "products-deferred-annuity-savings-slug___zh-HK"
  }, {
    path: "/zh-HK/products/deferred-annuity/stable_retirement_income/:slug",
    component: _e41f7f70,
    name: "products-deferred-annuity-stable_retirement_income-slug___zh-HK"
  }, {
    path: "/en/blog/author/:slug?",
    component: _4ff73f59,
    name: "blog-author-slug___en"
  }, {
    path: "/en/blog/category/:slug?",
    component: _28b03768,
    name: "blog-category-slug___en"
  }, {
    path: "/en/products/home/:slug",
    component: _310ab12a,
    name: "products-home-slug___en"
  }, {
    path: "/en/products/immediate-annuity/:slug?",
    component: _74c2e0d8,
    name: "products-immediate-annuity-slug___en"
  }, {
    path: "/en/products/long-term-savings/:slug?",
    component: _7f5333c3,
    name: "products-long-term-savings-slug___en"
  }, {
    path: "/en/products/medical/:slug",
    component: _7645edea,
    name: "products-medical-slug___en"
  }, {
    path: "/en/products/personal-accident/:slug?",
    component: _36a166f6,
    name: "products-personal-accident-slug___en"
  }, {
    path: "/en/products/short-term-savings/:slug?",
    component: _cc811f56,
    name: "products-short-term-savings-slug___en"
  }, {
    path: "/en/products/term-cancer/:slug?",
    component: _0730cc0a,
    name: "products-term-cancer-slug___en"
  }, {
    path: "/en/products/term-critical-illness/:slug?",
    component: _336be766,
    name: "products-term-critical-illness-slug___en"
  }, {
    path: "/en/products/term-life/:slug?",
    component: _1fa80d12,
    name: "products-term-life-slug___en"
  }, {
    path: "/en/products/travel/:slug",
    component: _4930e9a6,
    name: "products-travel-slug___en"
  }, {
    path: "/en/products/whole-life-critical-illness/:slug?",
    component: _4061ee67,
    name: "products-whole-life-critical-illness-slug___en"
  }, {
    path: "/en/products/whole-life-protection/:slug?",
    component: _29aa94ae,
    name: "products-whole-life-protection-slug___en"
  }, {
    path: "/zh-CN/blog/author/:slug?",
    component: _4ff73f59,
    name: "blog-author-slug___zh-CN"
  }, {
    path: "/zh-CN/blog/category/:slug?",
    component: _28b03768,
    name: "blog-category-slug___zh-CN"
  }, {
    path: "/zh-HK/blog/author/:slug?",
    component: _4ff73f59,
    name: "blog-author-slug___zh-HK"
  }, {
    path: "/zh-HK/blog/category/:slug?",
    component: _28b03768,
    name: "blog-category-slug___zh-HK"
  }, {
    path: "/zh-HK/products/home/:slug",
    component: _310ab12a,
    name: "products-home-slug___zh-HK"
  }, {
    path: "/zh-HK/products/immediate-annuity/:slug?",
    component: _74c2e0d8,
    name: "products-immediate-annuity-slug___zh-HK"
  }, {
    path: "/zh-HK/products/long-term-savings/:slug?",
    component: _7f5333c3,
    name: "products-long-term-savings-slug___zh-HK"
  }, {
    path: "/zh-HK/products/medical/:slug",
    component: _7645edea,
    name: "products-medical-slug___zh-HK"
  }, {
    path: "/zh-HK/products/personal-accident/:slug?",
    component: _36a166f6,
    name: "products-personal-accident-slug___zh-HK"
  }, {
    path: "/zh-HK/products/short-term-savings/:slug?",
    component: _cc811f56,
    name: "products-short-term-savings-slug___zh-HK"
  }, {
    path: "/zh-HK/products/term-cancer/:slug?",
    component: _0730cc0a,
    name: "products-term-cancer-slug___zh-HK"
  }, {
    path: "/zh-HK/products/term-critical-illness/:slug?",
    component: _336be766,
    name: "products-term-critical-illness-slug___zh-HK"
  }, {
    path: "/zh-HK/products/term-life/:slug?",
    component: _1fa80d12,
    name: "products-term-life-slug___zh-HK"
  }, {
    path: "/zh-HK/products/travel/:slug",
    component: _4930e9a6,
    name: "products-travel-slug___zh-HK"
  }, {
    path: "/zh-HK/products/whole-life-critical-illness/:slug?",
    component: _4061ee67,
    name: "products-whole-life-critical-illness-slug___zh-HK"
  }, {
    path: "/zh-HK/products/whole-life-protection/:slug?",
    component: _29aa94ae,
    name: "products-whole-life-protection-slug___zh-HK"
  }, {
    path: "/en/5star-products-2025/:any",
    component: _692218c5,
    name: "5star-products-2025-any___en"
  }, {
    path: "/en/5star-products/:year",
    component: _f29edb38,
    name: "5star-products-year___en"
  }, {
    path: "/en/blog/:slug",
    component: _9d03b872,
    name: "blog-slug___en"
  }, {
    path: "/en/career/:slug",
    component: _0aa3ec23,
    name: "career-slug___en"
  }, {
    path: "/en/faq/:slug?",
    component: _401fba57,
    name: "faq-slug___en"
  }, {
    path: "/en/happy-kids-2024/:any",
    component: _0c849694,
    name: "happy-kids-2024-any___en"
  }, {
    path: "/en/insurance-guide/:slug?",
    component: _6bfbfe6c,
    name: "insurance-guide-slug___en"
  }, {
    path: "/en/insurer/:code",
    component: _1a845f3e,
    name: "insurer-code___en"
  }, {
    path: "/en/media/:slug",
    component: _762e1bb6,
    name: "media-slug___en"
  }, {
    path: "/en/qdap/:year",
    component: _d6d88c08,
    name: "qdap-year___en"
  }, {
    path: "/en/scoring-methodology/:slug",
    component: _e9f9ca2e,
    name: "scoring-methodology-slug___en"
  }, {
    path: "/zh-CN/5star-products-2025/:any",
    component: _692218c5,
    name: "5star-products-2025-any___zh-CN"
  }, {
    path: "/zh-CN/5star-products/:year",
    component: _f29edb38,
    name: "5star-products-year___zh-CN"
  }, {
    path: "/zh-CN/blog/:slug",
    component: _9d03b872,
    name: "blog-slug___zh-CN"
  }, {
    path: "/zh-CN/faq/:slug?",
    component: _401fba57,
    name: "faq-slug___zh-CN"
  }, {
    path: "/zh-CN/happy-kids-2024/:any",
    component: _0c849694,
    name: "happy-kids-2024-any___zh-CN"
  }, {
    path: "/zh-CN/insurance-guide/:slug?",
    component: _6bfbfe6c,
    name: "insurance-guide-slug___zh-CN"
  }, {
    path: "/zh-CN/insurer/:code",
    component: _1a845f3e,
    name: "insurer-code___zh-CN"
  }, {
    path: "/zh-CN/media/:slug",
    component: _762e1bb6,
    name: "media-slug___zh-CN"
  }, {
    path: "/zh-CN/qdap/:year",
    component: _d6d88c08,
    name: "qdap-year___zh-CN"
  }, {
    path: "/zh-CN/scoring-methodology/:slug",
    component: _e9f9ca2e,
    name: "scoring-methodology-slug___zh-CN"
  }, {
    path: "/zh-HK/5star-products-2025/:any",
    component: _692218c5,
    name: "5star-products-2025-any___zh-HK"
  }, {
    path: "/zh-HK/5star-products/:year",
    component: _f29edb38,
    name: "5star-products-year___zh-HK"
  }, {
    path: "/zh-HK/blog/:slug",
    component: _9d03b872,
    name: "blog-slug___zh-HK"
  }, {
    path: "/zh-HK/career/:slug",
    component: _0aa3ec23,
    name: "career-slug___zh-HK"
  }, {
    path: "/zh-HK/faq/:slug?",
    component: _401fba57,
    name: "faq-slug___zh-HK"
  }, {
    path: "/zh-HK/happy-kids-2024/:any",
    component: _0c849694,
    name: "happy-kids-2024-any___zh-HK"
  }, {
    path: "/zh-HK/insurance-guide/:slug?",
    component: _6bfbfe6c,
    name: "insurance-guide-slug___zh-HK"
  }, {
    path: "/zh-HK/insurer/:code",
    component: _1a845f3e,
    name: "insurer-code___zh-HK"
  }, {
    path: "/zh-HK/media/:slug",
    component: _762e1bb6,
    name: "media-slug___zh-HK"
  }, {
    path: "/zh-HK/qdap/:year",
    component: _d6d88c08,
    name: "qdap-year___zh-HK"
  }, {
    path: "/zh-HK/scoring-methodology/:slug",
    component: _e9f9ca2e,
    name: "scoring-methodology-slug___zh-HK"
  }, {
    path: "/en/5star-products/:year?/insurer",
    component: _6bafc00d,
    name: "5star-products-year-insurer___en"
  }, {
    path: "/zh-CN/5star-products/:year?/insurer",
    component: _6bafc00d,
    name: "5star-products-year-insurer___zh-CN"
  }, {
    path: "/zh-HK/5star-products/:year?/insurer",
    component: _6bafc00d,
    name: "5star-products-year-insurer___zh-HK"
  }, {
    path: "/en/5star-products/:year?/insurer/:insurer",
    component: _0b0a455e,
    name: "5star-products-year-insurer-insurer___en"
  }, {
    path: "/en/qdap/:year/products/:slug",
    component: _31e77da8,
    name: "qdap-year-products-slug___en"
  }, {
    path: "/zh-CN/5star-products/:year?/insurer/:insurer",
    component: _0b0a455e,
    name: "5star-products-year-insurer-insurer___zh-CN"
  }, {
    path: "/zh-CN/qdap/:year/products/:slug",
    component: _31e77da8,
    name: "qdap-year-products-slug___zh-CN"
  }, {
    path: "/zh-HK/5star-products/:year?/insurer/:insurer",
    component: _0b0a455e,
    name: "5star-products-year-insurer-insurer___zh-HK"
  }, {
    path: "/zh-HK/qdap/:year/products/:slug",
    component: _31e77da8,
    name: "qdap-year-products-slug___zh-HK"
  }, {
    path: "/en/5star-products-2025/:any?/:any2",
    component: _1fc91f9b,
    name: "5star-products-2025-any-any2___en"
  }, {
    path: "/en/5star-products/:year?/:productCat",
    component: _34bd969b,
    name: "5star-products-year-productCat___en"
  }, {
    path: "/en/insurer/:code?/:cat",
    component: _9536e5c4,
    name: "insurer-code-cat___en"
  }, {
    path: "/zh-CN/5star-products-2025/:any?/:any2",
    component: _1fc91f9b,
    name: "5star-products-2025-any-any2___zh-CN"
  }, {
    path: "/zh-CN/5star-products/:year?/:productCat",
    component: _34bd969b,
    name: "5star-products-year-productCat___zh-CN"
  }, {
    path: "/zh-CN/insurer/:code?/:cat",
    component: _9536e5c4,
    name: "insurer-code-cat___zh-CN"
  }, {
    path: "/zh-HK/5star-products-2025/:any?/:any2",
    component: _1fc91f9b,
    name: "5star-products-2025-any-any2___zh-HK"
  }, {
    path: "/zh-HK/5star-products/:year?/:productCat",
    component: _34bd969b,
    name: "5star-products-year-productCat___zh-HK"
  }, {
    path: "/zh-HK/insurer/:code?/:cat",
    component: _9536e5c4,
    name: "insurer-code-cat___zh-HK"
  }, {
    path: "/en/5star-products/:year?/:productCat/:productSubCat",
    component: _2fbaeee0,
    name: "5star-products-year-productCat-productSubCat___en"
  }, {
    path: "/zh-CN/5star-products/:year?/:productCat/:productSubCat",
    component: _2fbaeee0,
    name: "5star-products-year-productCat-productSubCat___zh-CN"
  }, {
    path: "/zh-HK/5star-products/:year?/:productCat/:productSubCat",
    component: _2fbaeee0,
    name: "5star-products-year-productCat-productSubCat___zh-HK"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
