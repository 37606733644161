
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    usage: {
      type: String,
      required: false,
      default: '',
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      required: false,
      default: '',
    },
  },
};
