

  export default {
    name: 'DrifterZoneVideoComponent',

    props: {
        self_hosted_video_url: {
            type: String,
            default: "",
        },
        other_video_link: {
            type: String,
            default: "https://v.qq.com/x/page/r35472oob0j.html",
        },
        youtube_link: {
            type: String,
            default: "",
        },
        
    },

    data() {
      return {
      }
    },
  }
