
export default {
  data() {
    return {
      complete: false,
    };
  },
  methods: {
    completeForm() {
      this.complete = true;
    },

    resetCompleteForm() {
      this.complete = false;
    },

    closeModal() {
      this.resetCompleteForm();
      this.$emit('close-modal');
    },
  },
};
