import { render, staticRenderFns } from "./HomepageProductShortcut.vue?vue&type=template&id=7ab6f4fb&scoped=true"
import script from "./HomepageProductShortcut.vue?vue&type=script&lang=js"
export * from "./HomepageProductShortcut.vue?vue&type=script&lang=js"
import style0 from "./HomepageProductShortcut.vue?vue&type=style&index=0&id=7ab6f4fb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ab6f4fb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImgComponent: require('/app/components/common/ImgComponent.vue').default,ReusableLink: require('/app/components/common/ReusableLink.vue').default,ReusableButton: require('/app/components/common/ReusableButton.vue').default})
