
import disclaimerQuery from '~/apollo/queries/disclaimer/disclaimer.gql';

export default {
  name: 'DisclaimerModal',

  props: {
    disclaimerType: {
      type: String,
      required: false,
      default: 'decoder-listing-popup',
    },

    confirmCookieSetFunction: {
      type: String,
      required: false,
      default: 'setHasProductDisclaimerConfirmed',
    },

    modalType: {
      type: String,
      required: false,
      default: '',
    },
  },

  apollo: {
    disclaimers: {
      query: disclaimerQuery,
      prefetch: true,
      variables() {
        return {
          disclaimer_type: this.disclaimerType,
          locale: this.$normalizeLocale(this.$i18n.locale),
        };
      },
      update(data) {
        return this.$normalizeStrapi(data.blogDisclaimers.data);
      },
    },
  },

  methods: {
    confirm() {
      if(this.modalType === 'blogDisclaimerModal') {
        this.$store.commit('disclaimerPopup/setDisclaimerConfirmedBlog', this.disclaimerType);
      }else  if(this.modalType === 'blogDisclaimerModalInfo') {
        // do nothing
      } else {
        this.$store.commit('disclaimerPopup/' + this.confirmCookieSetFunction, true);
      }
    },
    leave() {
      this.$router.push({
        path: `/${this.$i18n.locale}/`,
      });
    },
  },
};
