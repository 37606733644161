import en from 'vee-validate/dist/locale/en'

export default (context, locale) => {
  return {
    menu: {
      login: 'Login',
      logout: 'Logout',
      hotline_number: '3705 1599',
      a_href_hotline_number: 'tel:+85237051599',
      email: 'enquiries@10life.com',
      company_address: '12/F Greatmany Centre, 109-115 Queen’s Road East, Wan Chai, Hong Kong',
      navigation_content: {
        about_us: {
          hotline_number: '(852) 3705 1599'
        }
      },
      profile: {
        my_profile: 'My Profile',
        reset_password: 'Reset Password',
        logout: 'Logout'
      },
    },
    navBar: {
      phone: 'Hotline',
    },
    welcome: 'Welcome',
    confirm: 'Confirm',
    not_applicable: 'N/A',
    page_meta: {
      'home': {
        title: "10Life｜HK's Leading Insurance Comparison Platform",
        description: '10Life has analysed >1000 insurance products to help users compare and select the right product. Users can also speak directly to our advisors for help.'
      },
      'life-stage': {
        title: 'What Insurance Do I Need?',
        description: 'Select insurance products based on your life stage and understand your protection needs through different life stage illustrations.'
      },
      products_landing_page: {
        title: 'Compare over 1000+ Insurance Products',
        description: 'Insurance product ratings from fair comparison of product details, guiding you to find the right life, savings, annuity and medical insurance products.',
      },
    },
    disclaimerModal: {
      leave: 'Leave',
      continue: 'Continue'
    },
    authModal: {
      intro_wording: {
        title: '10Life Insurance Comparison Platform',
        description: 'Help You Decode 1000+ Insurance Products with Big Data',
      },
      login: {
        title: 'Log In',
        email: 'Email',
        password: 'Password',
        forget_password: 'Forgot Password',
        sign_up_prompt_title: 'No 10Life account?',
        sign_up_prompt_button: 'Sign Up',
        login_error: 'Error while signing in. Please try again later.',
        login_advisor_error: 'For advisors, please use 10life mobile application to login.',
        login_button: 'Log In',
      },
      register: {
        title: 'Sign Up',
        login_prompt_title: 'Already have an account?',
        login_prompt_button: 'Log In Now!'
      },
      signup: {
        title: 'Sign up with Email',
        goto_register_page: 'Sign up with other methods',
        email: 'Register email',
        password: 'Password',
        confirm_password: 'Confirm Password',
        nickname: 'Name',
        sign_up_error: 'Error while signing up. Please try again later.',
        sign_up_button: 'Sign Up',
      },
      verify_email: {
        title: 'Verification Email',
        resend_email_content: [
          'You have received 10Life verification email. Please verify your email address in 48 hours for using full functionality.',
          'If the email address you filled in at the time of registration is incorrect, please re-register.',
          'If you do not receive the verification email, please ',
          ' to get a new verification email.',
        ],
        resend_email_button: 'click here',
        resend_email_response: 'Email verification succesfully sent',
      },
      forget_password: {
        title: 'Reset Password',
        email: 'Email',
        description: 'Please provide the registered email address for reset password email.',
        forget_password_error: 'Error while resetting password. Please try again later.',
        forget_password_message: 'Please check email for password reset.',
        forget_password_button: 'Send Reset Password Email',
      },
      facebook_auth: {
        title: 'Continue with Facebook',
      },
      google_auth: {
        title: 'Continue with Google'
      },
      disclaimer: [
        'By signing up an account, you agree to 10Life\'s <a href="/en/privacy" target="_self">Privacy Policy</a> and <a href="/en/terms" target="_self">Terms of Use</a>.',
        'By logging in to your account, you agree to 10Life\'s <a href="/en/privacy" target="_self">Privacy Policy</a> and <a href="/en/terms" target="_self">Terms of Use</a>.',
        'I agree to receive 10Life information and promotional materials via my email address and contact number.'
      ],
      dividing_or: 'or',
      password_remarks: 'Password must have at least 8 characters, containing numbers, uppercase or lowercase letters.'
    },
    user_center: {
      title: 'Account Settings',
      reset_password: {
        title: 'Reset Password',
        current_password: 'Current Password',
        new_password: 'New Password',
        confirm_new_password: 'Confirm Password',
        forget_password: 'Forgot password',
        reset_password_error: 'Error while resetting password. Please try again later.',
        reset_password_message: 'Reset password successfully.',
        reset_password_button: 'Confirm',
      },
      update_profile: {
        title: 'My Profile',
        membership_default_level: 'Insurance Newbie',
        nickname: 'Nickname',
        date_of_birth: 'Date of birth',
        gender: 'Gender',
        gender_options: [
          {
            value: 'male',
            text: 'Male'
          },
          {
            value: 'female',
            text: 'Female'
          },
        ],
        martial_status: 'Marital Status',
        martial_status_options: [
          {
            value: 'single',
            text: 'Single'
          },
          {
            value: 'married',
            text: 'Married'
          },
        ],
        number_of_children: 'Number of children',
        number_of_pets: 'Number of pets',
        select_text: '--- Please select {replace_me} ---',
        reset_button: 'Reset',
        reset_message: 'Profile data is reset.',
        save_button: 'Save',
        save_message: 'Profile data is updated.',
        save_error: 'Error while updating user profile. Please try again later.',
      },
    },
    my_favourite: {
      title: 'My Favourite',
      insurance_product: {
        title: 'Insurance Product',
        product_selected: '<strong>$num</strong> products selected',
        no_product: 'No products have been saved. Go to "Compare Insurance" to find your right products.'
      },
      blog: {
        title: 'Blog',
        blog_selected: '<strong>$num</strong> blogs selected',
        no_blog: 'You have not saved any blog articles. Check out 10Life Blog for the latest insurance product insights.'
      },
      select_all: 'Select All',
    },
    contact_us_form_modal: {
      company_info: {
        title: '10Life Financial Limited',
        email: 'Enquiries: enquiries@10life.com',
        hotline: 'Hotline: (852) 3705 1599',
        address: 'Address: 12/F Greatmany Centre, 109-115 Queen’s Road East, Wan Chai, Hong Kong',
      },
      form: {
        title: 'Contact Us',
        description: 'Need to get in touch with us? We\'d love to know your thoughts.',
        enquiry_type: 'Enquiry Type',
        enquiry_options: [
          {
            value: 'enquiryForAppBug',
            text: 'Website Issues'
          },
          {
            value: 'enquiryForOthers',
            text: 'General Enquiries'
          },
        ],
        name: 'Your Name',
        email: 'Email Address',
        message: 'Message',
        submission_button: 'Submit',
      },
      complete_message: 'You have submitted successfully. The staff of 10Life Financial Limited will contact you in 1 working day.',
      complete: 'Complete'
    },
    wechatModal: {
      title: 'Scan the QR code via WeChat',
      description: 'Talk to 10Life on WeChat'
    },
    decoderCtaModal: {
      title: 'Application Method',
      onlineApplication: 'Online Application',
      whatsappConsultation: 'WhatsApp Consultation',
    },
    social_links: {
      facebook: 'https://www.facebook.com/10LifeHK/',
      instagram: 'https://www.instagram.com/10lifehk/',
      linkedin: 'https://hk.linkedin.com/company/10life',
      youtube: 'https://www.youtube.com/channel/UCz8b7EYrOF4iXFIsap30kkw',
    },
    validation: en.messages,
    footer: {
      logo: '@/assets/images/common/tenlife-normal-logo.png',
      contact_section_description: 'We\'re here to help',
      contact_form_title: 'Leave your enquiry and we will contact you soon',
      contact_form_button_text: 'General Enquiry',
      contact_form_button_link: '',
      hotline_title: 'Call 10Life Now',
      hotline_label: 'Hotline',
      hotline_number: '3705 1599',
      enews_section_title: 'Make informed insurance choices. Subscribe now',
      enews_section_description: 'What insurance companies don\'t tell you',
      enews_section_hint: 'Enter your email',
      enews_section_successful_message: 'Subscribed successfully!',
      enews_section_error_message: 'The same email has already been subscribed.',
      disclaimer: [
        '10Life.com is operated by 10Life Financial Limited registered in Hong Kong under company number 1154750 (“10Life Financial”).',
        '10Life Financial is an authorised insurance broker with Insurance Authority License Number FB1526 whose business includes operating insurance comparison website and arranging insurance products and services for its clients. 10Life Financial is wholly owned subsidiary of 10Life Group.',
      ],
      copyright_text1: '© ',
      copyright_text2: ' 10Life Group Limited (registered in Hong Kong under company number 2366460) ("10Life Group"). All rights reserved.',
      social_title: 'Follow Us',
      hotlinks: [
        {
          text: 'Terms of Use',
          link: '/en/terms'
        },
        {
          text: 'Privacy Policy',
          link: '/en/privacy'
        },
        {
          text: 'Cookie Policy',
          link: '/en/cookies'
        }
      ]
    },
    forgetPassword: {
      title: 'Forget Password'
    },
    error_page: {
      title: 'Page not found.',
      return_home: 'Return to Homepage',
      goto_url: '/',
    },
    blog: {
      article: 'Category',
      author: 'Author',
      authorAllArticles: 'All articles',
      trending: 'Trending',
      recommandedToYou: 'Recommended For You',
      minuteToRead: 'min read',
      browseAuthor: 'About Author',
      articleAnchor: 'Table of Content',
      relatedProduct: 'Related Products',
      disclaimer: 'Disclaimer',
      sidebarToggle: 'Trending ',
      noBlog: 'No blog meets your selected criteria. Please try again. ',
      loadMore: 'Load More',
      reset: 'Reset',
      filter: {
        all: 'All',
        type: {
          category: 'Category',
          author: 'Author'
        },
        confirm: 'Confirm'
      },
      search: {
        placeholder: 'Search',
        title: 'Search for Articles',
        suggestedKeywords: 'Trending Keywords',
        noResult: 'No relevant articles. Please try different search words.',
        noKeyword: 'Please provide search words',
        backToBlog: 'Back to BLOG',
      },
      advisorProfile: 'View Profile',
      errorPage: {
        message: 'Sorry! This article is not available.<br />Please find other insurance-related articles <a href=\'/en/blog/\'>here</a>. <span style=\'display: inline-block\'>Thank you!</span>',
        ctaText: 'Return to Homepage',
        meta: {
          title : 'Unavailable Article',
          description : 'Sorry, we couldn\'t find your article.',
        }
      }
    },
    media: {
      filter: {
        all: 'All',
        year: 'Filter by year'
      },
      press_contact: 'Press Contact',
      gallery_title: 'Related Photos'
    },
    products_comparison: {
      add_to_compare: 'Compare',
      already_added_to_compare: 'Added',
      go_to_compare: 'Compare',
      clear_all: 'Clear all',
      select_products: 'Select Products',
      add_more_products: 'Add More Products',
      quit_comparison: 'Leave Product Comparison',
    },
    products: {
      insurer: "Insurer",
      targetPeriod: "Target Protection Period",
      gender: "Gender",
      male: "Male",
      female: "Female",
      age: "Age",
      yearUnit: "Year",
      yearsUnit: "Years",
      ageUnit: "Age",
      monthUnit: "Months",
      dayUnit: "Days",
      to: "to",
      annualRenewal: "Yearly Renewal",
      paymentTerm: "Payment Term",
      renewalTerm: "Renewal Term",
      renewalUnit: "$num-year Renewal",
      underwriting: "Underwriting",
      standardUnderwriting: "Standard Underwriting",
      onlineUnderwriting: "Online Underwriting",
      sumAssured: "Sum Assured",
      advisorAssistance: "Advisor Assistance",
      sort: "Sort By",
      result: "We found $num Term Life product(s) matching your selected filters.",
      compare: "Compare",
      enquiry: "WhatsApp Enquiry",
      product_website: "Product Website",
      purchase_now: "Purchase Now",
      user_review: " user(s) reviews",
      not_specified: "Not specified",
      currency: "Currency",
      ratingAssumption: "Assumptions",
      back: "Back",
      any: "Any",
      prepaid: "1-year or Pre-paid",
      yearPremium: "-year Premium",
      yearAnnuity: "-year Annuity",
      whatsapp_enquiry_message: "Hi 10Life, I am interested in {replace_with_category} ({replace_with_insurer} | {replace_with_product_name})",
      whatsapp_enquiry_message_blog: "I saw 10Life Blog [ {replace_with_link} ] and I am interested in {replace_with_category} ({replace_with_insurer} | {replace_with_product_name})",
      datePicker: {
        title: 'Trip',
        placeholder: 'please select trip date'
      },
      destination: "Destination",
      distributor: "Distributor",
      productFeatures: "Product Features",
      show_product: "Show Products",
      filter_selection: "Filter Selection",
      sorting_option: "Sorting Option",

      medical: {
        md_categoryName: "medical insurance",
        md_pageTitle: "Compare Medical Insurance",
        md_quickGuide: "Medical Insurance Quick Guide",
        md_result: "We found <strong>$num</strong> Medical Insurance product(s) matching your selected filters.",
        md_forRenewal: "*For Renewal Only",
        md_10_yearAvgPremium: "10-year Avg. Premium",
        md_10_yearAvgPremiumHKD: "10-year Avg. Premium (HKD)",
        md_10_yearAvgPremiumLowtoHigh: "10-year Avg. Premium (Low to High)",
        md_5StarRatings: "5 Star Ratings",
        md_additionalCoverage: "Additional Coverage",
        md_additionalFeatures: "Additional Features",
        md_age: "Age",
        md_ageRange: "Age $from to $to",
        md_anaesthetistsFee: "Anaesthetist's Fee",
        md_angioplastywithStent: "Angioplasty with Stent",
        md_arthroscopicAnteriorCruciateLigament: "Arthroscopic Anterior Cruciate Ligament",
        md_attendingDoctorsVisitFee: "Attending Doctor's Visit Fee",
        md_averageAnnualPremium: "Average Annual Premium",
        md_averageCoverageofCommonMedicalCases: "Average Coverage of Common Medical Cases",
        md_basePlanLimit: "Base Plan Limit",
        md_benefitLimitsBasePlan: "Benefit Limits (Base Plan)",
        md_cancer_BreastCancerwithMastectomyReconstructionChemotherapyRadiotherapy: "Cancer - Breast Cancer with Mastectomy, Reconstruction, Chemotherapy & Radiotherapy",
        md_cancer_ColonCancerwithSurgeryandChemotherapy: "Cancer - Colon Cancer with Surgery and Chemotherapy",
        md_cancer_LiverCancerwithSurgeryandTargetedTherapy: "Cancer - Liver Cancer with Surgery and Targeted Therapy",
        md_cancer_LungCancerwithLobectomy: "Cancer - Lung Cancer with Lobectomy",
        md_cancerAverageCoverage: "Cancer Average Coverage",
        md_co_payment: "Co-payment",
        md_colonoscopyHemi_Colectomy: "Colonoscopy & Hemi-Colectomy",
        md_colonoscopywithPolypectomy: "Colonoscopy with Polypectomy",
        md_commonCases_ColonoscopyDayCase: "Common Cases - Colonoscopy (Day Case)",
        md_commonCases_FeverRequiringHospitalisation: "Common Cases - Fever Requiring Hospitalisation",
        md_commonCases_HemorrhoidSurgery: "Common Cases - Hemorrhoid Surgery",
        md_coverageLimit: "Coverage Limit",
        md_deductible: "Deductible",
        md_deductibleHKD: "Deductible (HKD)",
        md_estimatedCoverage: "Estimated Coverage",
        md_estimatedCoverageofselectedDiseases: "Estimated Coverage of Selected Diseases",
        md_estimatedOutofPocket: "Estimated Out of Pocket",
        md_estimatedTotalBill: "Estimated Total Bill",
        md_firstYearPremium: "First Year Premium",
        md_firstYearPremiumLowtoHigh: "First Year Premium (Low to High)",
        md_fullCoveragePlan: "Full Coverage Plan",
        md_gender: "Gender",
        md_generalLimit: "General Limit",
        md_geographicalCoverage: "Geographical Coverage",
        md_geoRegionDefaultText: "Any",
        md_guaranteedNoBenefitReductionAppliedtoAllVHISplans: "Guaranteed No Benefit Reduction (Applicable To All VHIS Plans)",
        md_hemorrhoidSurgery: "Hemorrhoid Surgery",
        md_highAnnualLimit: "High Annual Limit",
        md_homeNursing: "Home Nursing",
        md_hospicePalliativeCareCoverage: "Hospice / Palliative Care Coverage",
        md_hospitalCash: "Hospital Cash",
        md_includeSMM: "Include SMM",
        md_injury_AnteriorCruciateLigamentSurgery: "Injury - Anterior Cruciate Ligament Surgery",
        md_injury_TotalKneeReplacement: "Injury - Total Knee Replacement",
        md_insurer: "Insurer",
        md_intensiveCare: "Intensive Care",
        md_internationalAssistanceService: "International Assistance Service",
        md_intersiveCare: "Intersive Care",
        md_key: "Maximum Numbers of days",
        md_lifetimeLimit: "Lifetime Limit",
        md_limit: "Limit",
        md_limits: "Limits",
        md_long_termPostHospitalisationOutpatientCover: "Long-term Post Hospitalisation Outpatient Cover",
        md_maximumNumbersofDays: "Maximum Numbers of Days",
        md_medicalCoverageScore: "Medical Coverage Score",
        md_medicalInflationProtection: "Medical Inflation Protection",
        md_miscellaneousCharges: "Miscellaneous Charges",
        md_mostPopularHightoLow: "Most Popular (High to Low)",
        md_multipleSurgery_2ndSurgeryPayment: "Multiple Surgery - 2nd Surgery Payment %",
        md_noLifetimeLimit: "No Lifetime Limit",
        md_oneorBothofTheFollowing2Conditions: "One or Both of The Following 2 Conditions",
        md_operatingTheatreCharges: "Operating Theatre Charges",
        md_otherCoverage: "Other Coverage",
        md_outpatientDialysis: "Outpatient Dialysis",
        md_overview: "Overview",
        md_perDay: "Per Day",
        md_physiotherapyPost_confinement: "Physiotherapy Post-confinement",
        md_planType: "Plan Type",
        md_pre_andPost_ConfinementDayCaseProcedureOutpatientCare: "Pre- and Post-Confinement/Day Case Procedure Outpatient Care",
        md_pregnancyComplicationsCoverage: "Pregnancy Complications Coverage",
        md_prescribedDiagnosticImagingTests: "Prescribed Diagnostic Imaging Tests",
        md_prescribedNon_surgicalCancerTreatments: "Prescribed Non-surgical Cancer Treatments",
        md_psychiatricTreatments: "Psychiatric Treatments",
        md_rightLobectomyofLung: "Right Lobectomy of Lung",
        md_rightRadicalMastectomy: "Right Radical Mastectomy",
        md_roomandboard: "Room And Board",
        md_roomType: "Room Type",
        md_roomTypeDefaultText: "Ward",
        md_secondMedicalOpinionService: "Second Medical Opinion Service",
        md_segmentectomyofLiver: "Segmentectomy of Liver",
        md_shortWaitingPeriod: "Short Waiting Period",
        md_sMMCo_pay: "SMM Co-pay",
        md_sMMGeneralLimits: "SMM General Limits",
        md_sMMLifetimeLimits: "SMM Lifetime Limit",
        md_specialistsFee: "Specialist's Fee",
        md_supplementaryMajorMedicalBenefits: "Supplementary Major Medical Benefits",
        md_surgeonFees_MaximumLimitPerSurgery: "Surgeon Fees - Maximum Limit Per Surgery",
        md_surgeonsFee: "Surgeon's Fee",
        md_surgeonsFees: "Surgeon's Fees",
        md_totalKneeReplacement: "Total Knee Replacement",
        md_userRatingHightoLow: "User Rating (High to Low)",
        md_vascularDiseases_AngioplastyandStentPlacement: "Vascular Diseases - Angioplasty and Stent Placement",
        md_vascularDiseases_KidneyDialysis: "Vascular Diseases - Kidney Dialysis",
        md_vascularDiseases_Stroke: "Vascular Diseases - Stroke",
        md_waiveronDeductibleforCertainCriticalCases: "Waiver on Deductible for Certain Critical Cases",
        md_overall_score: "10Life Score",
        md_special_feature_score: "Special Feature Score",
        md_special_feature: "Special Feature",
        md_medicalInflationProtection_indicator: "Indicators For Medical Inflation Protection",
        md_noItemizedSubLimit: "No Itemized Sub-limit",
        md_additionalCoverage_indicator: "Indicators For Additional Coverage",
        md_deductibleWaiverOnCancer: "Deductible Waiver On Cancer",
        md_deductibleWaiverOnStrokeAndHeartDisease: "Deductible Waiver On Stroke And Heart Disease",
        md_deductibleWaiverOnDiabetes: "Deductible Waiver On Diabetes Complications, Kidney Failure And Organ Transplantation",
        md_deductibleWaiverOnOtherIllness: "Deductible Waiver On Other Designated Serious Illnesses",
        md_secondClaimCashAllowance: "Second Claim Cash Allowance",
        md_hassleFreeArrangement_indicator: "Indicators For Hassle-free Arrangement",
        md_cashlessArrangement: "Cashless Arrangement",
        md_wellnessAndOthers_indicator: "Indicators for Wellness and Others",
        md_freeBodyCheck: "Free Body Check Entitlement",
        md_numberOfPolicyYearForFreeBodyCheck: "Number of Policy Year For Your First Free Body Check",
        md_frequencyOfFreeBodyCheck: "Frequency Of Entitled Free Body Check",
        md_availabilityOfNoClaimBonus: "Availability Of No Claim Bonus",
        md_availabilityOfEClaim: "Availability Of e-Claim",
        md_applyOnlineInsurer: "Apply through Insurer Website",
        md_experimentalCancerTreatment: "Experimental Cancer Treatment Coverage",
        md_emergencyDentalBenefit: "Emergency Dental Benefit",
        md_shortWaitingPeriod: "Short Waiting Period",
        md_personalCaseMgt: "Personal Case Management Support",
        md_healthHotline: "Health Hotline",
        md_mentalHealthHotline: "Mental Health Hotline",
        md_psychiatricCounsellingOutpatient: "Psychiatric Counselling Outpatient Coverage",
        md_claimsRelated_indicator: "Indicators For Claims-related Features",
        md_adjDeductibleWithoutReUnderwriteSpecificAge: "Reduction On Deductible On Specific Age Without Re-underwrite",
        md_donorBenefits: "Donors Benefits",
        md_compassionateDeathBenefit: "Compassionate Death Benefit",
        md_surgeonsFeeRemarks: "Per surgery, subject to surgical category for the surgery/procedure in the Schedule of Surgical Procedures"
      },
      termLife: {
        tl_categoryName: "term life insurance​",
        tl_pageTitle: "Compare Term Life Insurance​",
        tl_quickGuide: "Term Life Quick Guide",
        tl_result: "We found <strong>$num</strong> Term Life product(s) matching your selected filters.",
        tl_insurer: "Insurer",
        tl_targetPeriod: "Target Protection Period",
        tl_gender: "Gender",
        tl_age: "Age",
        tl_renewalTerm: "Renewal Term",
        tl_renewalUnit: "$num-year Renewal",
        tl_underwriting: "Underwriting",
        tl_sumAssured: "Sum Assured",
        tl_10LifeRating: "10Life Score",
        tl_firstYearPremium: "First Year Premium",
        tl_YearAveragePremium: "-Year Average Premium",
        tl_termsScore: "Terms Score",
        tl_overview: "Overview",
        tl_10_YearDeathBenefitScore: "10-Year Death Benefit Score",
        tl_20_YearDeathBenefitScore: "20-Year Death Benefit Score",
        tl_10_YearAverageAnnualPremium: "10-Year Average Annual Premium",
        tl_20_YearAverageAnnualPremium: "20-Year Average Annual Premium",
        tl_issueAge: "Issue Age",
        tl_lastCoverageAge: "Last Coverage Age",
        tl_maximumSumAssured: "Maximum Sum Assured",
        tl_onlinePurchase: "Online Purchase",
        tl_premium: "Premium",
        tl_YearPremium: "Year Premium",
        tl_1stYearPremium: "1st Year Premium",
        tl_2ndYearPremium: "2nd Year Premium",
        tl_3rdYearPremium: "3rd Year Premium",
        tl_4thYearPremium: "4th Year Premium",
        tl_5thYearPremium: "5th Year Premium",
        tl_6thYearPremium: "6th Year Premium",
        tl_7thYearPremium: "7th Year Premium",
        tl_8thYearPremium: "8th Year Premium",
        tl_9thYearPremium: "9th Year Premium",
        tl_10thYearPremium: "10th Year Premium",
        tl_11thYearPremium: "11th Year Premium",
        tl_12thYearPremium: "12th Year Premium",
        tl_13thYearPremium: "13th Year Premium",
        tl_14thYearPremium: "14th Year Premium",
        tl_15thYearPremium: "15th Year Premium",
        tl_16thYearPremium: "16th Year Premium",
        tl_17thYearPremium: "17th Year Premium",
        tl_18thYearPremium: "18th Year Premium",
        tl_19thYearPremium: "19th Year Premium",
        tl_20thYearPremium: "20th Year Premium",
        tl_coverageafterAChangeofResidency: "Coverage after A Change of Residency",
        tl_coverageunderTheFollowingDeathCauses: "Coverage under the following death causes",
        tl_suicide: "Suicide",
        tl_hIVandAIDS: "HIV and AIDS",
        tl_illegalActivities: "Illegal Activities",
        tl_drugorAlcoholOverdoseorInfluence: "Drug or Alcohol Overdose or Influence",
        tl_armedForcesParticipation: "Armed Forces Participation",
        tl_nuclearBiologicalandChemicalActivities: "Nuclear, Biological, and Chemical Activities",
        tl_warandTerrorism: "War and Terrorism",
      },
      longSavings: {
        ls_categoryName: "long term savings insurance​",
        ls_pageTitle: "Compare Long Term Savings Insurance",
        ls_quickGuide: "Long Term Savings Quick Guide",
        ls_result: "We found <strong>$num</strong> Long-Term Savings product(s) matching your selected filters.",
        ls_yearpayment: "-year Payment",
        ls_10thPolicyYear: "10th Policy Year",
        ls_20thPolicyYear: "20th Policy Year",
        ls_30thPolicyYear: "30th Policy Year",
        ls_accumulatedDividendandInterest: "Accumulated Dividend and Interest",
        ls_accumulatedReversionaryBonus: "Accumulated Reversionary Bonus",
        ls_age: "Age",
        ls_aggressiveSavingsScore: "Aggressive Savings Score",
        ls_benefitmultiplier: "Benefit Multiplier",
        ls_bonusPhilosophy_SharingofProfit: "Bonus Philosophy (%) - Sharing of Profit",
        ls_cashValueSplit: "Cash Value Split",
        ls_conservativeSavingsScore: "Conservative Savings Score",
        ls_currency: "Currency",
        ls_deathBenefit: "Death Benefit",
        ls_equity_typeSecurities: "Equity-type Securities",
        ls_fixed_incomeSecurities: "Fixed-income Securities",
        ls_gender: "Gender",
        ls_growthSavingsScore: "Savings Score",
        ls_guaranteedCashValue: "Guaranteed Cash Value",
        ls_guaranteedDeathBenefit: "Guaranteed Death Benefit",
        ls_guaranteedIRR: "Guaranteed IRR",
        ls_guaranteedReturnRatio: "Guaranteed Return Ratio",
        ls_guaranteedReturnRatioHightoLow: "Guaranteed Return Ratio (High to Low)",
        ls_insurer: "Insurer",
        ls_internalRateofReturnIRR: "Internal Rate of Return (IRR)",
        ls_mostPopularHightoLow: "Most Popular (High to Low)",
        ls_overview: "Overview",
        ls_paymentTermYearly: "Payment Term (Yearly)",
        ls_policyYear: "Policy Year",
        ls_projectedCashValueBase: "Projected Cash Value (Base)",
        ls_projectedCashValuePessimistic: "Projected Cash Value (Pessimistic)",
        ls_projectedIRRBase: "Projected IRR (Base)",
        ls_projectedIRRPessimistic: "Projected IRR (Pessimistic)",
        ls_projectedReturnBase: "Projected IRR",
        ls_projectedReturnPessimistic: "Projected IRR (Pessimistic)",
        ls_projectedReturnRatioBase: "Projected Return Ratio",
        ls_projectedReturnRatioBaseHightoLow: "Projected Return Ratio (High to Low)",
        ls_projectedReturnRatioPessimistic: "Projected Return Ratio (Pessimistic)",
        ls_projectedReturnRatioPessimisticHightoLow: "Projected Return Ratio (Pessimistic) (High to Low)",
        ls_return: "Return",
        ls_riskPreference: "Risk Preference",
        ls_targetAssetAllocation: "Target Asset Allocation",
        ls_targetPeriod: "Target Period",
        ls_terminalDividend: "Terminal Dividend",
        ls_toPolicyholders: "To Policyholders",
        ls_toShareholders: "To Shareholders",
        ls_totalCashValue: "Total Cash Value",
        ls_totalPremium: "Total Premium",
        ls_totalPremiumPaid: "Total Premium Paid",
        ls_userRatingHightolow: "User Rating (High to low)",
        ls_conservative: "Conservative",
        ls_growth: "Growth",
        ls_aggressive: "Aggressive",
        ls_prepaid: "Pre-paid",
        ls_specialFeatures: "Special Features",
        ls_notSpecified: "Not Specified",
        ls_multiCurrency: "Multi-Currency",
        ls_transferOfInsured: "Transfer of Insured",
        ls_simplifiedUnderwriting: "Simplified Underwriting",
        ls_longTermSavingsScore: "10Life Score",
        ls_overallScore: "10Life Score",
        ls_insurerDividendFulfillmentScore: "Insurer Fulfillment Score (IFS)",
        ls_5StarRatings: "5-Star Ratings",
        ls_insurerFulfillmentRatios: "Insurer Fulfillment Ratios",
        ls_insurersFulfillmentScore: "Insurers' Fulfillment Score",
        ls_fulfillmentRatioStatisticsPolicyYear6orAbove: "Fulfillment Ratio Statistics (Policy Year 6 or above)",
        ls_fulfillmentRatioStatisticsAllPolicyYear: "Fulfillment Ratio Statistics (All Policy Year)",
        ls_dataPoint: "Data points",
        ls_averageFulfillmentRatiosPolicyYear6orAbove: "Average Fulfillment Ratios (Policy Year 6 or above)",
        ls_averageFulfillmentRatios: "Average Fulfillment Ratios",
        ls_proportionOfFulfillmentRatiosWhichAreHigherThan70: "Proportion of Fulfillment Ratios which are higher than 70%",
        ls_policyEnded: "Policy Ended",
        ls_riskLevel: "Risk Level",
        ls_stable: "Stable",
        ls_returnRatioCashValueOverTotalPremiumPaid: "Return Ratio (Cash Value over Total Premium Paid)",
        ls_projectedReturnRatioBaseInDetail: "Projected Return Ratio (Base)",
        ls_cashValue: "Cash Value",
        ls_accumulatedDividendAndInterestOverProjectedCashValueBase: "Accumulated Dividend and Interest over Projected Cash Value (Base)",
        ls_accumulatedReversionaryBonusOverProjectedCashValueBase: "Accumulated Reversionary Bonus over Projected Cash Value (Base)",
        ls_terminalDividendOverProjectedCashValueBase: "Terminal Dividend over Projected Cash Value (Base)",
        ls_notApplicale: "N/A",
      },
      shortSavings: {
        st_categoryName: "short term savings insurance",
        st_pageTitle: "Compare Short Term Savings Insurance",
        st_quickGuide: "Short Term Savings Quick Guide",
        st_result: "We found <strong>$num</strong> Short-Term Savings product(s) matching your selected filters.",
        st_insurer: "Insurer",
        st_currency: "Currency",
        st_paymentTerm: "Payment Term",
        st_yearpayment: "-year Payment",
        st_yearmaturity: "-year Maturity",
        st_maturity: "Maturity",
        st_shortTermSavingsScore: "10Life Score",
        st_guaranteedBreakevenShorttoLong: "Guaranteed Breakeven (Short to Long)",
        st_guaranteedReturnRatioHightoLow: "$num Year Guaranteed Return Ratio (High to Low)",
        st_guaranteedIRRHightoLow: "$num Year Guaranteed IRR (High to Low)",
        st_mostPopularHightoLow: "Most Popular (High to Low)",
        st_userRatingHightoLow: "User Rating (High to Low)",
        st_guaranteedBreakeven: "Guaranteed Breakeven ",
        st_targetPeriodGuaranteedIRR: "$num Year Guaranteed IRR",
        st_guaranteedReturnRatio: "$num Year Guaranteed Return Ratio",
        st_guaranteedIRRatyr1surrender: "Guaranteed IRR at Yr 1 Surrender",
        st_premiumTerm: "Premium Term",
        st_policyTerm: "Policy Term",
        st_issueAge: "Issue Age",
        st_minimumTotalPremium: "Minimum Total Premium ",
        st_maximumTotalPremium: "Maximum Total Premium",
        st_onlinePurchase: "Online Purchase",
        st_deathBenefit: "Death Benefit",
        st_overview: "Overview",
        st_guaranteedReturn: "Guaranteed Return",
        st_guaranteedIRREarlySurrenderoratMaturity: "Guaranteed IRR (Early Surrender or at Maturity)",
        st_1Year: "1 Year",
        st_2Year: "2 Year",
        st_3Year: "3 Year",
        st_4Year: "4 Year",
        st_5Year: "5 Year",
        st_guaranteedReturnRatioGuaranteedBenefitPremiumPaid: "Guaranteed Return Ratio (=Guaranteed Benefit / Premium Paid)",
        st_gbey_immediate: 'Immediate',
        st_targetPeriod: 'Target Period',
      },
      wlci: {
        wlci_categoryName: "whole life critical illness insurance",
        wlci_pageTitle: "Compare Whole Life Critical Illness Insurance",
        wlci_quickGuide: "Whole Life Critical Illness Quick Guide",
        wlci_result: "We found <strong>$num</strong> Whole Life Critical Illness Insurance product(s) matching your selected filters.",
        wlci_insurer: "Insurer",
        wlci_gender: "Gender",
        wlci_age: "Age",
        wlci_smokerStatus: "Smoker Status",
        wlci_smoker: "Smoker",
        wlci_nonSmoker: "Non Smoker",
        wlci_3major: "Multiple - 3 Major CI",
        wlci_specific: "Multiple - Specific CI",
        wlci_all: "Multiple - All CI",
        wlci_multipleClaims: "Multiple Claims",
        wlci_currency: "Currency",
        wlci_sumAssured: "Sum Assured",
        wlci_multipleCI: "Multiple CI",
        wlci_5StarRatings: "5 Star Ratings",
        wlci_cICoverageScoreHightoLow: "CI Coverage Score (High to Low)",
        wlci_firstYearPremiumLowtoHigh: "First Year Premium (Low to High)",
        wlci_10yearAvgPremiumLowtoHigh: "10-year Avg. Premium (Low to High)",
        wlci_mostPopularHightoLow: "Most Popular (High to Low)",
        wlci_userRatingHightoLow: "User Rating (High to Low)",
        wlci_wholeLifeCICoverageScore: "Whole Life CI Coverage Score",
        wlci_overallScore: "10Life Score",
        wlci_termsScore: "Terms Score",
        wlci_premiumTermYears: "Premium Term (Years)",
        wlci_annualPremium: "Annual Premium",
        wlci_firstYearPremium: "First Year Premium",
        wlci_overview: "Overview",
        wlci_coverageScore: "Coverage Score",
        wlci_paymentTermYears: "Payment Term (Years)",
        wlci_numberofCoveredMajorIllnesses: "Number of Covered Major Illnesses",
        wlci_numberofCoveredEarlyIllnesses: "Number of Covered Early Illnesses ",
        wlci_deathBenefit: "Death Benefit",
        wlci_maximumPayoutBasicSumAssured: "Maximum Payout (Basic Sum Assured)",
        wlci_waitingPeriod: "Waiting Period",
        wlci_issueAge: "Issue Age",
        wlci_cICoverage: "CI Coverage",
        wlci_cITerms: "CI Terms",
        wlci_return: "Return",
        wlci_singleCICoverageScore: "Single CI Coverage Score",
        wlci_cancerPayoutBasicSumAssured: "Cancer Payout (Basic Sum Assured)",
        wlci_earlyCancerCarcinomainsituPayoutBasicSumAssured: "Early Cancer - Carcinoma-in-situ Payout (Basic Sum Assured)",
        wlci_heartDiseasePayoutBasicSumAssured: "Heart Disease Payout (Basic Sum Assured)",
        wlci_earlyHeartDiseaseTreatmentAngioplastyPayoutBasicSumAssured: "Early Heart Disease Treatment - Angioplasty Payout (Basic Sum Assured)",
        wlci_strokePayoutBasicSumAssured: "Stroke Payout (Basic Sum Assured)",
        wlci_protectionAmountBooster: "Protection Amount Booster",
        wlci_coverUnknownDiseases: "Cover Unknown Diseases",
        wlci_multipleCICoverageScore: "Multiple CI Coverage Score",
        wlci_continuousCancerCashBenefit: "Continuous Cancer Cash Benefit",
        wlci_maximumPayouton5YearCancerTreatmentPeriodBasicSumAssured: "Maximum Payout on 5-Year Cancer Treatment Period (Basic Sum Assured)",
        wlci_maximumPayouton20YearCancerTreatmentPeriodBasicSumAssured: "Maximum Payout on 20-Year Cancer Treatment Period (Basic Sum Assured)",
        wlci_maximumPayouton3xHeartDiseaseBasicSumAssured: "Maximum Payout on 3x Heart Disease (Basic Sum Assured)",
        wlci_maximumPayouton2xHeartDiseaseand1xStrokeBasicSumAssured: "Maximum Payout on 2x Heart Disease and 1x Stroke (Basic Sum Assured))",
        wlci_maximumPayouton2xStrokeand1xHeartDiseaseBasicSumAssured: "Maximum Payout on 2x Stroke and 1x Heart Disease (Basic Sum Assured))",
        wlci_maximumPayouton3xStrokeBasicSumAssured: "Maximum Payout on 3x Stroke (Basic Sum Assured)",
        wlci_otherMajorCISpecificDiseases: "Other Major CI - Specific Diseases",
        wlci_otherMajorCIAllDiseases: "Other Major CI - All Diseases",
        wlci_cancerTerms: "Cancer Terms",
        wlci_noofEarlyCancerCarcinomainsituPayout: "No. of Early Cancer - Carcinoma-in-situ Payout",
        wlci_cancerNoVagueExclusionBorderlineMalignancyorLowMalignantPotential: "Cancer: No Vague Exclusion (Borderline Malignancy or Low Malignant Potential)",
        wlci_cancerNoVagueDefinitionNonlifeThreateningCancers: "Cancer: No Vague Definition (Non-life Threatening Cancers)",
        wlci_recurrenceCancerWaitingPeriod: "Recurrence Cancer Waiting Period",
        wlci_newCancerWaitingPeriod: "New Cancer Waiting Period",
        wlci_continuousCancerWaitingPeriod: "Continuous Cancer Waiting Period",
        wlci_heartandStrokeTerms: "Heart and Stroke Terms",
        wlci_noofEarlyHeartDiseaseTreatmentAngioplastyPayout: "No. of Early Heart Disease Treatment - Angioplasty Payout",
        wlci_clearDefinitionofCoronaryArteryDiseaseCADwithNoSpecifiedSurgeriesorProceduresRequired: "Clear Definition of Coronary Artery Disease (CAD), with No Specified Surgeries or Procedures Required",
        wlci_angioplastyElectrocardiogramECGChangesNotRequired: "Angioplasty: Electrocardiogram (ECG) Changes Not Required",
        wlci_strokeDoesNotSpecificallyExcludeAccidentsTraumaorTraumaticInjuryInducedStroke: "Stroke: Does Not Specifically Exclude Accidents, Trauma or Traumatic Injury Induced Stroke",
        wlci_strokeDoesNotSpecificallyExcludeMigraineInducedStroke: "Stroke: Does Not Specifically Exclude Migraine Induced Stroke",
        wlci_strokeDoesNotSpecificallyExcludeVasculitisorInfectionInducedStroke: "Stroke: Does Not Specifically Exclude Vasculitis or Infection Induced Stroke",
        wlci_coversonlyHeartAttackunderMultipleCIHeartDiseaseCoverage: "Covers only Heart Attack under Multiple CI Heart Disease Coverage",
        wlci_generalTerms: "General Terms",
        wlci_noDeductionfromMajorPayoutafterEarlystagePayout: "No Deduction from Major Payout after Early-stage Payout",
        wlci_ageLimitforMultipleCICover: "Age Limit for Multiple CI Cover",
        wlci_guaranteedLevelPremium: "Guaranteed Level Premium",
        wlci_guaranteedReturnRatio: "Guaranteed Return Ratio",
        wlci_10thPolicyYear: "10th Policy Year",
        wlci_20thPolicyYear: "20th Policy Year",
        wlci_30thPolicyYear: "30th Policy Year",
        wlci_projectedReturnRatio: "Projected Return Ratio",
        wlci_nolimit: "Not specified",
        wlci_yearpayment: "-year Payment",
      },
      termci: {
        tci_categoryName: "term critical illness insurance",
        tci_pageTitle: "Compare Term Critical Illness Insurance ",
        tci_quickGuide: "Term Critical Illness Quick Guide",
        tci_result: "We found <strong>$num</strong> Term Critical Illness Insurance product(s) matching your selected filters.",
        tci_insurer: "Insurer",
        tci_gender: "Gender",
        tci_age: "Age",
        tci_cICoverage: "CI Coverage",
        tci_renewalTerm: "Renewal Term",
        tci_underwritingMethod: "Underwriting Method",
        tci_sumAssured: "Sum Assured",
        tci_termCIScore: "10Life Score",
        tci_5StarRatings: "5-Star Ratings",
        tci_firstYearPremiumLowtoHigh: "First Year Premium (Low to High)",
        tci_10yearAvgPremiumLowtoHigh: "10-year Avg. Premium (Low to High)",
        tci_mostPopularHightoLow: "Most Popular (High to Low)",
        tci_userRatingHightoLow: "User Rating (High to Low)",
        tci_firstYearPremium: "First Year Premium",
        tci_10yearAvgPremium: "10-year Avg. Premium",
        tci_overview: "Overview",
        tci_cancer: "Cancer",
        tci_cancerCoverage: "Cancer Coverage",
        tci_heart: "Heart Disease",
        tci_heartCoverage: "Heart Coverage",
        tci_stroke: "Stroke",
        tci_strokeCoverage: "Stroke Coverage",
        tci_otherCriticalIllness: "Other Critical Illness",
        tci_otherCriticalIllnessCoverage: "Other Critical Illness Coverage",
        tci_generalTermsScore: "General Terms Score",
        tci_numberofCoveredMajorIllnesses: "Number of Covered Major Illnesses",
        tci_numberofStandardCriticalIllnessesCovered: "Number of Standard Critical Illnesses Covered",
        tci_issueAge: "Issue Age",
        tci_maximumCoveredAge: "Maximum Covered Age",
        tci_onlinePurchase: "Online Purchase",
        tci_cancerCoverageScore: "Cancer Coverage Score",
        tci_heartCoverageScore: "Heart Coverage Score",
        tci_strokeCoverageScore: "Stroke Coverage Score",
        tci_otherCriticalIllnessCoverageScore: "Other Critical Illness Coverage Score",
        tci_premium: "Premium",
        tci_cancerScore: "Cancer Score",
        tci_cancerTerms: "Cancer Terms",
        tci_noVagueExclusionNonLifeThreateningCancers: "No Vague Exclusion: Non-Life Threatening Cancers",
        tci_payoutofMajorCancerinSumAssured: "Payout of Major Cancer in Sum Assured",
        tci_colorectumCancer: "Colorectum Cancer",
        tci_lungCancer: "Lung Cancer",
        tci_breastCancer: "Breast Cancer",
        tci_prostateCancerExcludeT1aT1bT1cExclusion: "Prostate Cancer (Exclude T1a, T1b & T1c Exclusion)",
        tci_liverCancer: "Liver Cancer",
        tci_stomachCancer: "Stomach Cancer",
        tci_nonmelanomaSkinCancer: "Non-melanoma Skin Cancer",
        tci_corpusUteriCancer: "Corpus Uteri Cancer",
        tci_nonHodgkinLymphomaCancer: "Non-Hodgkin Lymphoma Cancer",
        tci_thyroidCancerExcludeT1N0M0Exclusion: "Thyroid Cancer (Exclude T1N0M0 Exclusion)",
        tci_payoutofEarlyCancerinSumAssured: "Payout of Early Cancer in Sum Assured",
        tci_carcinomainsitu: "Carcinoma-in-situ",
        tci_heartScore: "Heart Score",
        tci_heartTerms: "Heart Terms",
        tci_cleardefinitionofCoronaryArteryDiseaseCADwithNoSpecifiedSugeriesorProceduresRequired: "Clear definition of Coronary Artery Disease (CAD), with No Specified Sugeries or Procedures Required",
        tci_coversHeartDiseasesotherthanHeartAttack: "Covers Heart Diseases other than Heart Attack",
        tci_payoutofEarlyHeartDiseaseinSumAssured: "Payout of Early Heart Disease in Sum Assured",
        tci_angioplasty: "Angioplasty",
        tci_strokeScore: "Stroke Score",
        tci_strokeTerms: "Stroke Terms",
        tci_doesNotSpecificallyExcludeAccidentsTraumaorTraumaticInjuryInducedStroke: "Does Not Specifically Exclude Accidents, Trauma or Traumatic Injury Induced Stroke",
        tci_doesNotSpecificallyExcludeMigraineInducedStroke: "Does Not Specifically Exclude Migraine Induced Stroke",
        tci_doesNotSpecificallyExcludeVasculitisorInfectionInducedStroke: "Does Not Specifically Exclude Vasculitis or Infection Induced Stroke",
        tci_notRequiredSurvivalPeriodafterDiagnosis: "Not Required Survival Period after Diagnosis",
        tci_fullcoverageforMajorCIAfterEarlyCIclaim: "Full coverage for Major CI After Early CI claim",
        tci_secondCICoverage: "Second CI Coverage",
        tci_secondCancer: "Second Cancer",
        tci_secondHeart: "Second Heart",
        tci_secondStroke: "Second Stroke",
        tci_secondOtherCriticalIllness: "Second Other Critical Illness",
        tci_10YearAverageAnnualPremium: "10-Year Average Annual Premium",
        tci_1stYearPremium: "1st Year Premium",
        tci_2ndYearPremium: "2nd Year Premium",
        tci_3rdYearPremium: "3rd Year Premium",
        tci_4thYearPremium: "4th Year Premium",
        tci_5thYearPremium: "5th Year Premium",
        tci_6thYearPremium: "6th Year Premium",
        tci_7thYearPremium: "7th Year Premium",
        tci_8thYearPremium: "8th Year Premium",
        tci_9thYearPremium: "9th Year Premium",
        tci_10thYearPremium: "10th Year Premium",
      },
      termcancer: {
        tcr_categoryName: "term cancer insurance",
        tcr_pageTitle: "Compare Term Cancer Insurance",
        tcr_quickGuide: "Term Cancer Quick Guide",
        tcr_result: "We found <strong>$num</strong> Term Cancer Insurance product(s) matching your selected filters.",
        tcr_insurer: "Insurer",
        tcr_gender: "Gender",
        tcr_age: "Age",
        tcr_renewalTerm: "Renewal Term",
        tcr_renewalUnit: "$num-year Renewal",
        tcr_underwritingMethod: "Underwriting Method",
        tcr_sumAssured: "Sum Assured",
        tcr_termCancerScore: "10Life Score",
        tcr_firstYearPremiumLowtoHigh: "First Year Premium (Low to High)",
        tcr_avgPremiumLowtoHigh: "Avg. Premium (Low to High)",
        tcr_mostPopularHightoLow: "Most Popular (High to Low)",
        tcr_userRatingHightoLow: "User Rating (High to Low)",
        tcr_earlyCancerCoverage: "Early Cancer Coverage",
        tcr_firstYearPremium: "First Year Premium",
        tcr_tenYearAvgPremium: "10-Year Avg. Premium",
        tcr_overview: "Overview",
        tcr_issueAge: "Issue Age",
        tcr_maximumCoveredAge: "Maximum Covered Age",
        tcr_onlinePurchase: "Online Purchase",
        tcr_cancerCoverageScore: "Cancer Coverage Score",
        tcr_premium: "Premium",
        tcr_cancerScore: "Cancer Score",
        tcr_cancerTerms: "Cancer Terms",
        tcr_noVagueExclusionNonLifeThreateningCancers: "No Vague Exclusion: Non-Life Threatening Cancers",
        tcr_continuousCancerTreatmentBenefit: "Continuous Cancer Treatment Benefit",
        tcr_fullCoverageforCancerafterCISClaim: "Full Coverage for Cancer after CIS Claim",
        tcr_notRequiredSurvivalPeriodafterDiagnosis: "Not Required Survival Period after Diagnosis",
        tcr_payoutofMajorCancerinSumAssured: "Payout of Major Cancer in Sum Assured",
        tcr_colorectumCancer: "Colorectum Cancer",
        tcr_lungCancer: "Lung Cancer",
        tcr_breastCancer: "Breast Cancer",
        tcr_prostateCancerExcludeT1aT1bT1cExclusion: "Prostate Cancer (Exclude T1a, T1b & T1c Exclusion)",
        tcr_liverCancer: "Liver Cancer",
        tcr_stomachCancer: "Stomach Cancer",
        tcr_nonmelanomaSkinCancer: "Non-melanoma Skin Cancer",
        tcr_corpusUteriCancer: "Corpus Uteri Cancer",
        tcr_nonHodgkinLymphomaCancer: "Non-Hodgkin Lymphoma Cancer",
        tcr_thyroidCancerExcludeT1N0M0Exclusion: "Thyroid Cancer (Exclude T1N0M0 Exclusion)",
        tcr_payoutofEarlyCancerinSumAssured: "Payout of Early Cancer in Sum Assured",
        tcr_carcinomainsitu: "Carcinoma-in-situ",
        tcr_10yearAvgPremium: "Average Annual Premium",
        tcr_10YearAverageAnnualPremium: "10-Year Average Annual Premium",
        tcr_1stYearPremium: "1st Year Premium",
        tcr_2ndYearPremium: "2nd Year Premium",
        tcr_3rdYearPremium: "3rd Year Premium",
        tcr_4thYearPremium: "4th Year Premium",
        tcr_5thYearPremium: "5th Year Premium",
        tcr_6thYearPremium: "6th Year Premium",
        tcr_7thYearPremium: "7th Year Premium",
        tcr_8thYearPremium: "8th Year Premium",
        tcr_9thYearPremium: "9th Year Premium",
        tcr_10thYearPremium: "10th Year Premium",
        tcr_earlyCancer: "Early Cancer Protection",
      },
      annuity: {
        as_categoryName: "QDAP​",
        as_pageTitle: "Compare QDAP",
        as_quickGuide: "QDAP Quick Guide",
        as_result: "We found <strong>$num</strong> QDAP product(s) matching your selected filters.",
        as_objective: "Objective",
        as_issueAge: "Issue Age",
        as_paymentTermYearly: "Payment Term (Yearly)",
        as_annuityStartAge: "Annuity Start Age",
        as_incomeTerm: "Income Term",
        as_guaranteedBenefitPerPremiumPaidAtMaturity: "Guaranteed Benefit Per Premium Paid at Maturity",
        as_totalPremium: "Total Premium",
        as_currency: "Currency",
        as_insurer: "Insurer",
        as_savingsAnnuityScore: "10Life Score",
        as_guaranteedInitialAnnualIncomeHightoLow: "Guaranteed Initial Annual Income (High to Low)",
        as_5StarRatings: '5-Star Ratings',
        as_guaranteedReturnRatioatAge60HightoLow: "Guaranteed Return Ratio at Age 60 (High to Low)",
        as_projectedReturnRatioatAge60HightoLow: "Projected Return Ratio at Age 60 (High to Low)",
        as_guaranteedBreakevenYearShorttoLong: "Guaranteed Breakeven Year (Short to Long)",
        as_mostPopularHightoLow: "Most Popular (High to Low)",
        as_userRatingHightoLow: "User Rating (High to Low)",
        as_guaranteedInitialAnnualIncome: "Guaranteed Initial Annual Income",
        as_guaranteedReturnRatioatAge60: "Guaranteed Return Ratio at Age 60",
        as_projectedReturnRatioatAge60: "Projected Return Ratio at Age 60",
        as_guaranteedBreakevenYear: "Guaranteed Breakeven Year",
        as_10LifeScenario: "Overview",
        as_productFacts: "Product Facts",
        as_guaranteedReturn: "Guaranteed Return",
        as_projectedReturn: "Projected Return",
        as_earlySurrenderCoverage: "Early Surrender Guaranteed Coverage",
        as_firstYearPremium: "First Year Premium",
        as_premiumTerm: "Premium Term",
        as_totalPremiumPaid: "Total Premium Paid",
        as_projectedInitialAnnualIncome: "Projected Initial Annual Income",
        as_percentageofTotalIncomeBeingGuaranteed: "Percentage of Total Income Being Guaranteed",
        as_currencyChoice: "Currency Choice",
        as_guaranteedInternalRateofReturnIRR: "Guaranteed Internal Rate of Return (IRR)",
        as_guaranteedInternalRateofReturnIRRWith17TaxSavings: "Guaranteed Internal Rate of Return (IRR) With 17% Tax Savings",
        as_totalGuaranteedIncomeAge: "Age $num",
        as_totalGuaranteedIncomeAge4549: "Total Guaranteed Income Age 45-49",
        as_totalGuaranteedIncomeAge5054: "Total Guaranteed Income Age 50-54",
        as_totalGuaranteedIncomeAge5559: "Total Guaranteed Income Age 55-59",
        as_totalGuaranteedIncomeAge6064: "Total Guaranteed Income Age 60-64",
        as_totalGuaranteedIncomeAge6569: "Total Guaranteed Income Age 65-69",
        as_totalGuaranteedIncomeAge5059: "Total Guaranteed Income Age 50-59",
        as_totalGuaranteedIncomeAge6069: "Total Guaranteed Income Age 60-69",
        as_totalGuaranteedIncomeAge7079: "Total Guaranteed Income Age 70-79",
        as_totalGuaranteedIncomeAge8089: "Total Guaranteed Income Age 80-89",
        as_totalGuaranteedIncomeAge9099: "Total Guaranteed Income Age 90-99",
        as_projectedBreakevenYear: "Projected Breakeven Year",
        as_projectedInternalRateofReturnIRR: "Projected Internal Rate of Return (IRR)",
        as_surrenderatage: "Surrender at Age $num",
        as_projectedInternalRateofReturnIRRwith17TaxSavings: "Projected Internal Rate of Return (IRR) with 17% Tax Savings",
        as_totalProjectedIncomeAge: "Age $num",
        as_totalProjectedIncomeAge4549: "Total Projected Income Age 45-49",
        as_totalProjectedIncomeAge5054: "Total Projected Income Age 50-54",
        as_totalProjectedIncomeAge5559: "Total Projected Income Age 55-59",
        as_totalProjectedIncomeAge6064: "Total Projected Income Age 60-64",
        as_totalProjectedIncomeAge6569: "Total Projected Income Age 65-69",
        as_totalProjectedIncomeAge5059: "Total Projected Income Age 50-59",
        as_totalProjectedIncomeAge6069: "Total Projected Income Age 60-69",
        as_totalProjectedIncomeAge7079: "Total Projected Income Age 70-79",
        as_totalProjectedIncomeAge8089: "Total Projected Income Age 80-89",
        as_totalProjectedIncomeAge9099: "Total Projected Income Age 90-99",
        as_totalProjectedIncomeAgeas_totalGuaranteedIncomeAge80894: "Total Projected Income Age 60-64",
        as_totalProjectedIncomeAge6569: "Total Projected Income Age 65-69",
        as_guaranteedBenefitPerPremiumPaidIncludingCashValueReceivedIncomeCashValuePremiumPaid: "Guaranteed Benefit Per Premium Paid, Including Cash Value: (Received Income + Cash Value) ÷ Premium Paid",
        as_surrenderatThe5thPolicyYear: "Surrender at The 5th Policy Year",
        as_surrenderatThe10thPolicyYear: "Surrender at The 10th Policy Year",
        as_surrenderatThe20thPolicyYear: "Surrender at The 20th Policy Year",
        as_surrenderatTheNumthPolicyYear: "Surrender at The $numth Policy Year",
        as_annuityStart: " Start",
        as_annuityEnd: "Policy Ended",
        ar_issueAge: "Issue Age",
        ar_paymentTermYearly: "Payment Term (Yearly)",
        ar_annuityStartAge: "Annuity Start Age",
        ar_incomeTerm: "Income Term",
        ar_totalPremium: "Total Premium",
        ar_currency: "Currency",
        ar_insurer: "Insurer",
        ar_retirementAnnuityScore: "10Life Score",
        ar_guaranteedInitialAnnualIncomeHightoLow: "Guaranteed Initial Annual Income (High to Low)",
        ar_guaranteedReturnRatioatAgeHightoLow: "Guaranteed Return Ratio at Age $num (High to Low)",
        ar_guaranteedReturnRatioatAge75HightoLow: "Guaranteed Return Ratio at Age 75 (High to Low)",
        ar_guaranteedReturnRatioatAge85HightoLow: "Guaranteed Return Ratio at Age 85 (High to Low)",
        ar_projectedReturnRatioatAgeHightoLow: "Projected Return Ratio at Age $num (High to Low)",
        ar_projectedReturnRatioatAge75HightoLow: "Projected Return Ratio at Age 75 (High to Low)",
        ar_projectedReturnRatioatAge85HightoLow: "Projected Return Ratio at Age 85 (High to Low)",
        ar_guaranteedBreakevenYearShorttoLong: "Guaranteed Breakeven Year (Short to Long)",
        ar_totalGuaranteedIncomeAge: "Age $num",
        ar_totalGuaranteedIncomeAge4549: "Total Guaranteed Income Age 45-49",
        ar_totalGuaranteedIncomeAge5054: "Total Guaranteed Income Age 50-54",
        ar_totalGuaranteedIncomeAge5559: "Total Guaranteed Income Age 55-59",
        ar_totalGuaranteedIncomeAge6064: "Total Guaranteed Income Age 60-64",
        ar_totalGuaranteedIncomeAge6569: "Total Guaranteed Income Age 65-69",
        ar_totalGuaranteedIncomeAge5059: "Total Guaranteed Income Age 50-59",
        ar_totalGuaranteedIncomeAge6069: "Total Guaranteed Income Age 60-69",
        ar_totalGuaranteedIncomeAge7079: "Total Guaranteed Income Age 70-79",
        ar_totalGuaranteedIncomeAge8089: "Total Guaranteed Income Age 80-89",
        ar_totalGuaranteedIncomeAge9099: "Total Guaranteed Income Age 90-99",
        ar_totalGuaranteedIncomeAge100109: "Total Guaranteed Income Age 100-109",
        ar_totalProjectedIncomeAge: "Age $num",
        ar_totalProjectedIncomeAge4549: "Total Projected Income Age 45-49",
        ar_totalProjectedIncomeAge5054: "Total Projected Income Age 50-54",
        ar_totalProjectedIncomeAge5559: "Total Projected Income Age 55-59",
        ar_totalProjectedIncomeAge6064: "Total Projected Income Age 60-64",
        ar_totalProjectedIncomeAge6569: "Total Projected Income Age 65-69",
        ar_totalProjectedIncomeAge5059: "Total Projected Income Age 50-59",
        ar_totalProjectedIncomeAge6069: "Total Projected Income Age 60-69",
        ar_totalProjectedIncomeAge7079: "Total Projected Income Age 70-79",
        ar_totalProjectedIncomeAge8089: "Total Projected Income Age 80-89",
        ar_totalProjectedIncomeAge9099: "Total Projected Income Age 90-99",
        ar_totalProjectedIncomeAge100109: "Total Projected Income Age 100-109",
        ar_surrenderatThe10thPolicyYear: "Surrender at The 10th Policy Year",
        ar_surrenderatThe20thPolicyYear: "Surrender at The 20th Policy Year",
        ar_mostPopularHightoLow: "Most Popular (High to Low)",
        ar_userRatingHightoLow: "User Rating (High to Low)",
        ar_guaranteedReturnRatioatAge: "Guaranteed Return Ratio at Age $num",
        ar_guaranteedReturnRatioatAge75: "Guaranteed Return Ratio at Age 75",
        ar_guaranteedReturnRatioatAge85: "Guaranteed Return Ratio at Age 85",
        ar_guaranteedReturnRatioatAge105: "Guaranteed Return Ratio at Age 105",
        ar_projectedReturnRatioatAge: "Projected Return Ratio at Age $num",
        ar_projectedReturnRatioatAge75: "Projected Return Ratio at Age 75",
        ar_projectedReturnRatioatAge85: "Projected Return Ratio at Age 85",
        ar_projectedReturnRatioatAge105: "Projected Return Ratio at Age 105",
        ar_guranteedBreakevenYear: "Guranteed Breakeven Year",
        ar_overview: "Overview",
        ar_guaranteedReturn: "Guaranteed Return",
        ar_projectedReturn: "Projected Return",
        ar_earlySurrenderCoverage: "Early Surrender Coverage",
        ar_10LifeRatings: "10Life Score",
        ar_firstYearPremium: "First Year Premium",
        ar_premiumTerm: "Premium Term",
        ar_totalPremiumPaid: "Total Premium Paid",
        ar_guaranteedBenefitPerPermiumPaidatMaturity: "Guaranteed Benefit Per Permium Paid at Maturity",
        ar_guaranteedInitialAnnualIncome: "Guaranteed Initial Annual Income",
        ar_projectedInitialAnnualIncome: "Projected Initial Annual Income",
        ar_percentageofTotalIncomeBeingGuaranteed: "Percentage of Total Income Being Guaranteed",
        ar_guaranteedBreakevenYear: "Guaranteed Breakeven Year",
        ar_guaranteedInternalRateofReturnIRR: "Guaranteed Internal Rate of Return (IRR)",
        ar_guaranteedReturnRatioatAgeNum: "Guaranteed Return Ratio at Age $num",
        ar_projectedReturnRatioatAgeNum: "Projected Return Ratio at Age $num",
        ar_surrenderatAge: "Surrender at Age $num",
        ar_guaranteedInternalRateofReturnIRRwith17TaxSavings: "Guaranteed Internal Rate of Return (IRR) with 17% Tax Savings",
        ar_totalGuaranteedIncomeAge5564: "Total Guaranteed Income Age 55-64",
        ar_totalGuaranteedIncomeAge6574: "Total Guaranteed Income Age 65-74",
        ar_totalGuaranteedIncomeAge7584: "Total Guaranteed Income Age 75-84",
        ar_projectedBreakevenYear: "Projected Breakeven Year",
        ar_projectedInternalRateofReturnIRR: "Projected Internal Rate of Return (IRR)",
        ar_projectedInternalRateofReturnIRRwith17TaxSavings: "Projected Internal Rate of Return (IRR) with 17% Tax Savings",
        ar_totalProjectedIncomeAge5564: "Total Projected Income Age 55-64",
        ar_totalProjectedIncomeAge6574: "Total Projected Income Age 65-74",
        ar_totalProjectedIncomeAge7584: "Total Projected Income Age 75-84",
        ar_guaranteedBenefitPerPremiumPaid: "Guaranteed Benefit Per Premium Paid",
        ar_surrenderatthe10thpolicyyear: "Surrender at the 10th policy year",
        ar_surrenderatthe20thpolicyyear: "Surrender at the 20th policy year",
        al_issueAge: "Issue Age",
        al_paymentTermYearly: "Payment Term (Yearly)",
        al_annuityStartAge: "Annuity Start Age",
        al_incomeTerm: "Income Term",
        al_totalPremium: "Total Premium",
        al_currency: "Currency",
        al_insurer: "Insurer",
        al_longevityAnnuityScore: "10Life Score",
        al_guaranteedInitialAnnualIncomeHightoLow: "Guaranteed Initial Annual Income (High to Low)",
        al_guaranteedReturnRatioatAge85HightoLow: "Guaranteed Return Ratio at Age 85 (High to Low)",
        al_guaranteedReturnRatioatAge105HightoLow: "Guaranteed Return Ratio at Age 105 (High to Low)",
        al_projectedReturnRatioatAge85HightoLow: "Projected Return Ratio at Age 85 (High to Low)",
        al_projectedReturnRatioatAge100HightoLow: "Projected Return Ratio at Age 100 (High to Low)",
        al_projectedReturnRatioatAge105HightoLow: "Projected Return Ratio at Age 105 (High to Low)",
        al_annuityPeriodShorttoLong: "Annuity Period (Short to Long)",
        al_mostPopularHightoLow: "Most Popular (High to Low)",
        al_userRatingHightoLow: "User Rating (High to Low)",
        al_guaranteedInitialAnnualIncome: "Guaranteed Initial Annual Income",
        al_guaranteedReturnRatioatAge85: "Guaranteed Return Ratio at Age 85",
        al_guaranteedReturnRatioatAge100: "Guaranteed Return Ratio at Age 100",
        al_projectedReturnRatioatAge85: "Projected Return Ratio at Age 85",
        al_projectedReturnRatioatAge100: "Projected Return Ratio at Age 100",
        al_annuityPeriod: "Annuity Period",
        al_firstYearPremium: "First Year Premium",
        al_premiumTerm: "Premium Term",
        al_totalPremiumPaid: "Total Premium Paid",
        al_projectedInitialAnnualIncome: "Projected Initial Annual Income",
        al_percentageofTotalIncomeBeingGuaranteed: "Percentage of Total Income Being Guaranteed",
        al_overview: "Overview",
        al_guaranteedReturn: "Guaranteed Return",
        al_projectedReturn: "Projected Return",
        al_lengthofCoverageTerm: "Length of Coverage Term",
        al_earlySurrenderCoverage: "Early Surrender Coverage",
        al_earlyDeathCoverage: "Early Death Coverage",
        al_guaranteedAnnuityBreakevenYear: "Guaranteed Breakeven Year",
        al_guaranteedAnnuityInternalRateofReturnIRR: "Guaranteed Annuity Internal Rate of Return (IRR)",
        al_deathatAge: "Death at Age $num",
        al_totalGuaranteedIncomeAge4554: "Total Guaranteed Income Age 45 - 54",
        al_totalGuaranteedIncomeAge5564: "Total Guaranteed Income Age 55 - 64",
        al_totalGuaranteedIncomeAge6574: "Total Guaranteed Income Age 65 - 74",
        al_totalGuaranteedIncomeAge7584: "Total Guaranteed Income Age 75 - 84",
        al_totalGuaranteedIncomeAge8594: "Total Guaranteed Income Age 85 - 94",
        al_totalGuaranteedIncomeAge95104: "Total Guaranteed Income Age 95 - 104",
        al_totalGuaranteedIncomeAge105114: "Total Guaranteed Income Age 105 - 114",
        al_guaranteedYearlyIncomeatAge105: "Guaranteed Yearly Income at Age 105",
        al_projectedAnnuityBreakevenYear: "Projected Annuity Breakeven Year",
        al_projectedAnnuityInternalRateofReturnIRR: "Projected Annuity Internal Rate of Return (IRR)",
        al_totalProjectedIncomeAge4554: "Total Projected Income Age 45 - 54",
        al_totalProjectedIncomeAge5564: "Total Projected Income Age 55 - 64",
        al_totalProjectedIncomeAge6574: "Total Projected Income Age 65 - 74",
        al_totalProjectedIncomeAge7584: "Total Projected Income Age 75 - 84",
        al_totalProjectedIncomeAge8594: "Total Projected Income Age 85 - 94",
        al_totalProjectedIncomeAge95104: "Total Projected Income Age 95 - 104",
        al_totalProjectedIncomeAge105114: "Total Projected Income Age 105 - 114",
        al_incomePeriod: "Income Period",
        al_guaranteedBenefitPerPremiumPaid: "Guaranteed Benefit Per Premium Paid",
        al_surrenderatThe10thPolicyYear: "Surrender at The 10th Policy Year",
        al_surrenderatThe20thPolicyYear: "Surrender at The 20th Policy Year",
        al_guaranteedBenefitPerPremiumPaidIncludingDeathBenefitReceivedIncomeDeathBenefitPremiumPaid: "Guaranteed Benefit Per Premium Paid, Including Death Benefit: (Received Income + Death Benefit) ÷ Premium Paid",
        al_deathatThe10thPolicyYear: "Death at The 10th Policy Year",
        al_deathatThe20thPolicyYear: "Death at The 20th Policy Year",
        al_wholelife: "whole life",
        ai_categoryName: "immediate annuity",
        ai_pageTitle: "Compare Immediate Annuity",
        ai_quickGuide: "Immediate Annuity Quick Guide",
        ai_result: "We found <strong>$num</strong> Immediate Annuity product(s) matching your selected filters.",
        ai_incomeTerm: "Income Term",
        ai_totalPremium: "Total Premium",
        ai_currency: "Currency",
        ai_insurer: "Insurer",
        ai_immediateAnnuityScore: "10Life Score",
        ai_5StarRatings: "5-Star Ratings",
        ai_guaranteedInitialAnnualIncomeHightoLow: "Guaranteed Initial Annual Income (High to Low)",
        ai_guaranteedReturnRatioatAge105HightoLow: "Guaranteed Return Ratio at Age 105 (High to Low)",
        ai_projectedReturnRatioatAge105HightoLow: "Projected Return Ratio at Age 105 (High to Low)",
        ai_annuityPeriodShorttoLong: "Annuity Period (Short to Long)",
        ai_mostPopularHightoLow: "Most Popular (High to Low)",
        ai_userRatingHightoLow: "User Rating (High to Low)",
        ai_longevityAnnuityScore: "10Life Score",
        ai_guaranteedIRRatAge85: "Guaranteed IRR at Age 85",
        ai_1stYearGuaranteedIncome: "1st Year Guaranteed Income",
        ai_1stYearExpectedIncome: "1st Year Expected Income",
        ai_annuityPeriod: "Annuity Period",
        ai_firstYearPremium: "First Year Premium",
        ai_premiumTerm: "Premium Term",
        ai_totalPremiumPaid: "Total Premium Paid",
        ai_percentageofTotalIncomeBeingGuaranteed: "Percentage of Total Income Being Guaranteed",
        ai_overview: "Overview",
        ai_guaranteedReturn: "Guaranteed Return",
        ai_projectedReturn: "Projected Return",
        ai_lengthofCoverageTerm: "Length of Coverage Term",
        ai_earlySurrenderCoverage: "Early Surrender Coverage",
        ai_earlyDeathCoverage: "Early Death Coverage",
        ai_guaranteedAnnuityBreakevenYear: "Guaranteed Annuity Breakeven Year",
        ai_guaranteedAnnuityInternalRateofReturnIRR: "Guaranteed Annuity Internal Rate of Return (IRR)",
        ai_deathatAge: "Death at Age $num",
        ai_totalGuaranteedIncomeAge4554: "Total Guaranteed Income Age 45 - 54",
        ai_totalGuaranteedIncomeAge5564: "Total Guaranteed Income Age 55 - 64",
        ai_totalGuaranteedIncomeAge6574: "Total Guaranteed Income Age 65 - 74",
        ai_totalGuaranteedIncomeAge7584: "Total Guaranteed Income Age 75 - 84",
        ai_totalGuaranteedIncomeAge8594: "Total Guaranteed Income Age 85 - 94",
        ai_totalGuaranteedIncomeAge95104: "Total Guaranteed Income Age 95 - 104",
        ai_totalGuaranteedIncomeAge105114: "Total Guaranteed Income Age 105 - 114",
        ai_guaranteedYearlyIncomeatAge105: "Guaranteed Yearly Income at Age 105",
        ai_projectedAnnuityBreakevenYear: "Projected Annuity Breakeven Year",
        ai_projectedAnnuityInternalRateofReturnIRR: "Projected Annuity Internal Rate of Return (IRR)",
        ai_totalProjectedIncomeAge4554: "Total Projected Income Age 45 - 54",
        ai_totalProjectedIncomeAge5564: "Total Projected Income Age 55 - 64",
        ai_totalProjectedIncomeAge6574: "Total Projected Income Age 65 - 74",
        ai_totalProjectedIncomeAge7584: "Total Projected Income Age 75 - 84",
        ai_totalProjectedIncomeAge8594: "Total Projected Income Age 85 - 94",
        ai_totalProjectedIncomeAge95104: "Total Projected Income Age 95 - 104",
        ai_totalProjectedIncomeAge105114: "Total Projected Income Age 105 - 114",
        ai_incomePeriod: "Income Period",
        ai_guaranteedBenefitPerPremiumPaid: "Guaranteed Benefit Per Premium Paid",
        ai_surrenderatThe10thPolicyYear: "Surrender at The 10th Policy Year",
        ai_surrenderatThe20thPolicyYear: "Surrender at The 20th Policy Year",
        ai_guaranteedBenefitPerPremiumPaidIncludingDeathBenefitReceivedIncomeDeathBenefitPremiumPaid: "Guaranteed Benefit Per Premium Paid, Including Death Benefit: (Received Income + Death Benefit) ÷ Premium Paid",
        ai_deathatThe10thPolicyYear: "Death at The 10th Policy Year",
        ai_deathatThe20thPolicyYear: "Death at The 20th Policy Year",
        ai_wholelife: "Whole life Annuity",
        ai_singlePremium: "Single Premium",
        objective_savings: "Savings",
        objective_retirement: "Retirement Income",
        objective_stable_retirement: "Stable Retirement Income",
        objective_longevity: "Hedge Longevity Risk",
        objective_growth_retirement_income: "Growth Retirement Income",
        twenty_years_above: "Above 20 years"
      },
      protection: {
        pt_categoryName: "whole life protection insurance",
        pt_pageTitle: "Compare Whole Life Protection",
        pt_quickGuide: "Whole Life Protection Quick Guide",
        pt_result: "We found <strong>$num</strong> Whole Life Protection Insurance product(s) matching your selected filters.",
        pt_yearpayment: "-year Payment",
        pt_paymentTermYearly: "Payment Term (Yearly)",
        pt_currency: "Currency",
        pt_gender: "Gender",
        pt_age: "Age",
        pt_totalPremium: "Total Premium",
        pt_insurer: "Insurer",
        pt_wholeLifeProtectionScore: "10Life Score",
        pt_5StarRatings: "5-Star Ratings",
        pt_age35_54AverageDeathBenefitHightoLow: "First 20 Years Avg. Death Benefit (High to Low)",
        pt_age55_84AverageDeathBenefitHightoLow: "21<sup>st</sup> - 50<sup>th</sup> Years Avg. Death Benefit (High to Low)",
        pt_mostPopularHightoLow: "Most Popular (High to Low)",
        pt_userRatingHightoLow: "User Rating (High to Low)",
        pt_age35_54AverageDeathBenefit: "First 20 Years Avg. Death Benefit",
        pt_age55_84AverageDeathBenefit: "21<sup>st</sup> - 50<sup>th</sup> Years Avg. Death Benefit",
        pt_annualPremium: "Annual Premium",
        pt_totalPremiumPaid: "Total Premium Paid",
        pt_guaranteedBreakevenYear: "Guaranteed Breakeven Year",
        pt_overview: "Overview",
        pt_guaranteedDeathBenefit: "Guaranteed Death Benefit",
        pt_policyValue: "Policy Value",
        pt_age35_44: "Age35-44",
        pt_age35: "Age 35",
        pt_age36: "Age 36",
        pt_age37: "Age 37",
        pt_age38: "Age 38",
        pt_age39: "Age 39",
        pt_age40: "Age 40",
        pt_age41: "Age 41",
        pt_age42: "Age 42",
        pt_age43: "Age 43",
        pt_age44: "Age 44",
        pt_age45_54: "Age45-54",
        pt_age45: "Age 45",
        pt_age46: "Age 46",
        pt_age47: "Age 47",
        pt_age48: "Age 48",
        pt_age49: "Age 49",
        pt_age50: "Age 50",
        pt_age51: "Age 51",
        pt_age52: "Age 52",
        pt_age53: "Age 53",
        pt_age54: "Age 54",
        pt_age55_64: "Age55-64",
        pt_age55: "Age 55",
        pt_age56: "Age 56",
        pt_age57: "Age 57",
        pt_age58: "Age 58",
        pt_age59: "Age 59",
        pt_age60: "Age 60",
        pt_age61: "Age 61",
        pt_age62: "Age 62",
        pt_age63: "Age 63",
        pt_age64: "Age 64",
        pt_age65_74: "Age65-74",
        pt_age65: "Age 65",
        pt_age66: "Age 66",
        pt_age67: "Age 67",
        pt_age68: "Age 68",
        pt_age69: "Age 69",
        pt_age70: "Age 70",
        pt_age71: "Age 71",
        pt_age72: "Age 72",
        pt_age73: "Age 73",
        pt_age74: "Age 74",
        pt_age75_84: "Age75-84",
        pt_age75: "Age 75",
        pt_age76: "Age 76",
        pt_age77: "Age 77",
        pt_age78: "Age 78",
        pt_age79: "Age 79",
        pt_age80: "Age 80",
        pt_age81: "Age 81",
        pt_age82: "Age 82",
        pt_age83: "Age 83",
        pt_age84: "Age 84",
        pt_age85_94: "Age85-94",
        pt_age85: "Age 85",
        pt_age86: "Age 86",
        pt_age87: "Age 87",
        pt_age88: "Age 88",
        pt_age89: "Age 89",
        pt_age90: "Age 90",
        pt_age91: "Age 91",
        pt_age92: "Age 92",
        pt_age93: "Age 93",
        pt_age94: "Age 94",
        pt_age95_99: "Age95-99",
        pt_age95: "Age 95",
        pt_age96: "Age 96",
        pt_age97: "Age 97",
        pt_age98: "Age 98",
        pt_age99: "Age 99",
        pt_IRR: "Internal Rate of Return (IRR) at the 30th Policy Year",
        pt_30year: "The 30th Year",
        pt_cannotBreakeven: "Cannot Breakeven",
        pt_year: "$year Year",
        pt_yearRange: "$from-$to Years",
        pt_first10Year: "First 10 Years",
        pt_ordinalNumber: "$num",
        pt_specialFeature: "Special Feature",
        pt_earlyYearsBooster: "Early Years Booster",
        pt_accidentalDeathBenefit: "Accidental Death Benefit",
        pt_terminalIllnessBenefit: "Terminal Illness Benefit",
        pt_premiumHoliday: "Premium Holiday",
        pt_guaranteedInsurabilityOption: "Guaranteed Insurability Option",
        pt_premiumWaiver: "Premium Waiver",
        pt_deathSettlementOptions: "Death Settlement Options",
        pt_options: "options"
      },
      immediateAnnuity: 'Immediate Annuity',
      home: {
        hm_categoryName: "Home Insurance",
        hm_ownerType: "Occupancy Type",
        hm_tenant: "Tenant",
        hm_landlord: "Landlord",
        hm_owner: "Self-Occupied",
        hm_buildingType: "Building Type",
        hm_mb: "Multi-storey building",
        hm_vh: "Village House",
        hm_dh: "Detached House",
        hm_sdh: "Semi-detached House",
        hm_buildingAge: "Property Age (Year)",
        hm_buildingAgeFeature: "Property Age {replace_age}",
        hm_grossArea: "Gross Floor Size (sq ft)",
        hm_grossAreaFeature: "Gross Floor Size {replace_range} sq ft",
        hm_saleableArea: "Saleable Area (sq ft) ",
        hm_saleableAreaFeature: "Saleable Area {replace_range} sq ft",
        hm_planType: "Plan Type",
        hm_basic: "Basic",
        hm_overallScore: "10Life Score",
        hm_homeContentsScore: "Home Content Score",
        hm_liabilityScore: "Liability Score",
        hm_personalAccidentScore: "Personal Accident Score",
        hm_annualPremium: "Annual Premium (HKD)​",
        hm_firstYearPremium: "First Year Premium (HKD)",
        hm_result: "We found <strong>$num</strong> Home Insurance product(s) matching your selected filters.",
        hm_overview: "Overview",
        hm_hcMaximumProtection: "Home Contents Maximum Protection (HKD)",
        hm_llMaximumProtection: "Legal Liabilities Maximum Protection (HKD)",
        hm_paMaximumProtection: "Personal Accident Maximum Protection (HKD)",
        hm_propertyAgeMax: "Max Age of Property (Years)",
        hm_propertyType: "Property Type",
        hm_homeContents: "Home Contents",
        hm_legalLiabilities: "Legal Liabilities",
        hm_personalAccident: "Personal Accident",
        hm_maximumCoverage: "Maximum Coverage",
        hm_noAddLocCovered: "No. of Additional Locations Covered xxx",
        hm_newResidence: "New Residence",
        hm_perValOutdoor: "Personal Valuables & Properties in the outdoor",
        hm_tempRes: "At temporary residence",
        hm_noCausationsCovered: "No. of Causations covered",
        hm_weather: "Weather",
        hm_fireLightening: "Fire/Lightening",
        hm_debrisRemoval: "Debris Removal",
        hm_duringTransit: "During Transit",
        hm_burglary: "Burglary",
        hm_duringRepairs: "During interior decoration/repairs",
        hm_malicious: "Malicious Damage by Tenant (For Landlords Selection only)",
        hm_severityInjuries: "Severity of Injuries covered",
        hm_causationAccidents: "Causation of Accidents covered",
        hm_keyTypesOfHomeContentsCovered: "Key types of Home Contents Covered​",
        hm_limitPerItem: "Limit per item ($)",
        hm_scoreDetails: "Score Details",
        hm_10life_selection: "10Life Selection",
        hm_nolimit: "Unlimited / Not specified",
      },
      travel: {
        td_result: "We found <strong>$num</strong> Travel Insurance product(s) matching your selected filters.",
        td_sorting_adult_premium: "Adult Premium (low to high)",
        td_adult_premium: "Adult Premium",
        td_overall_score: "10Life Score",
        td_medical_emergency_score: " Medical and Emergency Assistance Score",
        td_accident_score: "Personal Accident Score​",
        td_inconv_score: "Trip Inconvenience Score",
        td_property_score: "Personal Property Score",
        td_categoryName: "Travel Insurance",
        td_mobile_protection: "Mobile Phone Protection",
        td_physio: "Follow-up Physiotherapy",
        td_car_rental: "Car rental excess",
        td_extreme_sports: "Extreme Sports Medical Coverage",
        td_loss_travel_doc: "Loss of Travel Document",
        td_overview: "Overview",
        td_section_medical_emergency: "Medical and Emergency Assistance",
        td_section_property: "Personal Property",
        td_section_trip_inconv: "Trip Inconvenience",
        td_section_accident: "Personal Accident",
        td_subscore_detail: "10Life Sub-score Details",
        td_medical_score: "Medical Score",
        td_emergency_score: "Emergency Assistance Score​",
        td_delay_score: "Delay Score​",
        td_trip_cancel_score: "Trip Cancellation and Curtailment Score​",
        td_laptop_protection: "Laptop Protection​",
        td_camera_protection: "Camera and Digital Recorder Protection",
        td_credit_card: "Fraudulent Use of Credit Card",
        td_oversea_hospital: "Overseas Hospital Cash",
        td_chinese_med: "Follow-up Chinese Medical Practitioner Medical Expenses​",
        td_chiro: "Follow-up Chiropractor Medical Expenses​",
        td_addition_loss_of_travel_doc: "Additional Travel and/or Accommodation Expenses due to Loss of Travel Document",
        td_airline_cancel: "Trip Cancellation Coverage due to Winding-up of airline company​",
        td_agent_bankrupt: "Trip Cancellation Coverage due to Bankruptcy of Travel Agents",
        td_home_burglary: "Trip Cancellation Coverage due to Burglary of the Principal Home of the Insured​",
        td_product_feature: "Product Features",
        td_dayUnit: "day(s)",
        td_hourUnit: "hours",
        td_detail_chinese_med: "Chinese Medical Practitioner Follow-up",
        td_detail_chiro: "Chiropractor Follow-up",
        td_detail_physio: "Physiotherapy Follow-up",
        td_detail_oversea_hos_cash: "Overseas Hospital Cash",
        td_detail_compassionate_visit: "Compassionate Visit Details​",
        td_detail_return_unattend_child: "Return of Unattended Child(ren) Details",
        td_term_term: "Terms",
        td_term_mobile: "Mobile Phone Terms",
        td_term_laptop: "Laptop Computer Terms",
        td_term_benefit: "Benefit Terms",
        td_default_geo: "Japan / 日本",
        td_detail_adult_premium: "-day Adult Premium",
        td_unlimited: "Unlimited / Actual costs​",
        td_not_specified: "Not specified, subject to the terms and conditions",
        td_10life_selection: "10Life Selection",
        td_outbound: "Outbound",
        td_returning: "Returning",
        td_feature_filter_title: "Filter",
        td_feature_selection: "Select product features",
        td_feature_reset: "Reset",
        td_selected: "Selected",
        td_number_of_feature: "feature(s)"
      },

      personal_accident: {
        pd_categoryName: "Personal Accident Insurance",
        pd_occupation: "Occupation",
        pd_sum_assured: "Sum Assured",
        pd_flexible_sa: "Optional Sum Assured",
        pd_adult_premium: "Adult Premium",
        pd_sorting_adult_premium: "Adult Premium (low to high)",
        pd_overall_score: "10Life Score",
        pd_death_dismember_score: "Death and Dismemberment Score",
        pd_death_dismember: "Death and Dismemberment",
        pd_medical_score: "Medical Score",
        pd_medical: "Medical",
        pd_physio_score: "Physiotherapy Score",
        pd_physio_chinese: "Physiotherapy and Chinese Medical Practitioner",
        pd_chinese_med: "Chinese Medical Practitioner Score",
        pd_office_worker: "Office Worker",
        pd_result: "We found <strong>$num</strong> Personal Accident Insurance product(s) matching your selected filters.",
        pd_overview: "Overview",
        pd_accident_death_score: "Death Score",
        pd_dismemberment_score: "Dismemberment Score",
        pd_death_coverage_sum_assured: "Death Coverage (Sum Assured)",
        pd_dismemberment_loss_all_toes: "Loss of use of all Toes on one foot",
        pd_death_benefit: "Death Benefit",
        pd_dismemberment_coverage_sum_assured: "Dismemberment Coverage (Sum Assured)",
        // pd_dismemberment_loss_all_toes: "Loss of use of all Toes on one foot",
        // pd_dismemberment_coverage_sum_assured: "Dismemberment Coverage (Sum Assured)",
        pd_dismemberment_loss_two_limbs: "Loss of or Permanent Total Loss of two Limbs",
        pd_dismemberment_loss_one_limb: "Loss of or Permanent Total Loss of one Limbs",
        pd_dismemberment_loss_both_eyes: "Loss of sight (Both eyes)",
        pd_dismemberment_loss_one_eye: "Loss of sight (One eye)",
        pd_dismemberment_loss_both_ears: "Loss of Hearing (Both ears)",
        pd_dismemberment_loss_one_ear: "Loss of Hearing (One ear)",
        pd_dismemberment_loss_speech: "Loss of Speech",
        pd_dismemberment_loss_thumbs_fingers_main: "Loss of use of Thumbs and 4 Fingers of Main Hand​",
        pd_dismemberment_loss_thumbs_fingers_another: "Loss of use of Thumbs and 4 Fingers of Another Hand​",
        pd_medical_expenses: "Medical Expenses Overall Limit",
        pd_medical_estimated_coverage: "Estimated Coverage of Selected Diseases",
        pd_medical_case_1_recovery_rate: "Orthopedics (with MRI Scan)",
        pd_medical_case_2_recovery_rate: "Neurology (with CT Scan)",
        pd_medical_case_3_recovery_rate: "Outpatient (with Tetanus Toxoid for wound)",
        pd_medical_case_4_recovery_rate: "Anterior Cruciate Ligament Surgery",
        pd_physio_follow_up: "Physiotherapy Follow-up",
        pd_physio_limit_per_policy_year: "Visits Limit per Policy",
        pd_physio_limit_per_visit: "Daily Limit",
        pd_physio_total_limit: "Total Limit",
        pd_chinese_medical_follow_up: "Chinese Medical Practitioner Follow-up",
        pd_bonesetter_limit_per_policy_year: "Visits Limit per Policy",
        pd_bonesetter_limit_per_visit: "Daily Limit",
        pd_bonesetter_total_limit: "Total Limit",
        pd_not_specified: "Not Specified",
        pd_na: "N/A",
        pd_firstYearPremium: "First Year Premium",
        pd_sorting_firstYear_premium: "First Year Premium (low to high)",
        pd_min_issue_age: "Minimum Issue Age",
        pd_max_issue_age: "Maximum Issue Age",
        pd_max_cover_age: "Maximum Renewal Age"
      },
    },
    product_promotion: {
      pop_up_text: 'Learn More',
      last_call: 'Final call',
    },
    product_promotion_detail: {
      download: 'Download',
      related_documents: 'Related Documents'
    },
    productShortcut: {
      happyKids: "Happy Kids Certification",
      fulfilmentRatio: "Fulfillment Ratio Insurer"
    },
    lifeStage: {
      card: {
        monthly: 'Monthly',
        up: 'Up'
      }
    },
    scoring_methodology: {
      articleAnchor: 'Table of Content',
    },
    transitionPage: {
      goingToPart1: "You are being redirected to",
      goingToPart2: "website"
    },
    faqPage: {
      fp_pageTitle: "FAQ",
      fp_general: "General",
      fp_methodology: "Methodology"
    },
    fiveStar: {
      awardProductSection: 'Award Winning Products',
      awardProductButton: 'Learn More',
      lastUpdateDate: 'Last Updated',
      filterMenuAll: 'All',
      relatedDoc: 'Related Documents',
      relatedDocButtonText: 'Download'
    },
    datePicker: {
      title: 'Trip',
      placeholder: 'please select trip date'
    },
    h1_text: {
      homepage: "10Life｜HK's Leading Insurance Comparison Platform",
      lifeInsurerOfTheYear: 'Life Insurer of the Year | 5 Star Hong Kong Insurance Award 2022',
      healthInsurerOfTheYear: 'Health Insurer of the Year | 5 Star Hong Kong Insurance Award 2022',
      vhis: 'VHIS | 5 Star Hong Kong Insurance Award 2022',
      highEndMedical: 'High End Medical | 5 Star Hong Kong Insurance Award 2022 ',
      wholeLifeCriticalIllness: 'Whole Life Critical Illness | 5 Star Hong Kong Insurance Award 2022',
      termCriticalIllness: 'Term Critical Illness | 5 Star Hong Kong Insurance Award 2022',
      savingInsuranceForEducation: 'Savings Insurance (Education) | 5 Star Hong Kong Insurance Award 2022',
      savingInsuranceForRetirement: 'Savings Insurance (Retirement) | 5 Star Hong Kong Insurance Award 2022',
      savingInsuranceForLegacy: 'Savings Insurance (Legacy) | 5 Star Hong Kong Insurance Award 2022',
      wholeLifeProtection: 'Whole Life Protection | 5 Star Hong Kong Insurance Award 2022'
    },

    filter: {
      dropDownSelect: "Selected",
      dropDownRemove: "Remove",
      no_result: "No exact match. Please try again."
    },

    breadcrumb: {
      home: "Home",
      products: {
        title: "Compare Insurance",
        medical: "Medical Insurance",
        termCriticalIllness: "Term Critical Illness Insurance",
        wholeLifeCriticalIllness: "Whole Life Critical Illness Insurance",
        termCancer: "Term Cancer Insurance",
        shortTermSavings: "Short Term Saving Insurance",
        longTermSavings: "Long Term Saving Insurance",
        immediateAnnuity: "Immediate Annuity",
        deferredAnnuity: {
          default: "QDAP",
          // default: "QDAP - Saving",
          retirement: "QDAP - Retirement",
          longevity: "QDAP - Longevity"
        },
        termLife: "Term Life Insurance",
        wholeLifeProtection: "Whole Life Insurance",
        personalAccident: "Personal Accident Insurance",
        home: "Home Insurance",
        travel :"Travel Insurance"
      },
      insurer: {
        title: "Insurer"
      },
      advisorService: {
        title: "Advisor Service"
      },
      fiveStarProducts: {
        title: "5-Star",
        InsurerOfTheYear: "Insurer Of the Year",
        methodology: "Methodology"
      },
      happyKids: {
        title: "Happy Kids",
        methodology: "Methodology"
      },
      scoringMethodology: {
        title: "Scoring Methodology",
        medicalCoverage: "Medical Insurance",
        wholeLifeCriticalIllness: "Whole Life Criticial Illness Insurance",
        termCriticalIllness: "Term Critical Illness Insurance",
        termCancer: "Term Cancer Insurance",
        wholeLifeProtection: "Whole Life Protection",
        termLife: "Term Life Insurance",
        shortTermSavings: "Short Term Saving Insurance",
        longTermSavings: "Long Term Saving Insurance",
        retirementQdap: "QDAP (Retirement)",
        savingsQdap: "QDAP (Saving)",
        longevityQdap: "QDAP (Longevity)",
        immediateAnnuity: "Immediate Annuity",
        personalAccident: "Personal Accident Insurance",
        homeInsurance: "Home Insurance ",
        travelSingleTrip :"Travel Insurance"
      },
      aboutUs: {
        title: "About 10Life"
      },
      latestPromotion: {
        title: "Latest Promotion"
      },
      media: {
        title: "Media Centre"
      },
      faq: {
        title: "FAQ"
      },
      career: {
        title: "Join 10Life"
      },
      blog: {
        title: "Blog",
        search: "Search"
      },
      terms: {
        title: "Terms of Use "
      },
      privacy: {
        title: "Privacy Policy"
      },
      cookies: {
        title: "Cookies"
      }
    },
    insurerPage: {
      knowAbout: "Know About ",
      aboutRelatedProducts: "Know About Related Products",
      moreProducts: "More Products",
      products: " Products",
      faq: "FAQ",
    },
    qdap: {
      questionnaire: {
        title: '扣稅年金點揀好？',
        desc: '市場上扣稅年金計劃花多眼亂，唔容易比較。立即回答幾條簡單問題，幫你篩選符合特定理財目標的產品！',
        disclaimer: {
          title: '重要聲明',
          content: '以上資訊僅供參考，並根據所輸入的資料及相關假設進行篩選生成。資訊並沒有考慮閣下之個人需要，閱覽有關資訊亦不應被視為正在進行個人合適性評估，亦不足以構成買入任何保險產品決定之基礎。無論你是否參考此資訊，在作出投保決定前，你必須衡量個人可承受風險的程度及你的財政狀況，徵詢持牌顧問的意見，並以保險公司提供的資料為準。你不應純粹單靠此資訊作出任何投保決定。'
        },
        btnStart: '立即開始',
        btnPrev: '上一步',
        btnNext: '下一步',
        btnReset: '重新開始',
        loadingMsg: '請等等，好快有結果',
        errorMsg: '沒有相應的產品，請重新輸入你的資料。',
        questions: [
          {
            question: '你今年幾歲？​',
            questionRemark: '以剛過去的生日計算​',
            answer: ['歲'],
            placeholder: '請選擇',
            tips: {
              title: '扣稅年金小貼士',
              content: '投保人須持有效香港身份證，年齡介乎18歲至80歲，視乎產品而定。每名納稅人的年金扣稅上限為每年60,000港元，以薪俸稅最高稅率17%計算，每年最多可慳稅10,200港元。'
            }
          },
          {
            question: '你買年金的目標？​',
            questionRemark: '除扣稅外​',
            answer: ['回本快​', '收長糧​'],
            tips: {
              title: '扣稅年金小貼士',
              content: '除了慳稅，扣稅年金用途包括儲蓄、為退休生活「自製長糧」及對沖長壽風險等。年金保費可滾存增值，同時讓受保人退休後有穩定收入來源。'
            }
          },
          {
            question: '你想幾歲開始拎？​',
            questionRemark: '預計的退休年齡或需要現金流的歲數​',
            answer: ['50歲', '55歲', '60歲', '65歲'],
            placeholder: '請選擇​理想的年金開始年齡',
            tips: {
              title: '扣稅年金小貼士',
              content: '保監局要求延期年金計劃的領取年齡為50歲以上。一般來說，滾存期越長，年金的累積金額就越大，受保人可領取的年金收入亦越高。'
            }
          },
          {
            question: '你想拎幾多年？​',
            questionRemark: '預計需要年金收入的年期',
            placeholder: '請選擇​理想的年金領取期',
            answer: ['10年', '20年', '20年以上'],
            tips: {
              title: '扣稅年金小貼士',
              content: '保監局規定延期年金的年金領取期最短為10年，部份計劃的年金領取期是直至100歲，以對沖長壽風險。不過，一般來說，年金期愈長，每月年金可能相對減少。所以，投保人因考慮個人的預期壽命、財務狀況，選擇合適的年金領取期。'
            }
          },
        ],
        productCard: {
          year: '年',
          around: '約',
          promotion: '限時優惠',
          btnWhatapp: 'WhatsApp 投保',
          incomeMsg: '以下扣稅年金的估算金額，年金期由{X}歲開始，可派發年金{Y}。',
          totalIncome: '10年總供款',
          errorMsg: {
            min: '因應保費最低要求，10年總供款不應低於180,000港元',
            max: '如需處理$1,000萬港元以上的10年供款，請與10Life顧問聯絡',
            maxBtnReadMore: '→ WhatsApp 查詢',
            numberOnly: '請輸入數字',
          },
          breakdown: {
            year: '保證回本年',
            guaranteedIrrY8: '第8年保證 IRR',
            guaranteedIrrY8ToolTip: '第8年提早退保的保證內部<span style="display: inline-block;">回報率（IRR）</span>',
            projectedIrrY8: '第8年預期 IRR',
            projectedIrrY8ToolTip: '第8年提早退保的預期內部<span style="display: inline-block;">回報率（IRR）</span>',
          },
          income: {
            firstYearAnnuity: '預期首年年金​',
            totalGuaranteedIncome: '{Y}保證總<em>年金</em>',
            totalProjectedIncome: '{Y}預期總<em>年金</em>',
            greaterThan20yP: '全期',
          },
          whatsappMsg: {
            breakdown: '回本快',
            income: '收長糧',
            incomeAge: '年金開始年齡：{A}歲',
            incomePeriod: '年金領取期：{Y}',
            tenYearsIncomePeriod: '10年總供款：HKD {Y},000',
            tenYearsIncomePeriodInquiry: '10年總供款：>HKD {Y},000',
            msgLine1: '我看到「扣稅年金點揀好？」，想了解 {N}',
            msgLine1Inquiry: '我看到「扣稅年金點揀好？」，想了解更多',
            msgLine2: '年齡：{A}歲',
            msgLine3: '年金目標：{G}',
          }
        },
        remarks: {
          title: '註：',
          content: ['以上顯示回報數字，僅供參考之用，一切應以保險公司資料為準。實際回報數字受年齡、投保額、保單貨幣和匯率、稅務狀況等因素影響。', '以上回報數字假設保單持有人是 45 歲非吸煙男性、 5 年供款期、按產品當時提供的折扣優惠後（如有）總供款為 30 萬港元（以美元保單為基準折算，並未計及保費徵費），以及保單持有人於供款期間每年可節省10,200 港元稅款的情景下，計算保證及預期內部回報率。​']
        },
        assumption: {
          title: '註：',
          content: ['以上顯示金額為估算金額，僅供參考之用，一切應以保險公司資料為準。實際年金金額受年齡、投保額、保單貨幣和匯率、滾存及入息期等因素影響。​​', '顯示金額假設保單持有人於特定歲數開始，分 10 年合共供款指定金額，並未計及保費徵費及任何優惠。','如選取派發年期 20 年以上，「全期」指至 105 歲（如適用）的總年金以作比較。'],
        }
      }
    }
  }
}
