import { render, staticRenderFns } from "./VideoModal.vue?vue&type=template&id=ffb4c52e&scoped=true"
import script from "./VideoModal.vue?vue&type=script&lang=js"
export * from "./VideoModal.vue?vue&type=script&lang=js"
import style0 from "./VideoModal.vue?vue&type=style&index=0&id=ffb4c52e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffb4c52e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoComponent: require('/app/components/common/VideoComponent.vue').default,ModalWrapper: require('/app/components/modal/common/ModalWrapper.vue').default})
