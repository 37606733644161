
export default {
  name: 'HomepageFaq',
  props: {
    data: {
      type: Object,
      default: () => ({
        Title: undefined,
        Description: undefined,
        ButtonText: undefined,
        ButtonLink: undefined,
        FAQQuestions: []
      }),
    },
  },
  data() {
    return { 
      activeItemId: 0,
    }
  },
  computed: {
    // id() {
    //   return this.$route.params.id
    // },
  },
  methods: {
    toggleActiveItem(i) {
      if (i === this.activeItemId) {
        this.activeItemId = -1
      } else {
        this.setActiveItem(i)
      }
    },

    setActiveItem(i) {
      this.activeItemId = i
    }
  },
}
