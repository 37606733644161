import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, configure, setInteractionMode } from 'vee-validate';
import { required, email, regex, min, max, confirmed } from "vee-validate/dist/rules";

Vue.component('ValidationProvider', ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("required", required);
extend("email", email);
extend("regex", regex);
extend("min", min);
extend("max", max);
extend("confirmed", confirmed);

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    // dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
  }
});

setInteractionMode('eager');