export const state = () => ({
  hasProductDisclaimerConfirmed: false,
  hasBlogDisclaimerConfirmed: false,
  disclaimerConfirmedBlogs: [],
})

export const mutations = {
  setHasProductDisclaimerConfirmed(state, data) {
    state.hasProductDisclaimerConfirmed = data;
  },
  setHasBlogDisclaimerConfirmed(state, data) {
    state.hasBlogDisclaimerConfirmed = data;
  },
  setDisclaimerConfirmedBlog(state, data) {
    state.disclaimerConfirmedBlogs.push(data);
  }
}

export const getters = {
  hasProductDisclaimerConfirmed: (state) => {
    return state.hasProductDisclaimerConfirmed;
  },
  hasBlogDisclaimerConfirmed: (state) => {
    return state.hasBlogDisclaimerConfirmed;
  },
  disclaimerConfirmedBlogs: (state) => {
    return state.disclaimerConfirmedBlogs;
  },
}