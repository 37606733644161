
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        Introduction: {},
        Links: [],
      }),
    },
    mobileOnly: {
      type: Boolean,
      default: false,
    },
  },
};
