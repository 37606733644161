
import _ from 'lodash';

export default {
  props: {
    useWhiteStyle: {
      type: Boolean,
      required: true,
    },
    mobileOnly: {
      type: Boolean,
      default: false,
    },
    isMenuOn: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    profileLink() {
      return process.env.AUTH_BASE_URL+`/account/profile?ui_locale=${this.$i18n.locale}`;
    },
  },

  methods: {
    fblogout() {
      window.FB.getLoginStatus((loginStatus) => {
        if (loginStatus.status === 'connected') {
          window.FB.logout((response) => {
            console.info('user logged out');
          });
        }
      });
    },

    logout() {
      // facebook logout
      // this.fblogout();

      // google logout - no need as sign-in status for your app and a Google Account are independent
      // see ref - https://developers.google.com/identity/gsi/web/guides/migration
        this.$auth.logout();
    },

    debouncedLogout: _.debounce(function() {
      this.logout();
    }, 300), // 300 milliseconds debounce time

    closeModal() {
      if (this.mobileOnly) {
        this.$emit('close-modal');
      }
    },
  },
};
