import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faArrowRight as freeFasFaArrowRight,
  faPlus as freeFasFaPlus,
  faMinus as freeFasFaMinus,
  faAnglesLeft as freeFasFaAnglesLeft,
  faAngleLeft as freeFasFaAngleLeft,
  faAnglesRight as freeFasFaAnglesRight,
  faAngleRight as freeFasFaAngleRight,
  faTimes as freeFasFaTimes,
  faAngleDown as freeFasFaAngleDown,
  faXmark as freeFasFaXmark,
  faFilter as freeFasFaFilter,
  faCircleChevronDown as freeFasFaCircleChevronDown,
  faCaretDown as freeFasFaCaretDown
} from '@fortawesome/free-solid-svg-icons'

library.add(
  freeFasFaArrowRight,
  freeFasFaPlus,
  freeFasFaMinus,
  freeFasFaAnglesLeft,
  freeFasFaAngleLeft,
  freeFasFaAnglesRight,
  freeFasFaAngleRight,
  freeFasFaTimes,
  freeFasFaAngleDown,
  freeFasFaXmark,
  freeFasFaFilter,
  freeFasFaCircleChevronDown,
  freeFasFaCaretDown
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
