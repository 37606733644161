export default {
  data() {
    return {
      result: '',
      error: ''
    }
  },
  methods: {
    outputResult(output, resultTimeoutMilliseconds = 5000) {
      this.result = output;
      setTimeout(() => {
        this.result = '';
      }, resultTimeoutMilliseconds);
    },

    outputError(output, resultTimeoutMilliseconds = 5000) {
      this.error = output;
      setTimeout(() => {
        this.error = '';
      }, resultTimeoutMilliseconds);
    }
  }
}