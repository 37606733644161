
export default {
    name: "HomepageVideoGallerySection",

    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
          loading: true,
          // categoryButtons: [],
          activeCatCode: 'health'
        }
    },

    computed: {
      activeCards() {
        return this.data?.Videos.filter(video => video.category_code === this.activeCatCode);
      },
      categoryButtons() {
        return this.data?.VideoCategoryButton;
      }
    },

    methods: {
      changeCategory(category) {
        try {
          this.activeCatCode = category;
        } catch(e) {
          console.error(e);
          this.$sentry.captureException(e);
        }
      }
    },

    mounted() {
      // this.categoryButtons = this.data?.VideoCategoryButton;
      this.$nextTick(() => {
        setTimeout(() => {
          this.loading = false;
        }, 1000)
      })
      console.debug('activeCatCode:', this.activeCatCode)
    },

}
