
import processImageLink from '../../mixins/processImageLink.js'
import ReusableLink from '../common/ReusableLink.vue';

export default {
  name: 'HomepageProductShortcut',
  props: {
    data: {
      type: Object,
      default: () => ({
        Title: undefined,
      }),
    },
  },
  mixins: [processImageLink],
  data() {
    return {
    }
  },
  computed: {
    shortcutData() {
      return this.data?.ProductShortcutItem || []
    }

  },
  methods: {
    toggleActiveItem(i) {
      if (i === this.activeItemId) {
        this.activeItemId = -1
      } else {
        this.setActiveItem(i)
      }
    },

    setActiveItem(i) {
      this.activeItemId = i
    },

    getRouteName() {
      return (this.$route.name).substring(0, (this.$route.name).indexOf('___'));
    },
    getEventTrackingLocationName() {
      if (this.getRouteName() === 'index')
        return 'homepage'
      if (this.getRouteName() === 'life-stage')
        return 'lifestage'
      if (this.getRouteName() === 'advisor-service')
        return 'broker'

      return 'other'
    },
    handleEventTracking(client){
      const params = {}
      params.categoryPrefix = `${this.getEventTrackingLocationName()}_`

      window.eventTracking({
        a:`open_${client}`,
        c: params.categoryPrefix + 'cta',
        l:``,
        cd:{
          "login_status":'NA'
        },
        save_action_entrypoint_with_label: false
      })
    }

  },
}
