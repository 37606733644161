
export default {
    name: "BackToTopButton",

    data() {
        return {
            backToTopBtnActive: false,
        }
    },

    computed: {
        backToTopImgPath() {
            return require(`@/assets/images/common/back-to-top-button.svg`)
        },
    },

    methods: {
        // Scroll to the top of the page
        jumpToTop() {
          window.scrollTo(0, 0);
        },

        // Handle scroll event
        handleScroll() {
          // Set backToTopBtnActive based on the screen width and height
          this.backToTopBtnActive = window.scrollY > 400;
        }
    },

    mounted() {
        // Add a scroll event listener to show/hide the button
        window.addEventListener("scroll", this.handleScroll);
    
        // Handle screen size changes
        window.addEventListener('resize', this.handleScroll); 

        // Initial check for scroll position (in case the page loads already scrolled)
        this.handleScroll();
    },

    beforeDestroy() {
        // Remove the scroll event listener when the component is destroyed
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleScroll);
    }
}
