const IMAGE_BASE_URI = process.env.IMAGE_BASE_URI;
const pattern = new RegExp(`${IMAGE_BASE_URI}`, 'gi');
export default {
  methods: {
    /**
     * Replacement of link
     * Replace s3 links to nuxt-img links, images will be converted to webp, inherit http2 & cdn from the parent domain 
     * @param input string input html/url string
     * @param format string
     * @param aliasLinkOnly boolean 
     */
    convertNuxtImageLink(input, aliasLinkOnly, format = 'webp') {
      let output = input;
      // default use webp, full formated link for non nuxt-img element
      let replacement = "/_ipx/f_webp/s3";
      // only change to alias link for nuxt-img element
      if(aliasLinkOnly){
        replacement = "/s3";
      } else if(format === 'webp'){
        // can be extended to use other format
        replacement = "/_ipx/f_webp/s3";
      } else {
        // console.log('format is not webp or no format input, use default')
      }
      // only run in client process
//      if (!process.server) {
        try {
          if (format == 'pdf') {
            return output;
          }
          output = input.replace(pattern, replacement);        
        } catch (error) {
          console.debug('processImageLink: ', error.message);
        }
//      }
      return output;
    }, 
  }
}