
import TutorialButton from './tutorial/TutorialButton.vue';
import Try from '~/helpers/tryCatch.js';
import commonMixin from '~/mixins/output.js';
import { VALIDATION_RULES } from '@/constants/';

export default {
  name: 'Footer',
  components: { TutorialButton },
  mixins: [commonMixin],

  props: {
    testBoo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      clientVersion: this.$config.clientVersion,
      activeItemId: 0,

      logo_img: require('@/assets/images/common/tenlife-white-logo.png'),

      facebookLink: 'https://www.facebook.com/10LifeHK/',
      instagramLink: 'https://www.instagram.com/10lifehk/',
      linkedinLink: 'https://hk.linkedin.com/company/10life',
      youtubeLink: 'https://www.youtube.com/channel/UCz8b7EYrOF4iXFIsap30kkw',

      input: { email: '' },
      rules: VALIDATION_RULES,

      // this year value.
      year: new Date().getFullYear(),
    };
  },
  methods: {
    setActiveItem(i) {
      this.activeItemId = i;
    },
    openContactUsFormModal() {
      this.$store.commit('modal/setShowContactUsFormModal', true);
    },
    resetInput() {
      for (const inputKey of Object.keys(this.input)) {
        this.input[inputKey] = undefined;
      }

      this.$nextTick(() => {
        this.formReset();
      });
    },
    async postNewsletterSubscriber() {
      const postParams = {
        data: {
          email: this.input.email,
        },
      };

      const [err, res] = await Try(this.$postNewsletterSubscriber(postParams));

      if (err) return this.outputError(this.$t('footer.enews_section_error_message'));

      this.outputResult(this.$t('footer.enews_section_successful_message'));
      this.resetInput();
    },
    formReset() {
      this.$refs.form.reset();
    },
  },
};
