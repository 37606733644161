import { render, staticRenderFns } from "./NavigationContentMobileContainer.vue?vue&type=template&id=1fff55d9&scoped=true"
import script from "./NavigationContentMobileContainer.vue?vue&type=script&lang=js"
export * from "./NavigationContentMobileContainer.vue?vue&type=script&lang=js"
import style0 from "./NavigationContentMobileContainer.vue?vue&type=style&index=0&id=1fff55d9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fff55d9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationContentDecoder: require('/app/components/navigation-bar/navigation-content/modules/NavigationContentDecoder.vue').default,NavigationContentBroker: require('/app/components/navigation-bar/navigation-content/modules/NavigationContentBroker.vue').default,NavigationContentLifestage: require('/app/components/navigation-bar/navigation-content/modules/NavigationContentLifestage.vue').default,NavigationContentAboutUs: require('/app/components/navigation-bar/navigation-content/modules/NavigationContentAboutUs.vue').default,ReusableLink: require('/app/components/common/ReusableLink.vue').default})
