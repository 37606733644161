
import { mapGetters } from 'vuex';
import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';
import whatsappFn from '~/mixins/whatsappFn.js';
import lgmMixin from '~/mixins/lgmMixin';

export default {
  name: 'DecoderCTAModal',
  mixins: [eventTrackingMixins, whatsappFn, lgmMixin],
  computed: {
    ...mapGetters({
      decoderCTAModalData: 'modal/decoderCTAModalData',
      showDecoderCTAModal: 'modal/showDecoderCTAModal',
    })
  },
  watch: {
    showDecoderCTAModal: {
      handler (val) {
        if (!val) {
          this.handleEventTracking('close_cta_popup');
        }
      }
    },
  },
  
  mounted() {
  },

  methods: {
    
    async interruptWhatsappLink(event) {

      const btnLink = event.currentTarget.href; 
      try {
        if(this.isWhatsappLink(btnLink) && (this.hasAdsInCookies() || this.hasLgmadsInCookies())) {
          const refData = await this.fetchWhatsappRefCode()
          window.open(this.adjustWhatsappLink(btnLink, refData), this.$device.isMobile ? '_self' : '_blank'); 
          return;
        }
        if(this.isWhatsappLink(btnLink)) {
          window.open(btnLink, '_blank');
          return;
        }
      } catch (error) {
        console.error('error: ', error)
        this.$sentry.captureException(error);
      }

      // window.open(btnLink, '_blank');
    },

    handleEventTracking(eventAction) {
      event.preventDefault();
      if(eventAction === 'open_whatsapp') {

        const eventTrackingParams = this.initEventTrackingParams()

        if (this.$route.path.includes('insurer')) {
          // #220 insurer_decoder_cta_popup
          eventTrackingParams.a = 'open_whatsapp'
          eventTrackingParams.l =  this.decoderCTAModalData?.trackingData?.scenarioCode
          eventTrackingParams.c = "insurer_decoder_cta_popup";
          eventTrackingParams.cd.insurer_name = this.decoderCTAModalData?.trackingData?.insurerName;
          eventTrackingParams.cd.product_name = this.decoderCTAModalData?.trackingData?.productName;
          eventTrackingParams.cd.product_category = this.decoderCTAModalData?.trackingData?.category;
        } else {
          // #185 open_whatsapp decoder_cta_popup
          eventTrackingParams.a = 'open_whatsapp'
          eventTrackingParams.l =  this.decoderCTAModalData?.trackingData?.scenarioCode
          eventTrackingParams.c = "decoder_cta_popup";
          eventTrackingParams.cd.insurer_name = this.decoderCTAModalData?.trackingData?.insurerName;
          eventTrackingParams.cd.product_name = this.decoderCTAModalData?.trackingData?.productName;
          eventTrackingParams.cd.product_category = this.decoderCTAModalData?.trackingData?.category;
        }
        this.sendEventTracking(eventTrackingParams)
      } 
      if(eventAction === 'to_ecom_site') {
        const eventTrackingParams = this.initEventTrackingParams()
        
        if (this.$route.path.includes('insurer')) {
          // #221 insurer_decoder_cta_popup
          eventTrackingParams.a = 'to_ecom_site'
          eventTrackingParams.l =  this.decoderCTAModalData?.trackingData?.scenarioCode
          eventTrackingParams.c = 'insurer_decoder_cta_popup';
          eventTrackingParams.cd.insurer_name = this.decoderCTAModalData?.trackingData?.insurerName;
          eventTrackingParams.cd.product_name = this.decoderCTAModalData?.trackingData?.productName;
          eventTrackingParams.cd.product_category = this.decoderCTAModalData?.trackingData?.category;          
        } else {
          // #186 to_ecom_site decoder_cta_popup
          console.debug('#186');
          eventTrackingParams.a = 'to_ecom_site'
          eventTrackingParams.l =  this.decoderCTAModalData?.trackingData?.scenarioCode
          eventTrackingParams.c = 'decoder_cta_popup';
          eventTrackingParams.cd.insurer_name = this.decoderCTAModalData?.trackingData?.insurerName;
          eventTrackingParams.cd.product_name = this.decoderCTAModalData?.trackingData?.productName;
          eventTrackingParams.cd.product_category = this.decoderCTAModalData?.trackingData?.category;

          this.openWindowWithLGMUrl(event, '.selectable-box', eventTrackingParams, 'eCommerceSite');

        }
      
        this.sendEventTracking(eventTrackingParams)
      } 
      if(eventAction === 'close_cta_popup') {
        // #187 close_cta_popup decoder_cta_popup
        const eventTrackingParams = this.initEventTrackingParams()
        
        if (this.$route.path.includes('insurer')) {
          // #222 close_cta_popup insurer_decoder_cta_popup
          eventTrackingParams.a = 'close_cta_popup'
          eventTrackingParams.l =  this.decoderCTAModalData.trackingData.scenarioCode
          eventTrackingParams.c = `insurer_decoder_cta_popup`
          eventTrackingParams.cd.insurer_name = this.decoderCTAModalData.trackingData.insurerName;
          eventTrackingParams.cd.product_name = this.decoderCTAModalData.trackingData.productName;
          eventTrackingParams.cd.product_category = this.decoderCTAModalData.trackingData.category;
        } else {
          eventTrackingParams.a = 'close_cta_popup'
          eventTrackingParams.l =  this.decoderCTAModalData.trackingData.scenarioCode
          eventTrackingParams.c = `decoder_cta_popup`
          eventTrackingParams.cd.insurer_name = this.decoderCTAModalData.trackingData.insurerName;
          eventTrackingParams.cd.product_name = this.decoderCTAModalData.trackingData.productName;
          eventTrackingParams.cd.product_category = this.decoderCTAModalData.trackingData.category;
        }
      
        this.sendEventTracking(eventTrackingParams)
      } 

      this.interruptWhatsappLink(event);
    },
  }
};
