
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'DrifterZoneVideoModal',
  
    props: {
    },
    
    computed: {
      ...mapGetters({
        showDrifterZoneVideoModal: 'modal/showDrifterZoneVideoModal',
        getDrifterZoneVideoModalLink: 'modal/getDrifterZoneVideoModalLink',
      }),
    },
  
    methods: {
      confirm() {
        this.$store.commit('modal/setShowDrifterZoneVideoModal', false);
      },
      
      closeModal() {
        this.loginBox = false;
        this.$nextTick(() => {
          this.$emit('close-modal');
          this.$store.commit('modal/setDrifterZoneVideoModalLink', '');
        });
      },
    },
    
  };
  