export const state = () => ({
  blogs: [],
  pendingProducts: "",
  newProducts: [],
})

export const mutations = {
  setMyfavBlogs(state, data) {
    const index = state.blogs.indexOf(data);
    if (index !== -1) {
      state.blogs.splice(index, 1);
    } else {
      state.blogs.push(data)
    }
  },

  setMyfavPendingProducts(state, data) {
    state.pendingProducts = data;
  },

  setMyfavNewProducts(state, data) {
    const index = state.newProducts.find(({scenarioId, productType, favProductId}) => scenarioId === data.scenarioId && productType === data.productType && favProductId === data.favProductId);
    if (index) {
      state.newProducts = state.newProducts.filter(({scenarioId, productType, favProductId}) => scenarioId !== data.scenarioId || productType !== data.productType || favProductId !== data.favProductId);
    } else {
      state.newProducts.push(data)
    }
  },

  removeAllMyFavBlogs(state) {
    // console.log("remove pending fav blog")
    state.blogs = [];
  },

  removeAllMyFavPendingProducts(state) {
    // console.log("remove pending fav product")
    state.pendingProducts = "";
  },

  removeAllMyFavNewProducts(state) {
    // console.log("remove pending fav product")
    state.newProducts = [];
  },
}

export const getters = {
  myfavBlogs: (state) => {
    return state?.blogs;
  },
  myfavPendingProducts: (state) => {
    return state?.pendingProducts;
  },
  myfavNewProducts: (state) => {
    return state?.newProducts;
  },
}
