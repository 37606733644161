function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export default function ({ route, redirect }) {
  if (process.server) {
    const invalidPaths = [
      '/zh-hk&utm_content=compare-get-insured&utm_medium=MTR-POW-P1&utm_source=MTR&utm_campaign=20221116_MTR-OOH_IN_Branding_homepage_awareness_2022-Branding-Campaign',
      '/zh-hk&utm_content=compare-get-insured&utm_medium=CWB-Bus-Shelter-C017&utm_source=KMB-Shelter&utm_campaign=20221125_KMB-shelter-OOH_IN_Branding_homepage_awareness_2022-Branding-Campaign',
      '/zh-hk&utm_content=compare-get-insured&utm_medium=12sheet&utm_source=MTR&utm_campaign=20221112_MTR-OOH_IN_Branding_homepage_awareness_2022-Branding-Campaign',
      '/zh-hk&utm_content=compare-get-insured&utm_medium=Broadview-Banner&utm_source=MTR&utm_campaign=20221117_MTR-OOH_IN_Branding_homepage_awareness_2022-Branding-Campaign',
      '/zh-hk&utm_content=compare-get-insured&utm_medium=4sheet&utm_source=MTR&utm_campaign=20221119_MTR-OOH_IN_Branding_homepage_awareness_2022-Branding-Campaign',
      '/zh-hk&utm_content=compare-get-insured&utm_medium=Seatback-Stickers-Plus&utm_source=KMB-Bus&utm_campaign=20221112_KMB-Bus-OOH_IN_Branding_homepage_awareness_2022-Branding-Campaign',
      '/zh-HK&utm_content=compare-over-1000-products&utm_medium=foamboard&utm_source=Event&utm_campaign=20230530_Event_Foamboard-OOH_IN_Branding_homepage_awareness_Event'
    ];

    const adjustInvalidPaths = invalidPaths.map(path => replaceAll(path, '&', '%26'));
    const foundPath = adjustInvalidPaths.find(path => route.path === path);

    if (foundPath) {
      const normalizePath = replaceAll(foundPath, '%26', '&').replace('&', '?');
      return redirect(normalizePath);
    }
  }
}