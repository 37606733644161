
export default {
  name: 'ReusableError',

  data() {
    return {
      gotoUrl: this.$t('error_page.goto_url'),
    };
  }
};
