
import globalEventListenersMixin from '~/mixins/globalEventListeners.js';
import fetchNavigationData from '~/mixins/fetchNavigationData.js';
import defaultHead from '~/mixins/defaultHead.js';
import globalCookies from '~/mixins/globalCookies.js';
import popupBannerMixin from '~/mixins/popupBannerMixins.js';

export default {
  name: 'BlankLayout',
  mixins: [globalEventListenersMixin, fetchNavigationData, defaultHead, globalCookies, popupBannerMixin],
};
