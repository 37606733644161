import { render, staticRenderFns } from "./NavigationContentBroker.vue?vue&type=template&id=a38b6a88&scoped=true"
import script from "./NavigationContentBroker.vue?vue&type=script&lang=js"
export * from "./NavigationContentBroker.vue?vue&type=script&lang=js"
import style0 from "./NavigationContentBroker.vue?vue&type=style&index=0&id=a38b6a88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a38b6a88",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationContentIntro: require('/app/components/navigation-bar/navigation-content/sections/NavigationContentIntro.vue').default,NavigationContentLinks: require('/app/components/navigation-bar/navigation-content/sections/NavigationContentLinks.vue').default,NavigationContentCardVisual: require('/app/components/navigation-bar/navigation-content/sections/NavigationContentCardVisual.vue').default})
