
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    mobileOnly: {
      type: Boolean,
      default: false,
    },
  },
};
