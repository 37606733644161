import { render, staticRenderFns } from "./NavigationContentContainer.vue?vue&type=template&id=2adf575e&scoped=true"
import script from "./NavigationContentContainer.vue?vue&type=script&lang=js"
export * from "./NavigationContentContainer.vue?vue&type=script&lang=js"
import style0 from "./NavigationContentContainer.vue?vue&type=style&index=0&id=2adf575e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2adf575e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationContentDecoder: require('/app/components/navigation-bar/navigation-content/modules/NavigationContentDecoder.vue').default,NavigationContentBroker: require('/app/components/navigation-bar/navigation-content/modules/NavigationContentBroker.vue').default,NavigationContentLifestage: require('/app/components/navigation-bar/navigation-content/modules/NavigationContentLifestage.vue').default,NavigationContentAboutUs: require('/app/components/navigation-bar/navigation-content/modules/NavigationContentAboutUs.vue').default})
