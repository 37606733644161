
import { mapGetters } from 'vuex';

export default {
  props: {
    useWhiteStyle: {
      type: Boolean,
      required: true,
    },
    useDefaultStyle: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      isProfileHovered: false,
      isFavHovered: false,
      isSearchHovered: false,
      isWalletHovered: false,
      locale: this.$i18n.locale
    };
  },

  computed: {
    walletLink() {
      return process.env.SALES_EXTERNAL_URL + '/' + (this.$i18n.locale === 'en' ? 'en' : 'tc') + '/account/wallet/policies'
    },

    isAuthenticated () {
      return this.$store.state.auth.loggedIn;
    },
    landingPath() {
      return this.locale == 'zh-CN'? this.localePath('/zh-CN') : this.locale == 'zh-HK' ? this.localePath('/zh-HK') : this.localePath('/');
    }
  },

  methods: {
    toggleProfileMenuModal() {
      this.$store.commit('modal/setShowProfileMenuModal', true);
    },

    openLoginModal() {
      this.$auth.loginWith('tenlife');
    },

    goFavourite() {
      this.$router.push({ path: this.localePath('/my-favourite') });
    },
  },
};
