
import processImageLink from '../../mixins/processImageLink.js'

export default {
  name: 'HomepageMilestone',
  props: {
    data: {
      type: Object,
      default: () => ({
        Title: undefined,
        MilestoneFigure: [],
        Subtitle: undefined,
        RewardLogo: []
      }),
    },
  },
  mixins: [processImageLink],
}
