import globalCookies from "./globalCookies";
import middlePlatformTrackingMixin from "./middlePlatformTrackingMixin";
export default {
  mixins: [globalCookies,middlePlatformTrackingMixin],
  mounted() {
    //
  },
  methods: {
    async getLGMConversionId(params) {
      return await this.getLGMTrackingHash(params);
    },

    injectionForAXA(params) {
      const link = params.link;
      const url = params.url;
      const cid = params.cid;
      const queryStringKey = params.queryStringKey;
      if (link) {
        const linkUrl = new URL(decodeURIComponent(link));
        linkUrl.searchParams.set(queryStringKey, `10lifecid_${cid}`);
        url.searchParams.set("link", (linkUrl.toString()));
      }
      return url;

    },
    injectionForSUN(params) {
      const link = params.link;
      const url = params.url;
      const cid = params.cid;
      const queryStringKey = params.queryStringKey;
      if (link) {
        const linkUrl = new URL(decodeURIComponent(link));
        const mcId = linkUrl.searchParams.get("WT.mc_id");
        const sunlifeCid = linkUrl.searchParams.get("cid"); 
        if (mcId) {
          const mcIdSections = mcId.split(":");
          const fifthSection = mcIdSections[4];
          const cidAppended = `${fifthSection}-${queryStringKey}-${cid}`;
          mcIdSections[4] = cidAppended;
          const newMcId = mcIdSections.join(":");
          linkUrl.searchParams.set("WT.mc_id", newMcId);
          const linkUrlDecoded = decodeURIComponent(linkUrl)
          url.searchParams.set("link", (linkUrlDecoded.toString()));
        } else if (sunlifeCid) {
          const sunlifeCidSections = sunlifeCid.split(":");
          const fifthSection = sunlifeCidSections[4];
          const cidAppended = `${fifthSection}-${queryStringKey}-${cid}`;
          sunlifeCidSections[4] = cidAppended;
          const newSunlifeCid = sunlifeCidSections.join(":");
          linkUrl.searchParams.set("cid", newSunlifeCid);
          const linkUrlDecoded = decodeURIComponent(linkUrl)
          url.searchParams.set("link", (linkUrlDecoded.toString()));
        } else {
          linkUrl.searchParams.set(queryStringKey, cid);
          const linkUrlDecoded = decodeURIComponent(linkUrl)
          url.searchParams.set("link", (linkUrlDecoded.toString()));
        }
      }
      return url;
    },
    injectionForDefault(params) {
      const link = params.link;
      const url = params.url;
      const cid = params.cid;
      const queryStringKey = params.queryStringKey;
      if (link) {
        const linkUrl = new URL(decodeURIComponent(link));
        linkUrl.searchParams.set(queryStringKey, cid);
        url.searchParams.set("link", (linkUrl.toString()));
      }
      return url;
    },
    injectLGMConversionId(transitionCheckoutLink, conversionId) {
      if (transitionCheckoutLink.includes('transition-checkout')) {

        const url = new URL(transitionCheckoutLink);
        const cid = conversionId;
        let QUERY_STRING_10LIFECID = '10lifecid';
        const link = url.searchParams.get("link");
        const params = {
          link: link,
          url: url,
          cid: cid,
          queryStringKey: QUERY_STRING_10LIFECID,
        }
        
        try {
          if (transitionCheckoutLink.includes('insurer=SUN')) {
            return this.injectionForSUN(params);
          } else if (transitionCheckoutLink.includes('insurer=AXA')) {
            params.queryStringKey = 'utm_content';
            params.queryStringValue = `10lifecid_${cid}`;
            return this.injectionForAXA(params);
          } 
          return this.injectionForDefault(params);
  
        } catch (error) {
          console.error('Error:', error);
          return transitionCheckoutLink;
        }
        
      } else {
        return transitionCheckoutLink;
      } 
    },

    injectLGMConversionIdToEComSite(eComSiteLink, conversionId) {
      if (eComSiteLink.includes('/purchase')) {

        const url = new URL(eComSiteLink);
        const cid = conversionId;
        const QUERY_STRING_10LIFECID = '10lifecid';
        const QUERY_STRING_10LIFECID_VALUE = `${cid}`;

        url.searchParams.set(QUERY_STRING_10LIFECID, QUERY_STRING_10LIFECID_VALUE);
        return url;
      } else {
        return eComSiteLink;
      } 
    },

    /**
     * 
     * @param {*} clickedEvent 
     * @param {*} aTagClassSelectorString 
     * @param {*} eventTrackingParams 
     * @param {*} destinationType  - transitionLink or eCommerceSite
     * @returns url
     */
    async getLGMTrackingUrl(clickedEvent, aTagClassSelectorString='a', eventTrackingParams, destinationType = 'transitionLink') {
      const injectionFn = {
        "transitionLink": this.injectLGMConversionId,
        "eCommerceSite": this.injectLGMConversionIdToEComSite
      }
      const currentUrl = window.location.href;
      let url;
      try {
        url = clickedEvent?.target?.closest(aTagClassSelectorString).href;
        const lgmParams = {
          "epg": currentUrl,
          "dpg": url,
          "lgmMeta": eventTrackingParams.c ? eventTrackingParams.c : undefined,
          "xr_id": eventTrackingParams.xr_id ? eventTrackingParams.xr_id : undefined,
          "ir": eventTrackingParams.cd.insurer_name ? eventTrackingParams.cd.insurer_name : undefined,
        }
        const cid = await this.getLGMConversionId(lgmParams);
        url = injectionFn[destinationType](url, cid);
      } catch (error) {
        console.error('Error:', error);
        this.$sentry.captureException(error);
      }
      return url;
    },

    openWindowWithLGMUrl(clickedEvent, aTagClassSelectorString='a', eventTrackingParams, destinationType = 'transitionLink') {
      const windowReference = window.open();
      this.getLGMTrackingUrl(clickedEvent, aTagClassSelectorString, eventTrackingParams, destinationType).then((url) => {
        windowReference.location = url;
      });
    }
  },
}