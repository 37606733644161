// import Try from '~/helpers/tryCatch.js';

export default ($axios) => {
  const advisorBackendUrl = process.env.ADVISOR_BACKEND_URL;
  return {
    getAdvisorByRefCode: async (refCode, blogSlug) => {
      return await $axios.get(advisorBackendUrl + '/api/ad-campaigns?filters[ref_code][$eq]='+refCode+'&filters[status][$eq]=active&filters[blog_slug]='+blogSlug+'&populate[0]=advisor&populate[1]=advisor.profile_picture&populate[2]=advisor.advisor_translation&populate[3]=advisor.insurance_companies&populate[4]=advisor.insurance_companies.logo&populate[5]=advisor.employed_companies&populate[6]=advisor.employed_companies.employed_company_translations&populate[7]=advisor.insurance_companies.insurance_company_translations')
    },

    getAdvisorTranslation: async (translationId) => {
      return await $axios.get(advisorBackendUrl + '/api/advisor-translations/'+translationId+'?populate[0]=localizations');
    }
  }
}