import Vue from 'vue'
import FloatingVue from 'floating-vue'
// import { Menu } from 'floating-vue'
import 'floating-vue/dist/style.css'

Vue.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
    },
    'info-menu': {
      $extend: 'menu',
      $resetCss: true,
    },
  },
})

// Vue.component('VMenu', Menu)