

export default {
  name: 'WechatModal',

  props: {
  },


  methods: {
    confirm() {
      this.$store.commit('modal/setShowWechatModal', false);
    },
    
    closeModal() {
      this.loginBox = false;
      this.$nextTick(() => {
        this.$emit('close-modal');
      });
    },
  },
};
