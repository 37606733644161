
import vClickOutside from 'v-click-outside';
import { mapGetters } from 'vuex';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    useWhiteStyle: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isMenuOn: false,

      isProfileHovered: false,
      isFavHovered: false,
      isSearchHovered: false,
      isWalletHovered: false,
      isLocaleHovered: false,
    };
  },

  computed: {
    currentLocaleCode() {
      return this.$i18n.locale;
    },
    availableLocale() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale).shift();
    },
    walletLink() {
      return process.env.SALES_EXTERNAL_URL + '/' + (this.currentLocaleCode === 'en' ? 'en' : 'tc') + '/account/wallet/policies'
    },
    isAuthenticated () {
      return this.$store.state.auth.loggedIn;
    },
  },

  methods: {
    toggleMenu() {
      this.isMenuOn = !this.isMenuOn;
    },

    toggleMenuOff() {
      this.isMenuOn = false;
    },

    openLoginModal() {
      this.$store.commit('modal/setLoginModalEntryPoint', 'header_icon');
      this.$auth.loginWith('tenlife');
    },

    goFavourite() {
      if(this.isAuthenticated)
        this.$router.push({ path: this.localePath('/my-favourite') });
      else
        this.$auth.loginWith('tenlife');
    },
  },
};
