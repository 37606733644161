
import processImageLink from '../../mixins/processImageLink.js'

export default {
  name: 'HomepageOffers',
  props: {
    data: {
      type: Object,
      default: () => ({
        Title: undefined,
        LifeStageOffering: [],
      }),
    },
  },
  mixins: [processImageLink],
  data() {
    return {
      activeItem: "lifestageoffering-0",
      activeItemMobile: "lifestageoffering-0",

      strapiData: []
    }
  },
  computed: {
    offeringData() {
      return this.strapiData.find((item) => this.offeringId === item.id )
    }
  },
  methods: {
    handleCategoryClicked(itemId) {
      this.changeCategory(itemId)
      this.changeCategoryMobile(itemId)
    },
    changeCategory(activeItemId) {
      this.activeItem = activeItemId
    },
    changeCategoryMobile(activeItemId) {
      this.activeItemMobile = (this.activeItemMobile === activeItemId) ? '' : activeItemId 
    }
  },
}
