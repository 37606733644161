
import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';

export default {
  mixins: [eventTrackingMixins],

  name: "VideoCard",

  props: {
    data: {
      type: Object,
      default: () => ({
      }),
    },
    videoLink: {
      type: String,
      default: '',
    },
    videoLinkSource: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    imgLink: {
      type: String,
      default: '',
    }
  },

  data() {
      return {
        isActive: true,
      }
  },

  methods: {
    openVideoAndEventTracking() {
      this.openVideo(this.videoLink);
    },
    openVideo(videoLink) {
      console.debug('openVideo()...', videoLink)
      this.$store.commit('modal/setVideoModalLink', videoLink);
      this.$store.commit('modal/setShowVideoModal', true);
    },
  },
}
