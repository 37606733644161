
export default {
  name: 'TutorialButton',

  props: {
    data: {
      type: Object,
      default: () => ({
        icon: 'arrow',
        textColor: 'black',
        buttonColor: 'red',
        buttonBorderColor: 'black',
      }),
    },
    text: {
      type: String,
      required: false,
      default: '[This is the Text]',
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      icon: this.data.icon || 'arrow',
      buttonColor: this.data.buttonColor,
      buttonBorderColor: this.data.buttonBorderColor,
    };
  },
};
