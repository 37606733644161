
export default {
  props: {
    href: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    isTargetSelf() {

      if (this.href && this.href.includes("transition")) return false;
      if (this.target === '_self') return true;
      if (!this.target && this.href && Array.from(this.href)[0] === '/')
        return true;
      return false;
    },

    isHrefEmpty() {
      return !this.href;
    },

    isTargetSelfAnchor() {
      if (this.target === 'anchor_self') {
        return true;
      }
      return false;
    }
  },

  methods: {
    enforceQuitNavigation() {
      this.$nuxt.$emit('leaveNavigationContent');
    },
  },
};
