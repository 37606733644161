
import { mapGetters } from 'vuex';

export default {
  name: 'VideoModal',

  props: {
  },
  
  computed: {
    ...mapGetters({
      showVideoModal: 'modal/showVideoModal',
      getVideoModalLink: 'modal/getVideoModalLink',
      getVideoModalLinkSource: 'modal/getVideoModalLinkSource',
    }),
  },

  methods: {
    confirm() {
      this.$store.commit('modal/setShowVideoModal', false);
    },
    
    closeModal() {
      this.loginBox = false;
      this.$nextTick(() => {
        this.$emit('close-modal');
        this.$store.commit('modal/setVideoModalLink', '');
        this.$store.commit('modal/setVideoModalLinkSource', '');
      });
    },
  },
  
};
