
// const zhCnWhitelist = [ 
//   { from: '/', regex_url: /^.*\/zh-CN[\/]?[\/#\?]?$/i , regex_param: /.*/i},
//   { from: '/404', regex_url: /^.*\/zh-CN\/404.*[\/#\?]?$/i , regex_param: /.*/i},
//   { from: '/cookies', regex_url: /^.*\/zh-CN\/cookies.*[\/#\?]?$/i , regex_param: /.*/i},
//   { from: '/terms', regex_url: /^.*\/zh-CN\/terms.*[\/#\?]?$/i , regex_param: /.*/i},
//   { from: '/privacy', regex_url: /^.*\/zh-CN\/privacy.*[\/#\?]?$/i , regex_param: /.*/i},
//   { from: '/blog', regex_url: /^.*\/zh-CN\/blog.*[\/#\?]?$/i , regex_param: /.*/i},
//   { from: '/hong-kong-insurance', regex_url: /^.*\/zh-CN\/hong-kong-insurance.*[\/#\?]?$/i , regex_param: /.*/i},
//   { from: '/5star-products/2024', regex_url: /^.*\/zh-CN\/5star-products\/2024.*[\/#\?]?$/i , regex_param: /.*/i},
// ];
// const zhCnOnlylist = [ 
//   { from: '/hong-kong-insurance', regex_url: /^.*\/.*\/hong-kong-insurance.*[\/#\?]?$/i , regex_param: /.*/i},
// ];
const zhCn301list = [ 
  // { from: '/', regex_url: /^.*\/zh-CN[\/]?[\/#\?]?$/i , regex_param: /.*/i, to: '/hong-kong-insurance'},
];

function isZhCn(url) {
  const regex = new RegExp('^.*\/zh-CN.*[\/#\?]?$', 'i');
  const boo = regex.test(url);
  return boo;
}
// function isZhCnWhitelistUrl(url) {
//   const matched = zhCnWhitelist.find(
//     (route) => {
//       return route.regex_url.exec(url);
//     }
//   );
//   return !!matched;
// }
// function isZhCnOnlylistUrl(url) {
//   const matched = zhCnOnlylist.find(
//     (route) => {
//       return route.regex_url.exec(url);
//     }
//   );
//   return !!matched;
// }
function isZhCn301listUrl(url) {
  const matched = zhCn301list.find(
    (route) => {
      return route.regex_url.exec(url);
    }
  );
  return !!matched;
}

export default function ({ context,route, redirect }) {
  // if (!isZhCn(route.fullPath) && isZhCnOnlylistUrl(route.fullPath)) {
  //   redirect( '/zh-HK/404');
  // }
  // if (isZhCn(route.fullPath) && !isZhCnWhitelistUrl(route.fullPath)) {
  //   redirect( '/zh-CN/404');
  // }

  if (isZhCn(route.fullPath) && isZhCn301listUrl(route.fullPath)) {
    const matched = zhCn301list.find(
      (item) => {
        const r = item.regex_url.exec(route.fullPath);
        return r
      }
    );
    redirect( matched.to);
  }
}