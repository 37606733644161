export const state = () => (
  {
      selected: [],
      page: '',
      form: false,
      result: false,
      user: { name: '', gender: '', cost: '0', score: '0'}
  }
)

export const mutations = {
  resetData(state) {
    state.selected = [];
    state.page = '';
    state.form = false;
    state.result = false;
    state.user = { name: '', gender: '', cost: '0', score: '0'}
  },
  updateSelected(state, data) {
    const isExistItem = state.selected.map(q => q.id).includes(data.id);
    const existItem = state.selected.find(p => p.id === data.id);

    if (isExistItem) {
      Object.assign(existItem, data);
    } else {
      state.selected.push(data);
    }
  },
  updatePage(state, value) {
    state.page = value;
  },
  updateForm(state, value) {
    state.form = value;
  },
  updateResult(state, value) {
    state.result = value;
  },
  updateUser(state, data ) {
    state.user = { name: data.name, gender: data.gender, cost: data.cost, score: data.score };
  }
}

export const getters = {
  getSelected: (state) => {
    return state.selected;
  },
  getPage: (state) => {
    return state.page;
  },
  getForm: (state) => {
    return state.form;
  },
  getResult: (state) => {
    return state.result;
  },
  getUser: (state) => {
    return state.user;
  },
}
