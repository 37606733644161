// import Try from '~/helpers/tryCatch.js';

export default ($axios) => {
  const strapiBaseUrl = process.env.STRAPI_BASE_URL + '/api';

  return {
    postNewsletterSubscriber: async (data) => {
      /*
      const env = process.env.NODE_ENV || 'development';
      const siteID = process.env.CUSTOMERIO_SITE_ID || '';
      const apiKey = process.env.CUSTOMERIO_API_KEY || '';
      const url = `https://track.customer.io/api/v1/customers/${currentEmail}`;
      const unixTimestamp = Math.round(Date.now() / 1000);

      const request =
      {
        "email": currentEmail,
        "plan": "basic",
        "created_at": unixTimestamp,
        "isFromProduction": env === 'production' ? '1' : '0'
      };
      const encodedStr = Buffer.from(`${siteID}:${apiKey}`).toString('base64');
      const headers = {
        headers: {
          'Authorization': `Basic ${encodedStr}`,
          'Content-Type': 'application/json'
        }
      };

      console.debug('hi ** postNewsletterSubscriber **')

      console.debug(siteID)
      console.debug(apiKey)
      console.debug(env)
      console.debug(strapiBaseUrl)

      const postParams = {
        data: {
          email: currentEmail,
        },
      };

      const [err, res] = await Try($axios.post(strapiBaseUrl + '/newsletter-subscribers', postParams))
      if (err) {
        return [err, res]
      }

      return Try(await $axios.put(url, request, headers));
      */

      return await $axios.post(strapiBaseUrl + '/newsletter-subscribers', data)
    },
  }
}