
import processImageLink from '../../mixins/processImageLink.js'

export default {
  name: 'MediaRecognition',

  props: {
    data: {
      type: Object,
      default: () => ({
        MediaRecognition: [],
        MediaRecognitionBackground: undefined,
      }),
    },
  },
  mixins: [processImageLink],
  data() {
    return {
      activePrimaryContainer: false,
      circleAnimationToggle: [],
      intervals: [],
      timeouts: [],
    };
  },

  computed: {
    backgroundImage() {
      return {
        backgroundImage: `url(${this.data.MediaRecognitionBackground?.url})`,
      };
    },

    mediaData() {
      return this.data.MediaRecognition;
    },

    currentPrimaryData() {
      return {
        data: this._.isEmpty(this.mediaData) ? undefined : this.mediaData[0],
        indexAt: 0,
      };
    },
  },

  created() {
    this.displayTimeInterval = 8000;
    this.fadingBufferInterval = 500;
  },

  mounted() {
    this.initializeCircleAnimationToggle();
    this.startAnimation(0);
    this.addVisibilityEventHandler();
  },

  destroyed() {
    this.removeVisibilityEventHandler();
  },

  methods: {
    rotateMediaLogo(animationToggleStartingIndex) {
      const self = this;
      const interval = this.displayTimeInterval;

      function toggleCircleAnimation(index) {
        self.$set(
          self.circleAnimationToggle,
          index,
          !self.circleAnimationToggle[index]
        );
      }

      function toggleCircleAnimationWithinInterval(
        index,
        animationToggleIndex,
        interval
      ) {
        const startTimeout = setTimeout(() => {
          toggleCircleAnimation(animationToggleIndex);
        }, interval * index);

        const endTimeout = setTimeout(() => {
          toggleCircleAnimation(animationToggleIndex);
        }, interval + interval * index);

        self.timeouts.push(startTimeout, endTimeout);
      }

      function toggleEveryCircleAnimationWithinInterval(
        interval,
        animationToggleStartingIndex
      ) {
        let animationToggleIndex = animationToggleStartingIndex;
        for (let i = 0; i < self.circleAnimationToggle.length; i++) {
          toggleCircleAnimationWithinInterval(
            i,
            animationToggleIndex,
            interval
          );
          if (animationToggleIndex >= self.circleAnimationToggle.length - 1)
            animationToggleIndex = 0;
          else animationToggleIndex++;
        }
      }

      toggleEveryCircleAnimationWithinInterval(
        interval,
        animationToggleStartingIndex
      );
      const startInterval = setInterval(() => {
        toggleEveryCircleAnimationWithinInterval(
          interval,
          animationToggleStartingIndex
        );
      }, interval * self.circleAnimationToggle.length);

      self.intervals.push(startInterval);
    },

    rotatePrimaryContent(startingIndex) {
      const self = this;
      const interval = this.displayTimeInterval;
      const bufferInterval = this.fadingBufferInterval;

      function rotateText() {
        if (self.currentPrimaryData.indexAt >= self.mediaData.length - 1)
          self.currentPrimaryData.indexAt = 0;
        else self.currentPrimaryData.indexAt++;

        self.currentPrimaryData.data =
          self.mediaData[self.currentPrimaryData.indexAt];
      }

      function togglePrimaryContainer() {
        self.activePrimaryContainer = !self.activePrimaryContainer;
      }

      function togglePrimaryContainerWithinInterval() {
        togglePrimaryContainer();

        const endTimeout = setTimeout(() => {
          togglePrimaryContainer();
        }, interval - bufferInterval);

        self.timeouts.push(endTimeout);
      }

      self.currentPrimaryData.data = self.mediaData[startingIndex];
      togglePrimaryContainerWithinInterval();
      const startInterval = setInterval(() => {
        rotateText();
        togglePrimaryContainerWithinInterval();
      }, interval);

      self.intervals.push(startInterval);
    },

    initializeCircleAnimationToggle() {
      this.circleAnimationToggle = new Array(this.mediaData.length).fill(false);
    },

    startAnimation(startingIndex = 0) {
      this.activePrimaryContainer = false;
      this.rotateMediaLogo(startingIndex);
      this.rotatePrimaryContent(startingIndex);
    },

    clearTimeFunctions() {
      this.timeouts.forEach(clearTimeout);
      this.intervals.forEach(clearInterval);
      this.timeouts = [];
      this.intervals = [];
    },

    stopAnimation() {
      this.clearTimeFunctions();
      this.activePrimaryContainer = false;
      this.initializeCircleAnimationToggle();
    },

    animationHandler() {
      if (document.hidden) {
        this.stopAnimation();
      } else {
        setTimeout(() => {
          this.initializeCircleAnimationToggle();
          this.startAnimation(this.currentPrimaryData.indexAt);
        }, 1);
      }
    },

    addVisibilityEventHandler() {
      document.addEventListener('visibilitychange', this.animationHandler);
    },

    removeVisibilityEventHandler() {
      document.removeEventListener('visibilitychange', this.animationHandler);
    },

    jumpToAnimation(index) {
      this.stopAnimation();

      setTimeout(() => {
        this.currentPrimaryData.indexAt = index;
        this.startAnimation(index);
      }, this.fadingBufferInterval);
    },
  },
};
