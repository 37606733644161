import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { ApolloLink, createHttpLink } from '@apollo/client/core';
import schema from './schema.json';
import { setContext } from '@apollo/client/link/context';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: schema
});

const promiseToObservable = (promise) => {
  const observable = new Observable((subscriber) => {
    promise.then(
      value => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err)
    ); 
    return subscriber;
  });
  return observable;
};

export default (context) => {

  let httpEndpoint = process.env.STRAPI_BASE_URL + '/graphql';
  if(process.server){
    httpEndpoint = process.env.STRAPI_INTERNAL_URL + '/graphql';
  }
  console.log("debug graphql", httpEndpoint);
  // A little aggressive for Infinity?
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error,
    },
  });

  const authLink = setContext((_, { headers }) => {
    if (!process.server) {
      // token name from $auth config
      const backendTokenName = context.app.$auth.strategies.tenlife.options.backendToken.property;
      const token = context.app.$auth.$storage.getUniversal(backendTokenName);
      if(token){
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          }
        }  
      }
    }
    return { headers };
  });
  
  const refreshToken = () => {
    return new Promise(async (resolve, reject) => {
      const value = {};
      try {
        await context.app.$auth.refreshTokens();
        // Get your new token from localStorage
        const backendTokenName = context.app.$auth.strategies.tenlife.options.backendToken.property;
        const token = context.app.$auth.$storage.getUniversal(backendTokenName);
        value.token = token;
        resolve(value);
      } catch (error) {
        // if refrehToken is not successful, logout and return empty object 
        context.app.$auth.reset();
        if (error.response && error.response.status === 401) {
          console.log("refreshTokens() failed with 401");
        } else {
          console.log("refresh token exception");
        }
        resolve(value);
      }
    });
  }
  

  // https://github.com/apollographql/apollo-link/issues/646
  const errorLink = onError(({ forward, operation, graphQLErrors, networkError }) => {
    if (graphQLErrors){
      console.log(graphQLErrors);
      graphQLErrors.forEach(({ message, locations, path }) =>{
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
      );
    }
  
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if ('statusCode' in networkError && networkError.statusCode === 401) {
        console.log('Unauthorized! The server responded with a 401 error');
         return promiseToObservable(refreshToken()).pipe(
            mergeMap(
            (value) => {
            let oldHeaders = operation.getContext().headers;
            // Convert all header keys to lowercase
            oldHeaders = Object.keys(oldHeaders).reduce((result, key) => {
              result[key.toLowerCase()] = oldHeaders[key];
              return result;
            }, {});
            // Remove the old authorization header
            delete oldHeaders['authorization'];
            // if no token, refreshToken is not successful, silently remove the Authorization header and continue
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...oldHeaders,
                ...(value.hasOwnProperty('token') ? { authorization: `Bearer ${value.token}` } : {})
              }
            }));
            return forward(operation);
          }));
      } else {
        console.log("debug enter error not 401");
          return forward(operation);  
      }
    }
    console.log("debug enter no error return");
    return forward(operation);

  });

  const httpLink = createHttpLink({
    uri: httpEndpoint,
  })

  return {  
    link: ApolloLink.from([authLink, errorLink, retryLink, httpLink]),
    // link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({ fragmentMatcher }),
    defaultHttpLink: false,
  };
}