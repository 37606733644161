
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

  export default {
    name: 'HomepageVideoListDesktop',

    components: { VueSlickCarousel },

    props: {
      data: {
        type: Object,
        default: () => ({
        }),
      },
      activeCatCode: {
        type: String,
        default: 'health'
      },
    },

    data() {
      return {
        settings: {
          "dots": true,
          "focusOnSelect": false,
          "infinite": true,
          "speed": 500,
          "slidesToShow": 3,
          "slidesToScroll": 1,
          "touchThreshold": 5,
          "responsive": [
            {
              "breakpoint": 1024,
              "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "infinite": true,
                "dots": true
              }
            },
            {
              "breakpoint": 768,
              "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 1
              }
            },
            {
              "breakpoint": 480,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "arrows": false
              }
            }
          ]
        }
      }
    },

    methods: {
      // create category code array
      categoryCodes() {
        const categoryArray = [];
        for(const x of this.data?.VideoCategoryButton) {
          categoryArray.push(x.category_code);
        }
        return categoryArray;
      },
      // filter videos to make a cards array
      videoCards(category) {
        return this.data?.Videos.filter(video => video.category_code == category);
      }
    }
  }
  