
import processImageLink from '../../mixins/processImageLink.js'

export default {
  name: 'InsurerPartner',

  props: {
    data: {
      type: Object,
      default: () => ({
        InsurerPartnerLogo: [],
        Title: undefined,
        Description: undefined,
      }),
    },
  },
  mixins: [processImageLink],
};
