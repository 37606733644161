export default (
  { graphQLErrors, networkError, operation, forward },
  { store, error, redirect, route }
) => {
  // @todo
  // check environment to show error, use errorMessage from apollo is good choice
  // or better use log handler control log by configuration file
  //  console.error('GraphQL Error: ', JSON.stringify(graphQLErrors));
  //  console.error('GraphQL Network Error: ', JSON.stringify(networkError));
}
