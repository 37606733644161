
export const state = () => ({
  filters: {
    tagCodes: [],
    activeType: 'tag',
    year: ''
  },
  showMobileMenu: false,
  showDesktopMenu: true,
  currentPage: 1
})

export const mutations = {
  clearTagCodes(state) {
    state.filters.tagCodes = [];
  },
  clearYear(state) {
    state.filters.year = '';
  },

  setTagCodes(state, data) {
    const index = state.filters.tagCodes.indexOf(data);
    if (index !== -1) {
      state.filters.tagCodes.splice(index, 1);
    } else {
      state.filters.tagCodes.push(data)
    }
  },

  setYear(state, data) {
    if (state.filters.year == data) {
      state.filters.year = '';
    } else {
      state.filters.year = data;
    }
  },

  setAllTagCodes(state, data) {
    state.filters.tagCodes = data;
  },

  setCurrentPage(state, data) {
    state.currentPage = data;
  }
//   setActiveType(state, data) {
//     state.filters.activeType = BlogFilterTypeEnum[data];
//   },
//   setMobileMenuState(state, data) {
//     state.showMobileMenu = data;
//   },
//   setDesktopMenuState(state, data) {
//     state.showDesktopMenu = data;
//   }
}

export const getters = {
  filtersTagCodes: (state) => {
    return state?.filters?.tagCodes;
  },

  filtersActiveType: (state) => {
    return state?.filters?.activeType;
  },

  getCurrentPage: (state) => {
    return state?.currentPage;
  },
  getCurrentYear: (state) => {
    return state?.filters.year;
  }
//   showMobileMenu: (state) => {
//     return state?.showMobileMenu;
//   },
//   showDesktopMenu: (state) => {
//     return state?.showDesktopMenu;
//   }
}
