import { render, staticRenderFns } from "./NavigationContentAboutUs.vue?vue&type=template&id=6a0cf11a&scoped=true"
import script from "./NavigationContentAboutUs.vue?vue&type=script&lang=js"
export * from "./NavigationContentAboutUs.vue?vue&type=script&lang=js"
import style0 from "./NavigationContentAboutUs.vue?vue&type=style&index=0&id=6a0cf11a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0cf11a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationContentIntro: require('/app/components/navigation-bar/navigation-content/sections/NavigationContentIntro.vue').default,NavigationContentLinks: require('/app/components/navigation-bar/navigation-content/sections/NavigationContentLinks.vue').default,NavigationContentCompanyInfo: require('/app/components/navigation-bar/navigation-content/sections/NavigationContentCompanyInfo.vue').default})
