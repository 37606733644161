import { render, staticRenderFns } from "./DisclaimerModal.vue?vue&type=template&id=67c9e0bd&scoped=true"
import script from "./DisclaimerModal.vue?vue&type=script&lang=js"
export * from "./DisclaimerModal.vue?vue&type=script&lang=js"
import style0 from "./DisclaimerModal.vue?vue&type=style&index=0&id=67c9e0bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c9e0bd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReusableButton: require('/app/components/common/ReusableButton.vue').default,ModalWrapper: require('/app/components/modal/common/ModalWrapper.vue').default})
