
export default {
    name: 'WidgetContainerMobile',

    data() {
        return {
            isAdvisorBlog: false,
        }
    },

    mounted() {
        // check if the blog is advisor blog or not
        const urlParams = new URLSearchParams(window.location.search);
        this.isAdvisorBlog = urlParams.has('adid');
    },
}
