const middleware = {}

middleware['redirectInvalidBlog'] = require('../middleware/redirectInvalidBlog.js')
middleware['redirectInvalidBlog'] = middleware['redirectInvalidBlog'].default || middleware['redirectInvalidBlog']

middleware['redirectInvalidPath'] = require('../middleware/redirectInvalidPath.js')
middleware['redirectInvalidPath'] = middleware['redirectInvalidPath'].default || middleware['redirectInvalidPath']

middleware['zhCnRedirection'] = require('../middleware/zhCnRedirection.js')
middleware['zhCnRedirection'] = middleware['zhCnRedirection'].default || middleware['zhCnRedirection']

export default middleware
