
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        AboutUs: {},
        Broker: {},
        Decoder: {},
        Lifestage: {},
      }),
    },
  },

  data() {
    return {
      activeItems: new Array(5).fill(false),
    };
  },

  computed: {
    currentLocaleCode() {
      return this.$i18n.locale;
    },
    drifterZoneCNEntryPoints () {
      let entryPoints = [];
      if (this.data?.DrifterZoneCNEntryPoints) {
        for (let item of this.data?.DrifterZoneCNEntryPoints.Header) {
          entryPoints.push(item);
        }
      }
      return entryPoints;
    }
  },

  methods: {
    toggleItemActive(index) {
      this.activeItems.forEach((element, i) => {
        if (i !== index) {
          this.$set(this.activeItems, i, false);
        } else {
          this.$set(this.activeItems, index, !this.activeItems[index]);
        }
      });
    },

    async toggleMobileNavMenuIfSwitchLang(lang) {
      if (this.currentLocaleCode === lang) {
        return;
      }

      await new Promise((resolve, reject) => this.$router.push({ path: this.switchLocalePath(lang) }, resolve, reject));
      this.$emit('toggle-mobile-nav-menu');
    },
  },
};
