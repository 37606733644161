
import popupBannerQuery from '@/apollo/queries/general/popupBannerQuery';
import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';

export default {
  name: 'PopupBannerModal',
  mixins: [eventTrackingMixins],

  apollo: {
    $loadingKey: 'loading',
    popupBanner: {
      query: popupBannerQuery,
      prefetch: true,
      variables() {
        return { currentlocale: this.$normalizeLocale(this.$i18n.locale) };
      },
      update(data) {
        return this.$normalizeStrapi(data.popupBanner);
      }
    }
  },

  props: {

  },

  data() {
    return {

    }
  },
  methods: {
    confirm() {
      this.$store.commit('modal/setShowPopupBannerModal', false);
    },
   
    closeModal() {
      this.loginBox = false;
      this.$nextTick(() => {
        this.$emit('close-modal');
      });
    },

    async handleClick(e,bannerItem) {
      const link = bannerItem.Link;
      const eventAction = bannerItem.TrackingParams?.event_action;
      const windowReference = window.open();
      try {
        await this.handleTracking(link, eventAction).then(() => {
          windowReference.location = link;
        });
      } catch (error) {
        console.error('handleClick error:', error);
        windowReference.location = link;
      }
    },

    async handleTracking(targetUrl, event_action) {
      const eventTrackingParams = this.initEventTrackingParams();
      const targetLink = targetUrl;
      const eventAction = event_action || '';
      if ( eventAction) {
        eventTrackingParams.a = eventAction;
        eventTrackingParams.l = targetLink;
        eventTrackingParams.c = 'pop_up_banner';
        await this.sendEventTracking(eventTrackingParams, true);
      }
    }
  },
};
