export const state = () => ({
  category: '',
  products: [],
})

export const mutations = {
  addProductToCompare(state, data) {
    // reset products if category is changed
    if (state.category !== data.category) {
      state.products = [];
    }

    if (state.products.map(p => p.id).includes(data.product.id))
      return;

    if (state.products?.length >= data.showQuantity)
      return;

    state.products.push(data.product);
    state.category = data.category;
  },

  updateProductToCompare(state, data) {
    const targetProductID = data.targetProductID;
    const newProduct = data.newProduct;
    const foundProduct = state.products.find(p => p.id === targetProductID);

    if (foundProduct) {
      Object.assign(foundProduct, newProduct);
    }
  },

  removeProductToCompare(state, productID) {
    const foundProduct = state.products.map(p => p.id).indexOf(productID);
    if (foundProduct !== -1) {
      state.products.splice(foundProduct, 1);
    }
  },

  clearProducts(state) {
    state.category = '';
    state.products = [];
  },

  // when lang changes..
  // triggered when page loads (created / mounted ..?)
  refreshProducts(state, data) {
    // as category is updated whenever route path is updated
    // in case category is empty, stops processing
    if (data.category === '')
      return;

    // if category changes
    // reset all products
    if (state.category !== data.category) {
      state.category = data.category;
      state.products = [];
      return;
    }

    // somehow it will send empty products
    // if so, stop processing
    if (!data.products)
      return;

    // otherwise
    // match current id with all products passed to here
    // refresh current set of products with passed data
    const stateProductsIDs = state.products.map(p => p.id);
    const matchedProducts = data.products?.filter(product => stateProductsIDs.includes(product.id));

    if (matchedProducts?.length !== state.products?.length)
      return;

    const replaceProducts = state.products.map(p => matchedProducts.find(mp => mp.id === p.id) || p);
    state.products = replaceProducts;
  },
}

export const getters = {
  productsToCompare: (state) => {
    return state.products;
  },
  productsToCompareLength: (state) => {
    return state.products.length;
  },
  categroy: (state) => {
    return state.category;
  },
  containsProductToCompare: (state) => (productID) => {
    return state.products.map(p => p.id).includes(productID);
  },
}