export default (promise) => {
  return promise
    .then((result) => {
      return [false, result];
    })
    .catch((err) => {
      console.debug('** Try Catch - Error **')
      console.debug(JSON.stringify(err));
      return [err, false];
    })
}