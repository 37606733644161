export default function ({ $axios }) {

  $axios.onResponse(response => {
    // console.debug('*** Axios Response ***')
    // console.debug('Axios path: ', response.config?.url)
    // console.debug('Axios response status: ', response.status)
    // console.debug('Axios response data: ', response.data)
  })

  // $axios.onError(error => {
  //   const code = parseInt(error.response && error.response.status)
  //   console.error('*** Axios Error ***')
  //   console.error(code)
  //   // console.error(JSON.stringify(error.response))
  // })

}