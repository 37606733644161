// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/family/icon/video-click-button.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes circular-animation-f654a010{to{stroke-dashoffset:0}}.video-card[data-v-f654a010]{padding:10px}.video-card[data-v-f654a010],.video-card>.wrapper[data-v-f654a010]{display:flex;flex-direction:column;overflow:hidden;width:100%}.video-card>.wrapper[data-v-f654a010]{align-items:flex-start;border-radius:10px;box-shadow:0 0 5px #ccc;color:#fff;justify-content:flex-start;text-align:left}@media(max-width:1024px){.video-card>.wrapper[data-v-f654a010]{flex-direction:column}}.video-card>.wrapper .body[data-v-f654a010]{height:0;overflow:hidden;padding-bottom:56.25%;position:relative;width:100%}.video-card>.wrapper .body .playButton[data-v-f654a010]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;bottom:0;height:50%;left:0;margin:auto;position:absolute;right:0;top:0;width:50%}.video-card>.wrapper .body .playButton[data-v-f654a010]:hover{cursor:pointer}@media(max-width:1024px){.video-card>.wrapper .body[data-v-f654a010]{width:100%}}.video-card>.wrapper .body img[data-v-f654a010]{-o-object-fit:contain;object-fit:contain;width:100%}.video-card>.wrapper .footer[data-v-f654a010]{width:100%}.video-card>.wrapper .footer .title[data-v-f654a010]{padding:20px}.video-card>.wrapper .footer .title .text[data-v-f654a010]{color:#000;font-size:18px;font-weight:700}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
