import gql from 'graphql-tag';
import blogByTitle from '@/apollo/queries/blog/blogByTitle';

export default async function ({ redirect, route, app }) {
  if (!route.path.includes('/blog/')) return;
  if (!route.params.slug) return;

  const getLocale = () => {
    if (route.path.includes('/en/')) return 'en';
    if (route.path.includes('/zh-HK/')) return 'zh-HK';
    if (route.path.includes('/zh-CN/')) return 'zh-CN';
  }

  const locale = getLocale();

  const variables = {
    slug: route.params.slug,
    currentlocale: ( locale === 'zh-HK' ) ? 'zh-Hant-HK' : locale
  }

  const data = await fetchData(app.apolloProvider.defaultClient, variables);

  // check if ShowInSitemap != true, then redirect to blog 404
  // if ( data && data[0].attributes.ShowInSitemap !== true ) {
  //   return redirect(301, `/${locale}/blog/404`);
  // }

  if (!data || data.length === 0) {
    return redirect(301, `/${locale}/blog/404`);
    // return redirect(301, `/${locale}/404`);
  }
}

async function fetchData(apolloClient, variablesData) {
  try {
    const response = await apolloClient.query({
      query: blogByTitle,
      variables: variablesData,
    });
    return response.data.blogs.data;
  } catch (error) {
    console.error('Error fetching blog data:', error);
    return null;
  }
}
