
export default {
  data() {
    return {
      complete: false,
    };
  },
  methods: {
    goToLogin() {
      this.$store.commit('modal/setShowLeadFormModal', false);
      this.$store.commit('modal/setShowLoginModal', true);
    },

    completeForm() {
      this.complete = true;
    },
  },
};
