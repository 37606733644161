
import processImageLink from '../../mixins/processImageLink.js'

export default {
    name: 'ImgComponent',

    props: {
        src: {
            type: String,
            default: "",
        },
        alt: {
            type: String,
            default: "",
        },
        loading: {
            type: String,
            default: null,
        },
        aliasLinkOnly: {
            type: Boolean,
            default: true
        },
    },
    mixins: [processImageLink],
}
