
import processImageLink from '../../mixins/processImageLink.js'

export default {
  name: 'HomepageNeedhelp',
  props: {
    data: {
      type: Object,
      default: () => ({
        OverlayImage: {},
        NeedHelpModuleSlide: [],
      }),
    },
  },
  mixins: [processImageLink],
  data() {
    return { 
      activeItemId: 0,
    }
  },
  computed: {
    // id() {
    //   return this.$route.params.id
    // },
  },
  methods: {
    toggleActiveItem(i) {
      if (i === this.activeItemId) {
        this.activeItemId = -1
      } else {
        this.setActiveItem(i)
      }
    },

    setActiveItem(i) {
      this.activeItemId = i
    }
  },
}
