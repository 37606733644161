import { render, staticRenderFns } from "./ModalsContainer.vue?vue&type=template&id=601d3f6c"
import script from "./ModalsContainer.vue?vue&type=script&lang=js"
export * from "./ModalsContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConfirmationModal: require('/app/components/modal/ConfirmationModal.vue').default,LeadFormModal: require('/app/components/modal/LeadFormModal.vue').default,ContactUsFormModal: require('/app/components/modal/ContactUsFormModal.vue').default,ProfileMenuModal: require('/app/components/modal/ProfileMenuModal.vue').default,WechatModal: require('/app/components/modal/WechatModal.vue').default,VideoModal: require('/app/components/modal/VideoModal.vue').default,DrifterZoneVideoModal: require('/app/components/modal/DrifterZoneVideoModal.vue').default,DecoderCTAModal: require('/app/components/modal/DecoderCTAModal.vue').default,DisclaimerModal: require('/app/components/modal/DisclaimerModal.vue').default,PopupBannerModal: require('/app/components/modal/PopupBannerModal.vue').default})
