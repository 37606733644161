
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        CardVisual: {},
        Introduction: {}
      })
    },
    mobileOnly: {
      type: Boolean,
      default: false
    }
  }
};
