
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      showConfirmationModal: 'modal/showConfirmationModal',
      showLeadFormModal: 'modal/showLeadFormModal',
      showContactUsFormModal: 'modal/showContactUsFormModal',
      showProfileMenuModal: 'modal/showProfileMenuModal',
      showWechatModal: 'modal/showWechatModal',
      showVideoModal: 'modal/showVideoModal',
      showDrifterZoneVideoModal: 'modal/showDrifterZoneVideoModal',
      showProductDisclaimerModal: 'modal/showProductDisclaimerModal',
      showBlogDisclaimerModal: 'modal/showBlogDisclaimerModal',
      blogDisclaimerModalData: 'modal/blogDisclaimerModalData',
      showDecoderCTAModal: 'modal/showDecoderCTAModal',
      showPopupBannerModal: 'modal/showPopupBannerModal',
    }),
  },

  methods: {
    closeLoginModal() {
      this.$store.commit('modal/setShowLoginModal', false);
    },

    closeConfirmationModal() {
      this.$store.commit('modal/setShowConfirmationModal', false);
    },

    closeLeadFormModal() {
      this.$store.commit('modal/setShowLeadFormModal', false);
    },

    closeContactUsFormModal() {
      this.$store.commit('modal/setShowContactUsFormModal', false);
    },

    closeProfileMenuModal() {
      this.$store.commit('modal/setShowProfileMenuModal', false);
    },

    closeWechatModal() {
      this.$store.commit('modal/setShowWechatModal', false);
    },

    closeVideoModal() {
      this.$store.commit('modal/setShowVideoModal', false);
    },

    closeDrifterZoneVideoModal() {
      this.$store.commit('modal/setShowDrifterZoneVideoModal', false);
    },

    closeProductDisclaimerModal() {
      this.$store.commit('modal/setShowProductDisclaimerModal', false);
    },

    closeBlogDisclaimerModal() {
      this.$store.commit('modal/setShowBlogDisclaimerModal', false);
    },

    closeDecoderCTAModal() {
      this.$store.commit('modal/setShowDecoderCTAModal', false);
    },
    closePopupBannerModal() {
      this.$store.commit('modal/setShowPopupBannerModal', false);
    }
  },
};
