
export default {
  name: 'Faq',
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return { 
      activeItemId: 0,
    }
  },
  computed: {
  },
  methods: {
    toggleActiveItem(i) {
      if (i === this.activeItemId) {
        this.activeItemId = -1
      } else {
        this.setActiveItem(i)
      }
    },

    setActiveItem(i) {
      this.activeItemId = i
    }
  },
}
