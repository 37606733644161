import navigationBarQuery from '@/apollo/queries/navigation/navigationbar';

export default {
  data() {
    return {
      navigationData: Object,
    }
  },

  apollo: {
    $loadingKey: 'loading',
    navigationData: {
      prefetch: true,
      query: navigationBarQuery,
      variables() {
        return { currentlocale: this.$normalizeLocale(this.$i18n.locale) };
      },
      update(data) {
        return this.$normalizeStrapi(data.navigationBar.data);
      },
    },
  },
}