import { render, staticRenderFns } from "./white.vue?vue&type=template&id=7d55d77a&scoped=true"
import script from "./white.vue?vue&type=script&lang=js"
export * from "./white.vue?vue&type=script&lang=js"
import style0 from "./white.vue?vue&type=style&index=0&id=7d55d77a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d55d77a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationBar: require('/app/components/navigation-bar/NavigationBar.vue').default,ModalsContainer: require('/app/components/modal/ModalsContainer.vue').default,Footer: require('/app/components/Footer.vue').default,DrifterZoneQRCodeWidget: require('/app/components/drifter-zone/DrifterZoneQRCodeWidget.vue').default,PromoFloatingWidget: require('/app/components/common/PromoFloatingWidget.vue').default,WechatFloatingWidget: require('/app/components/common/WechatFloatingWidget.vue').default,WhatsappFloatingWidget: require('/app/components/common/WhatsappFloatingWidget.vue').default,EnquiryFloatingWidget: require('/app/components/common/EnquiryFloatingWidget.vue').default,BackToTopButton: require('/app/components/common/BackToTopButton.vue').default,WidgetContainer: require('/app/components/common/WidgetContainer.vue').default,WidgetContainerMobile: require('/app/components/common/WidgetContainerMobile.vue').default,FunctionBar: require('/app/components/common/FunctionBar.vue').default})
