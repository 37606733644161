
  import promoWidgetQuery from '@/apollo/queries/general/promoWidgetQuery';
  import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';

  export default {
    name: 'PromoFloatingWidget',
    mixins: [eventTrackingMixins],
    apollo: {
      $loadingKey: 'loading',
      promoWidget: {
        prefetch: true,
        query: promoWidgetQuery,
        variables() {
          return { currentlocale: this.$normalizeLocale(this.$i18n.locale) }
        },
        update(data) {
          return this.$normalizeStrapi(data.promoWidgetItems);
        },
      },
    },

    data() {
      return {
        closedPromoWidgets: [],
      }
    },
    computed: {
      activePromoWidget() {
        return this.promoWidget?.filter((item) => {
          const cookiesKey = '10life-' + item?.Name.replace(/\s/g, '-');
          if (this.clickedClose(cookiesKey)) return false;
          if (this.closedPromoWidgets.includes(cookiesKey)) return false;
          return true
        });
      },
      // 
    },
    methods: {
      clickedClose(cookiesKey) {
        if(process.client) {
          const value = sessionStorage.getItem(cookiesKey);
          if (value) {
            return true;
          }
        } else {
          return false;
        }
      },
      closeWidget(cookiesKey) {
        cookiesKey = cookiesKey.replace(/\s/g, '-');
        this.setSession(cookiesKey, true);
        if (!this.closedPromoWidgets.includes(cookiesKey)) {
          this.closedPromoWidgets.push(cookiesKey);
        }
      },
      setSession(key, value) {
        if(process.client) {
          sessionStorage.setItem(key, value);
        }
      },
      handleTracking(link, trackingParams) {
        const eventAction = trackingParams?.event_action;
        if (!eventAction) console.error('PromoFloatingWidget - eventAction:', eventAction);

        const eventTrackingParams = this.initEventTrackingParams();
        const targetLink = link;

        eventTrackingParams.a = eventAction;
        eventTrackingParams.l = targetLink;
        eventTrackingParams.c = 'promo_widget';
        this.sendEventTracking(eventTrackingParams);

      },
      isBlogSinglePage() {
        if (this.$route.name) {
          return this.$route.name.split('___')[0] === 'blog-slug';
        }
        return false;
      },
      allowDisplay(displayRules) {
        const { allow_full_site, allow_blog_cat, disallow_blog_cat, disallow_url_text, allow_url_text } = displayRules;

        if (disallow_url_text.length > 0) {
          const disallow = disallow_url_text.some((item) => {
            // console.debug('fullPath & item.Text', encodeURIComponent(this.$route.fullPath).toLowerCase(), encodeURIComponent(item.Text).toLowerCase());
            return (encodeURIComponent(this.$route.fullPath).toLowerCase()).includes(encodeURIComponent(item.Text).toLowerCase());
          });
          if (disallow) return false;
        }        
        if (disallow_blog_cat.length > 0) {
          if (this.isBlogSinglePage()) {
            const disallow = disallow_blog_cat.some((item) => {
              return this.$store.state.blog.currentBlogSingleTags.some((tag) => tag.TagCode === item.TagCode);
            });
            if (disallow) return false;
          }
        }

        if (allow_full_site) return true;
        if (allow_url_text.length > 0) {
          const allow = allow_url_text.some((item) => {
            // console.debug('fullPath & item.Text', encodeURIComponent(this.$route.fullPath).toLowerCase(), encodeURIComponent(item.Text).toLowerCase());
            return (encodeURIComponent(this.$route.fullPath).toLowerCase()).includes(encodeURIComponent(item.Text).toLowerCase());
          });
          if (allow) return true;
        }
        if (allow_blog_cat.length > 0) {
          if (this.isBlogSinglePage()) {
            const allow = allow_blog_cat.some((item) => {
              return this.$store.state.blog.currentBlogSingleTags.some((tag) => tag.TagCode === item.TagCode);
            });
            if (allow) return true;
          }
        }

        return false;
      },
    },
  };
  