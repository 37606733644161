if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.onupdatefound = function () {
        var installingWorker = registration.installing;
        installingWorker.onstatechange = function () {
          switch (installingWorker.state) {
            case 'installed':
              if (navigator.serviceWorker.controller) {
                  setTimeout(function(){
                    console.debug("service worker reload");
                    window.location.reload();
                  }, 3000);                    
              } else {
                console.debug('service worker register succeed');
              }
              break;
            case 'redundant':
              console.debug('The installing service worker became redundant.');
              break;
          }
        };
      };        
    }
  });
}
