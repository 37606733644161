
import homepageQuery from '@/apollo/queries/home/homepage';
import HomepageProductShortcut from '~/components/homepage/HomepageProductShortcut.vue';
import HomepageVideoGallerySection from '~/components/homepage/HomepageVideoGallerySection.vue';

export default {
  name: 'IndexPage',
  layout: 'overlay',

  data() {
    return {
      homepage: Object,
      loading: 0,
    };
  },

  head() {
    return {
    title: this.$t('page_meta.home.title'),
    meta: [
        {
        hid: 'description',
        name: 'description',
        content: this.$t('page_meta.home.description')
        }
    ]
    }
  },

  apollo: {
    $loadingKey: 'loading',
    homepage: {
      prefetch: true,
      query: homepageQuery,
      variables() {
        return { currentlocale: this.$normalizeLocale(this.$i18n.locale) };
      },
      update(data) {
        return this.$normalizeStrapi(data.homepage.data);
      },
    },
  },
};
