import { render, staticRenderFns } from "./TutorialButton.vue?vue&type=template&id=d56eb9be&scoped=true"
import script from "./TutorialButton.vue?vue&type=script&lang=js"
export * from "./TutorialButton.vue?vue&type=script&lang=js"
import style0 from "./TutorialButton.vue?vue&type=style&index=0&id=d56eb9be&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d56eb9be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReusableLink: require('/app/components/common/ReusableLink.vue').default})
