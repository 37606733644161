import { render, staticRenderFns } from "./NavigationContentDecoder.vue?vue&type=template&id=507602a0&scoped=true"
import script from "./NavigationContentDecoder.vue?vue&type=script&lang=js"
export * from "./NavigationContentDecoder.vue?vue&type=script&lang=js"
import style0 from "./NavigationContentDecoder.vue?vue&type=style&index=0&id=507602a0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507602a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationContentIntro: require('/app/components/navigation-bar/navigation-content/sections/NavigationContentIntro.vue').default,NavigationContentLinks: require('/app/components/navigation-bar/navigation-content/sections/NavigationContentLinks.vue').default})
