
import Try from '~/helpers/tryCatch.js';
import commonMixin from '~/mixins/output.js';
import { VALIDATION_RULES } from '@/constants/';

export default {
  mixins: [commonMixin],

  props: {
    mobileOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      input: { enquiry_type: null, name: '', email: '', enquiry: '' },
      rules: VALIDATION_RULES,
      prcoessing: false,
    };
  },

  methods: {
    closeModal() {
      this.resetInput();
      this.$emit('close-modal');
    },

    resetInput() {
      for (const inputKey of Object.keys(this.input)) {
        this.input[inputKey] = null;
      }

      this.$nextTick(() => {
        this.formReset();
      });
    },

    async postContactUsForm() {
      if (this.processing === true) {
        return;
      }

      this.processing = true;

      const postParams = {
        email: this.input.email,
        message: this.input.enquiry,
        name: this.input.name,
        question_type: this.input.enquiry_type,
      };

      const [err, res] = await Try(this.$postContactUs(postParams));

      if (err) {
        this.processing = false;
        return this.outputError(
          'Unexpected error when submitting contact us form.'
        );
      }

      if (res) {
        this.processing = false;
      }

      this.resetInput();
      this.$emit('completeForm');
    },

    formReset() {
      this.$refs?.form?.reset();
    },
  },
};
