export const VALIDATION_RULES = {
  email: 'required|email|max:250',
  password: {
    required: true,
    regex: /^[a-zA-Z0-9]+$/,
    min: 8
  },
  nickname: 'required|max:250',
  phone: {
    required: true,
    regex: /^(?:\+)?(?:\d{3})?[\s.-]?\d{4}[\s.-]?\d{4}$/,
    min: 8,
    max: 50
  }
}