
import Try from '~/helpers/tryCatch.js';
import commonMixin from '~/mixins/output.js';
import { VALIDATION_RULES } from '@/constants/';

export default {
  mixins: [commonMixin],

  data() {
    return {
      input: { nickname: '', gender: null, age: null, phone: '', enquiry: '' },
      rules: VALIDATION_RULES,
    };
  },

  methods: {
    resetInput() {
      for (const inputKey of Object.keys(this.input)) {
        this.input[inputKey] = undefined;
      }
    },

    async postLeadForm() {
      const inquiriesParams = {
        inquiry: {
          // analytics_user_id: window.d10UserId,
          mobile_number: this.input.phone,
          name: this.input.nickname,
          content: this.input.enquiry,
          age: this.input.age,
          gender: this.input.gender,

          // need below product_id - otherwise can't link back product in node_backend
          // product_id: productId
        },
      };

      const [err, res] = await Try(this.$postInquiries(inquiriesParams));

      if (err)
        return this.outputError('Unexpected error when submitting lead form.');

      this.resetInput();
      this.$emit('completeForm');
    },
  },
};
