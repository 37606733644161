import zh from 'vee-validate/dist/locale/zh_TW'

export default (context, locale) => {
  return {
    menu: {
      login: '登入',
      logout: '登出',
      hotline_number: '3705 1599',
      a_href_hotline_number: 'tel:+85237051599',
      email: 'enquiries@10life.com',
      company_address: '香港 灣仔 皇后大道東 109-115號 智群商業中心 12 樓',
      navigation_content: {
        about_us: {
          hotline_number: '(852) 3705 1599'
        }
      },
      profile: {
        my_profile: '我的帳戶',
        reset_password: '更改密碼',
        logout: '登出'
      },
    },
    navBar: {
      phone: '熱線電話',
    },
    welcome: '歡迎',
    confirm: '確定',
    not_applicable: '不適用',
    page_meta: {
      'home': {
        title: '10Life：香港保險比較平台｜買保險 有比較 比較好',
        description: '10Life比較超過1000份人壽、儲蓄、年金及醫療保險，讓香港人認識保險、買啱保險。用戶亦可以直接聯絡10Life保險顧問諮詢及投保。'
      },
      'life-stage': {
        title: '我需要咩保險？',
        description: '不同的人生階段，需要不同的保障，透過模擬個案，認識所需保障及預算。'
      },
      products_landing_page: {
        title: '10Life 比較全港1000+保險｜買保險 有比較 比較好',
        description: '10Life精算師引入評分制度，公平地比較保險產品細節，涵蓋人壽、儲蓄、年金及醫療保險。保險產品好壞，無所遁形，助你自己揀保險。',
      },
    },
    disclaimerModal: {
      leave: '離開',
      continue: '繼續瀏覽'
    },
    authModal: {
      intro_wording: {
        title: '10Life 保險比較平台',
        description: '精算大數據 幫你拆解全港1000+保險產品',
      },
      login: {
        title: '登入',
        email: '電郵地址',
        password: '密碼',
        forget_password: '忘記密碼',
        sign_up_prompt_title: '沒有10Life帳戶?',
        sign_up_prompt_button: '立即加入',
        login_error: '發生錯誤，請稍後再試',
        login_advisor_error: '顧問請使用10life手機應用程式登入。',
        login_button: '登入',
      },
      register: {
        title: '立即登記',
        login_prompt_title: '已有帳號？',
        login_prompt_button: '立即登入！'
      },
      signup: {
        title: '以電郵註冊',
        goto_register_page: '更多登記方法',
        email: '登記電郵',
        password: '密碼',
        confirm_password: '確認密碼',
        nickname: '暱稱',
        sign_up_error: '發生錯誤，請稍後再試',
        sign_up_button: '立即登記',
      },
      verify_email: {
        title: '驗證電郵',
        resend_email_content: [
          '10Life已將驗證電郵發送給您，請於48小時內驗證您的電郵地址，以便使用完整的功能。',
          '如果在註冊時填寫的電子郵件地址不正確，請重新註冊。',
          '如未收到驗證電郵，請點擊',
          '重新領取新的驗證電郵。',
        ],
        resend_email_button: '此按鈕',
        resend_email_response: '已發出驗證電郵',
      },
      forget_password: {
        title: '重設密碼',
        email: '電郵地址',
        description: '請提供10Life帳戶之登記電郵，以接收重設密碼提示',
        forget_password_error: '發生錯誤，請稍後再試',
        forget_password_message: 'Please check email for password reset.',
        forget_password_button: '寄出重設密碼電郵',
      },
      facebook_auth: {
        title: '以FACEBOOK繼續',
      },
      google_auth: {
        title: '以GOOGLE繼續'
      },
      disclaimer: [
        '當您註冊帳戶時，代表您同意10Life的<a href="/zh-HK/privacy" target="_self">私隱政策</a>及<a href="/zh-HK/terms" target="_self">使用條款</a>。',
        '當您登入帳戶時，代表您同意10Life的<a href="/zh-HK/privacy" target="_self">私隱政策</a>及<a href="/zh-HK/terms" target="_self">使用條款</a>。',
        '我同意透過電郵地址及聯絡電話接收10Life通訊及推廣資訊。'
      ],
      dividing_or: '或',
      password_remarks: '密碼必須多於8個字元，允許大階、細階英文字母或數字'
    },
    user_center: {
      title: '帳戶設定',
      reset_password: {
        title: '修改密碼',
        current_password: '現有密碼',
        new_password: '新密碼',
        confirm_new_password: '確認密碼',
        forget_password: '忘記密碼?',
        reset_password_error: '發生錯誤，請稍後再試',
        reset_password_message: 'Reset password successfully.',
        reset_password_button: '確定',
      },
      update_profile: {
        title: '我的帳戶',
        membership_default_level: '保險新手',
        nickname: '暱稱',
        date_of_birth: '出生日期',
        gender: '性別',
        gender_options: [
          {
            value: 'male',
            text: '男'
          },
          {
            value: 'female',
            text: '女'
          },
        ],
        martial_status: '婚姻狀況',
        martial_status_options: [
          {
            value: 'single',
            text: '單身'
          },
          {
            value: 'married',
            text: '已婚'
          },
        ],
        number_of_children: '子女數目',
        number_of_pets: '寵物數目',
        select_text: '--- 請選擇{replace_me} ---',
        reset_button: '重設',
        reset_message: '已重設你的帳戶',
        save_button: '儲存',
        save_message: '已更新你的帳戶',
        save_error: '發生錯誤，請稍後再試',
      },
    },
    my_favourite: {
      title: '我的收藏',
      insurance_product: {
        title: '保險產品',
        product_selected: '已選 <strong>$num</strong> 個產品',
        no_product: '未儲存任何保險產品。瀏覽保險比較，揀選最合適你的產品。'
      },
      blog: {
        title: '網誌',
        blog_selected: '已選 <strong>$num</strong> 個網誌',
        no_blog: '未儲存任何文章。瀏覽10Life網誌，掌握最新保險產品資訊。'
      },
      select_all: '全選',
    },
    contact_us_form_modal: {
      company_info: {
        title: '10Life Financial Limited',
        email: '查詢: enquiries@10life.com',
        hotline: '電話: (852) 3705 1599',
        address: '地址: 香港 灣仔 皇后大道東 109-115號 智群商業中心 12樓',
      },
      form: {
        title: '聯絡我們',
        description: '想查詢或提出建議？我們非常樂意聆聽你的意見。',
        enquiry_type: '查詢類型',
        enquiry_options: [
          {
            value: 'enquiryForAppBug',
            text: '網頁或App問題'
          },
          {
            value: 'enquiryForOthers',
            text: '一般查詢'
          },
        ],
        name: '稱呼',
        email: '電郵地址',
        message: '留言',
        submission_button: '提交',
      },
      complete_message: '您已成功提交，10Life Financial Limited 的同事會於一個工作天內與您聯絡。',
      complete: '完成'
    },
    wechatModal: {
      title: '請用WeChat掃瞄二維碼',
      description: '立即WeChat查詢'
    },
    decoderCtaModal: {
      title: '請選擇你的投保方式',
      onlineApplication: '自己網上申請',
      whatsappConsultation: 'WhatsApp<br>搵人幫手',
    },
    social_links: {
      facebook: 'https://www.facebook.com/10LifeHK/',
      instagram: 'https://www.instagram.com/10lifehk/',
      linkedin: 'https://hk.linkedin.com/company/10life',
      youtube: 'https://www.youtube.com/channel/UCz8b7EYrOF4iXFIsap30kkw',
    },
    validation: {
      ...zh.messages,
    },
    footer: {
      logo: '../assets/images/common/tenlife-normal-logo.png',
      contact_section_description: '如需要幫助，請聯絡我們',
      contact_form_title: '留低聯絡資料及問題',
      contact_form_button_text: '立即查詢',
      contact_form_button_link: '',
      hotline_title: '致電10Life即傾',
      hotline_label: '熱線電話',
      hotline_number: '3705 1599',
      enews_section_title: '免費訂閱 精明買啱保險',
      enews_section_description: '公開保險公司未必想您知的細節',
      enews_section_hint: '輸入電郵',
      enews_section_successful_message: '已成功訂閱！',
      enews_section_error_message: '此電郵已登記。',
      disclaimer: [
        '10Life.com 是由10Life Financial Limited（於香港註冊，公司編號為 1154750）(“10Life Financial”) 營運。',
        '10Life Financial Limited 是獲授權保險經紀公司，保監局牌照號碼為FB1526，其業務是營運保險比較平台，以及為客戶安排保險產品及服務。10Life Financial 為 10Life Group 全資擁有的子公司。',
      ],
      copyright_text1: '©',
      copyright_text2: ' 10Life Group Limited（於香港註冊，公司編號為 2366460) (“10Life Group”)。版權所有。',
      social_title: '追蹤我們',
      hotlinks: [
        {
          text: '使用條款',
          link: '/zh-HK/terms'
        },
        {
          text: '私隱政策',
          link: '/zh-HK/privacy'
        },
        {
          text: 'Cookies政策',
          link: '/zh-HK/cookies'
        }
      ]
    },
    forgetPassword: {
      title: '忘記密碼'
    },
    error_page: {
      title: '找不到此頁。',
      return_home: '返回主頁',
      goto_url: '/'
    },
    blog: {
      article: '文章主題',
      author: '專欄作者',
      authorAllArticles: '所有文章',
      trending: '熱門文章',
      recommandedToYou: '為你推介',
      minuteToRead: '分鐘閱讀',
      browseAuthor: '查看作者',
      articleAnchor: '文章重點',
      relatedProduct: '相關產品',
      disclaimer: '免責聲明',
      sidebarToggle: '熱門文章',
      noBlog: '沒有相關主題的文章，請重新篩選。',
      loadMore: '載入更多',
      reset: '重新篩選',
      filter: {
        all: '全部',
        type: {
          category: '文章主題',
          author: '專欄作者'
        },
        confirm: '確定'
      },
      search: {
        placeholder: '輸入關鍵字',
        title: '搜尋文章',
        suggestedKeywords: '熱門搜尋',
        noResult: '未有相關的文章，請使用其他關鍵字重試。',
        noKeyword: '以關鍵字搜尋10Life文章',
        backToBlog: '返回網誌',
      },
      advisorProfile: '查看顧問簡介',
      errorPage: {
        message: '對不起，我們找不到相關的文章。<br />歡迎到訪<a href=\'/zh-HK/blog/\'>網誌</a>閱讀其他保險的資訊。<span style=\'display: inline-block\'>謝謝！</span>',
        ctaText: '回到主頁',
        meta: {
          title : '找不到文章',
          description : '對不起，我們找不到相關的文章。',
        }
      }
    },
    media: {
      filter: {
        all: '全部',
        year: '篩選年份'
      },
      press_contact: '傳媒查詢',
      gallery_title: '相關照片'
    },
    products_comparison: {
      add_to_compare: '產品比較​',
      already_added_to_compare: '已加入比較',
      go_to_compare: '立即比較',
      clear_all: '全部清除',
      select_products: '選擇產品',
      add_more_products: '比較更多產品',
      quit_comparison: '退出保險比較',
    },
    products: {
      insurer: "保險公司",
      targetPeriod: "目標保障期",
      gender: "性別",
      male: "男",
      female: "女",
      age: "年齡",
      yearUnit: "年",
      yearsUnit: "年",
      ageUnit: "歲",
      monthUnit: "個月",
      dayUnit: "日",
      to: "至",
      annualRenewal: "每年續保",
      paymentTerm: "供款年期",
      renewalTerm: "續保期",
      renewalUnit: "每$num年續保",
      underwriting: "核保方法",
      standardUnderwriting: "標準核保",
      onlineUnderwriting: "網上核保",
      sumAssured: "保額",
      advisorAssistance: "顧問協助",
      sort: "排序方式",
      compare: "產品比較​",
      enquiry: "WhatsApp 查詢",
      product_website: "產品網站",
      purchase_now: "立刻投保",
      user_review: " 個用戶評分",
      not_specified: "沒有列明",
      currency: "貨幣",
      ratingAssumption: "評分假設",
      back: "返回",
      any: "任何",
      prepaid: "1年或預繳",
      yearPremium: "年供款",
      yearAnnuity: "年領取期",
      whatsapp_enquiry_message: "Hello 10Life，我想查詢{replace_with_category} （{replace_with_insurer} | {replace_with_product_name}）",
      whatsapp_enquiry_message_blog: "我看到10Life文章 [ {replace_with_link} ]，我想查詢{replace_with_category} （{replace_with_insurer} | {replace_with_product_name}）",
      datePicker: {
        title: '旅程',
        placeholder: '請選擇旅程日期'
      },
      destination: "目的地",
      distributor: "供應商",
      productFeatures: "保單特色",
      show_product: "顯示產品",
      filter_selection: "全部篩選",
      sorting_option: "排序方法",



      medical: {
        md_categoryName: "住院醫保",
        md_pageTitle: "比較住院醫療保險",
        md_quickGuide: "住院保險懶人包",
        md_result: "我們找到$num個符合篩選條件的醫療保險產品！",
        md_forRenewal: "*只限於續保",
        md_10_yearAvgPremium: "10年平均年保費",
        md_10_yearAvgPremiumHKD: "10年平均年保費 (HKD)",
        md_10_yearAvgPremiumLowtoHigh: "10年平均年保費 (低至高)",
        md_5StarRatings: "5星評級",
        md_additionalCoverage: "額外保障",
        md_additionalFeatures: "額外保障",
        md_age: "年齡",
        md_ageRange: "$from-$to歲",
        md_anaesthetistsFee: "麻醉科醫生費",
        md_angioplastywithStent: "冠狀動脈成形術，程序包支架置入",
        md_arthroscopicAnteriorCruciateLigament: "以關節鏡作前十字韌帶重建手術",
        md_attendingDoctorsVisitFee: "主診醫生巡房費",
        md_averageAnnualPremium: "平均年保費",
        md_averageCoverageofCommonMedicalCases: "常見傷病平均賠償率",
        md_basePlanLimit: "基本計劃賠償上限",
        md_benefitLimitsBasePlan: "基本計劃的賠償上限",
        md_cancer_BreastCancerwithMastectomyReconstructionChemotherapyRadiotherapy: "癌症 - 乳癌，乳房切除、重建、化療及放射治療",
        md_cancer_ColonCancerwithSurgeryandChemotherapy: "癌症 - 腸癌，需接受腹腔鏡結腸切除術及化療",
        md_cancer_LiverCancerwithSurgeryandTargetedTherapy: "癌症 - 肝癌，需接受手術及標靶治療",
        md_cancer_LungCancerwithLobectomy: "癌症 - 肺癌，肺葉切除術",
        md_cancerAverageCoverage: "癌症預計平均保障",
        md_co_payment: "共同保險 (客戶自付額)",
        md_colonoscopyHemi_Colectomy: "結腸鏡檢查及結腸切除",
        md_colonoscopywithPolypectomy: "結腸鏡檢查，附帶息肉切除",
        md_commonCases_ColonoscopyDayCase: "非嚴重疾病 - 腸鏡檢查 (日間治療)",
        md_commonCases_FeverRequiringHospitalisation: "非嚴重疾病 - 發燒，需住院",
        md_commonCases_HemorrhoidSurgery: "非嚴重疾病 - 割痔瘡手術",
        md_coverageLimit: "賠償上限",
        md_deductible: "自付費",
        md_deductibleHKD: "自付費 (HKD)",
        md_estimatedCoverage: "預計賠償額",
        md_estimatedCoverageofselectedDiseases: "各傷病的預計保障",
        md_estimatedOutofPocket: "預計自付額",
        md_estimatedTotalBill: "預計醫療開支",
        md_firstYearPremium: "首年保費",
        md_firstYearPremiumLowtoHigh: "首年保費 (低至高)",
        md_fullCoveragePlan: "全數保障計劃",
        md_gender: "性別",
        md_generalLimit: "一般賠償上限",
        md_geographicalCoverage: "受保地區",
        md_geoRegionDefaultText: "任何",
        md_guaranteedNoBenefitReductionAppliedtoAllVHISplans: "保證保障不減少（適用於所有自願醫保計劃）",
        md_hemorrhoidSurgery: "痔瘡切除",
        md_highAnnualLimit: "高額全年賠償限額",
        md_homeNursing: "家中看護",
        md_hospicePalliativeCareCoverage: "善終服務保障 / 舒緩治療保障",
        md_hospitalCash: "住院現金",
        md_includeSMM: "附有額外醫療保障",
        md_injury_AnteriorCruciateLigamentSurgery: "受傷 - 前十字韌帶手術",
        md_injury_TotalKneeReplacement: "受傷 - 全膝置換術",
        md_insurer: "保險公司",
        md_intensiveCare: "深切治療",
        md_internationalAssistanceService: "環球醫療援助服務",
        md_intersiveCare: "深切治療",
        md_key: "最多日數",
        md_lifetimeLimit: "終身限額",
        md_limit: "賠償上限",
        md_limits: "賠償上限",
        md_long_termPostHospitalisationOutpatientCover: "住院後長期門診保障",
        md_maximumNumbersofDays: "最多日數",
        md_medicalCoverageScore: "醫療保障評分",
        md_medicalInflationProtection: "抗醫療通脹",
        md_miscellaneousCharges: "雜項開支",
        md_mostPopularHightoLow: "最受歡迎 (高至低)",
        md_multipleSurgery_2ndSurgeryPayment: "多樣手術 - 第2個手術之賠償%",
        md_noLifetimeLimit: "不設終身限額",
        md_oneorBothofTheFollowing2Conditions: "滿足以下其中一項",
        md_operatingTheatreCharges: "手術室費",
        md_otherCoverage: "其他保障",
        md_outpatientDialysis: "日間血液透析",
        md_overview: "概括",
        md_perDay: "每日",
        md_physiotherapyPost_confinement: "出院後物理治療",
        md_planType: "計劃種類",
        md_pre_andPost_ConfinementDayCaseProcedureOutpatientCare: "入院前或出院後/日間手術前後的門診護理",
        md_pregnancyComplicationsCoverage: "妊娠併發症",
        md_prescribedDiagnosticImagingTests: "訂明診斷成像檢測",
        md_prescribedNon_surgicalCancerTreatments: "訂明非手術癌症治療",
        md_psychiatricTreatments: "精神科治療",
        md_rightLobectomyofLung: "右肺葉切除術",
        md_rightRadicalMastectomy: "跟治性右乳房切除術",
        md_roomandboard: "病房及膳食",
        md_roomType: "房間類型",
        md_roomTypeDefaultText: "普通房",
        md_secondMedicalOpinionService: "第二醫療意見服務",
        md_segmentectomyofLiver: "開放式或腹腔鏡式肝葉切除術",
        md_shortWaitingPeriod: "短等候期",
        md_sMMCo_pay: "額外醫療保障自付額",
        md_sMMGeneralLimits: "額外醫療保障上限",
        md_sMMLifetimeLimits: "額外醫療終身賠償上限",
        md_specialistsFee: "專科醫生費",
        md_supplementaryMajorMedicalBenefits: "額外醫療保障的賠償上限",
        md_surgeonFees_MaximumLimitPerSurgery: "最高程度的外科手術醫生費上限",
        md_surgeonsFee: "外科醫生費",
        md_surgeonsFees: "外科醫生費",
        md_totalKneeReplacement: "全膝置換術",
        md_userRatingHightoLow: "用戶評分 (高至低)",
        md_vascularDiseases_AngioplastyandStentPlacement: "血管疾病 - 通波仔，置入支架",
        md_vascularDiseases_KidneyDialysis: "血管疾病 - 需日間血液透析（洗血）",
        md_vascularDiseases_Stroke: "血管疾病 - 中風",
        md_waiveronDeductibleforCertainCriticalCases: "豁免自付費–指定危疾",
        md_overall_score: "10Life評分",
        md_special_feature_score: "特色保障評分",
        md_special_feature: "特色保障",
        md_medicalInflationProtection_indicator: "與抗醫療通脹相關",
        md_noItemizedSubLimit: "不設分項限額",
        md_additionalCoverage_indicator: "與額外保障相關​",
        md_deductibleWaiverOnCancer: "轄免自付費–癌症",
        md_deductibleWaiverOnStrokeAndHeartDisease: "轄免自付費–中風及心臟疾病",
        md_deductibleWaiverOnDiabetes: "轄免自付費–糖尿病併發症、腎衰竭及器官移植",
        md_deductibleWaiverOnOtherIllness: "轄免自付費–其他指定嚴重疾病",
        md_secondClaimCashAllowance: "第二索償現金津貼",
        md_hassleFreeArrangement_indicator: "與無憂安排相關​",
        md_cashlessArrangement: "免找數服務",
        md_wellnessAndOthers_indicator: "其他保障",
        md_freeBodyCheck: "基本身體檢查​",
        md_numberOfPolicyYearForFreeBodyCheck: "於第幾個保單年度可獲首次免費基本身體檢查",
        md_frequencyOfFreeBodyCheck: "其後免費身體檢查的頻次​",
        md_availabilityOfNoClaimBonus: "無索償折扣",
        md_availabilityOfEClaim: "網上索償",
        md_applyOnlineInsurer: "經保險公司網上投保",
        md_experimentalCancerTreatment: "臨床試驗藥物保障/賠償",
        md_emergencyDentalBenefit: "緊急牙齒治療費用賠償",
        md_shortWaitingPeriod: "短等候期",
        md_personalCaseMgt: "個人療程復康管理服務",
        md_healthHotline: "健康服務熱線",
        md_mentalHealthHotline: "情緒支援服務熱線",
        md_psychiatricCounsellingOutpatient: "臨床心理輔導門診保障",
        md_claimsRelated_indicator: "與索償安排相關",
        md_adjDeductibleWithoutReUnderwriteSpecificAge: "可於指定歲數免核保降低墊底費",
        md_donorBenefits: "捐贈者保障",
        md_compassionateDeathBenefit: "恩恤身故賠償",
        md_surgeonsFeeRemarks: "每項手術，按手術表劃分的手術分類"
      },
      termLife: {
        tl_categoryName: "定期人壽",
        tl_pageTitle: "比較定期人壽保險",
        tl_quickGuide: "定期人壽保險懶人包",
        tl_result: "我們找到$num個符合篩選條件的定期人壽保險產品！",
        tl_insurer: "保險公司",
        tl_targetPeriod: "目標保障期",
        tl_gender: "性別",
        tl_age: "年齡",
        tl_renewalTerm: "續保期",
        tl_renewalUnit: "每$num年續保",
        tl_underwriting: "核保方法",
        tl_sumAssured: "保額",
        tl_10LifeRating: "10Life評分",
        tl_firstYearPremium: "首年保費",
        tl_YearAveragePremium: "年平均保費",
        tl_termsScore: "條款評分",
        tl_overview: "概括",
        tl_10_YearDeathBenefitScore: "10年身故賠償評分",
        tl_20_YearDeathBenefitScore: "20年身故賠償評分",
        tl_10_YearAverageAnnualPremium: "10年平均年保費",
        tl_20_YearAverageAnnualPremium: "20年平均年保費",
        tl_issueAge: "投保年齡",
        tl_lastCoverageAge: "最高保障年齡",
        tl_maximumSumAssured: "最高投保額",
        tl_onlinePurchase: "網上購買",
        tl_premium: "每年保費",
        tl_YearPremium: "第$num年保費",
        tl_1stYearPremium: "首年保費",
        tl_2ndYearPremium: "第2年保費",
        tl_3rdYearPremium: "第3年保費",
        tl_4thYearPremium: "第4年保費",
        tl_5thYearPremium: "第5年保費",
        tl_6thYearPremium: "第6年保費",
        tl_7thYearPremium: "第7年保費",
        tl_8thYearPremium: "第8年保費",
        tl_9thYearPremium: "第9年保費",
        tl_10thYearPremium: "第10年保費",
        tl_11thYearPremium: "第11年保費",
        tl_12thYearPremium: "第12年保費",
        tl_13thYearPremium: "第13年保費",
        tl_14thYearPremium: "第14年保費",
        tl_15thYearPremium: "第15年保費",
        tl_16thYearPremium: "第16年保費",
        tl_17thYearPremium: "第17年保費",
        tl_18thYearPremium: "第18年保費",
        tl_19thYearPremium: "第19年保費",
        tl_20thYearPremium: "第20年保費",
        tl_coverageafterAChangeofResidency: "居住地變更到海外的人壽保障",
        tl_coverageunderTheFollowingDeathCauses: "以下身故原因的人壽保障",
        tl_suicide: "自殺",
        tl_hIVandAIDS: "HIV及愛滋病",
        tl_illegalActivities: "非法活動",
        tl_drugorAlcoholOverdoseorInfluence: "過量服用毒品、酒精",
        tl_armedForcesParticipation: "武裝部隊",
        tl_nuclearBiologicalandChemicalActivities: "核、生物及化學活動",
        tl_warandTerrorism: "戰爭及恐怖主義",
      },
      longSavings: {
        ls_categoryName: "長期儲蓄​",
        ls_pageTitle: "比較長期儲蓄保險",
        ls_quickGuide: "長期儲蓄保險懶人包",
        ls_result: "我們找到$num個符合篩選條件的長期儲蓄保險！",
        ls_yearpayment: "年供款",
        ls_10thPolicyYear: "第10年",
        ls_20thPolicyYear: "第20年",
        ls_30thPolicyYear: "第30年",
        ls_accumulatedDividendandInterest: "累積週年紅利及利息",
        ls_accumulatedReversionaryBonus: "累積歸原紅利",
        ls_age: "年齡",
        ls_aggressiveSavingsScore: "進取型儲蓄評分",
        ls_benefitmultiplier: "賠償倍數",
        ls_bonusPhilosophy_SharingofProfit: "分紅理念(%) - 盈利分享",
        ls_cashValueSplit: "現金價值分佈",
        ls_conservativeSavingsScore: "保守型儲蓄評分",
        ls_currency: "貨幣",
        ls_deathBenefit: "身故賠償",
        ls_equity_typeSecurities: "股票權重",
        ls_fixed_incomeSecurities: "債券權重",
        ls_gender: "性別",
        ls_growthSavingsScore: "儲蓄評分",
        ls_guaranteedCashValue: "保證現金價值",
        ls_guaranteedDeathBenefit: "保證身故賠償",
        ls_guaranteedIRR: "保證IRR",
        ls_guaranteedReturnRatio: "保證回報比率",
        ls_guaranteedReturnRatioHightoLow: "保證回報比率 (高至低)",
        ls_insurer: "保險公司",
        ls_internalRateofReturnIRR: "內部回報率 (IRR)",
        ls_mostPopularHightoLow: "最受歡迎 (高至低)",
        ls_overview: "概括",
        ls_paymentTermYearly: "供款年期",
        ls_policyYear: "保單週年",
        ls_projectedCashValueBase: "預期現金價值 (基本情景)",
        ls_projectedCashValuePessimistic: "預期現金價值 (悲觀情景)",
        ls_projectedIRRBase: "預期IRR (基本情景)",
        ls_projectedIRRPessimistic: "預期IRR (悲觀情景)",
        ls_projectedReturnBase: "預期IRR",
        ls_projectedReturnPessimistic: "預期IRR (悲觀)",
        ls_projectedReturnRatioBase: "預期回報比率",
        ls_projectedReturnRatioBaseHightoLow: "預期回報比率 (高至低)",
        ls_projectedReturnRatioPessimistic: "預期回報比率 (悲觀情景)",
        ls_projectedReturnRatioPessimisticHightoLow: "預期回報比率 (悲觀情景) (高至低)",
        ls_return: "回報",
        ls_riskPreference: "風險取向",
        ls_targetAssetAllocation: "目標資產組合",
        ls_targetPeriod: "目標年期",
        ls_terminalDividend: "終期紅利",
        ls_toPolicyholders: "保單持有人",
        ls_toShareholders: "股東",
        ls_totalCashValue: "總現金價值",
        ls_totalPremium: "總供款",
        ls_totalPremiumPaid: "已繳付保費總額",
        ls_userRatingHightolow: "用戶評分 (高至低)",
        ls_conservative: "保守型",
        ls_growth: "增長",
        ls_aggressive: "進取型",
        ls_prepaid: "預繳保費",
        ls_specialFeatures: "保單特色",
        ls_notSpecified: "沒有指定",
        ls_multiCurrency: "多元貨幣計劃",
        ls_transferOfInsured: "更換受保人",
        ls_simplifiedUnderwriting: "簡易核保",
        ls_longTermSavingsScore: "10Life評分",
        ls_overallScore: "10Life評分",
        ls_insurerDividendFulfillmentScore: "公司紅利實現評分",
        ls_5StarRatings: "5星評級",
        ls_insurerFulfillmentRatios: "分紅實現詳情",
        ls_insurersFulfillmentScore: "公司紅利實現評分",
        ls_fulfillmentRatioStatisticsPolicyYear6orAbove: "詳情（保單年期6年或以上）",
        ls_fulfillmentRatioStatisticsAllPolicyYear: "詳情（任何保單年期）",
        ls_dataPoint: "數據點",
        ls_averageFulfillmentRatiosPolicyYear6orAbove: "紅利實現率平均值（保單年期6年或以上）",
        ls_averageFulfillmentRatios: "紅利實現率平均值",
        ls_proportionOfFulfillmentRatiosWhichAreHigherThan70: "紅利實現率高於70%的比例",
        ls_policyEnded: "保單已完結",
        ls_riskLevel: "風險",
        ls_stable: "穩定",
        ls_returnRatioCashValueOverTotalPremiumPaid: "回報比率 (現⾦價值與總繳保費的比率)",
        ls_projectedReturnRatioBaseInDetail: "預期回報比率 (基本情景)",
        ls_cashValue: "現金價值",
        ls_accumulatedDividendAndInterestOverProjectedCashValueBase: "累積週年紅利佔總現金價值的比例",
        ls_accumulatedReversionaryBonusOverProjectedCashValueBase: "累積歸原紅利佔總現金價值的比例",
        ls_terminalDividendOverProjectedCashValueBase: "終期紅利佔總現金價值的比例",
        ls_notApplicale: "沒有提供",
      },
      shortSavings: {
        st_categoryName: "短期儲蓄",
        st_pageTitle: "比較短期儲蓄保險",
        st_quickGuide: "短期儲蓄保險懶人包",
        st_result: "我們找到<strong>$num</strong>個符合篩選條件的短期儲蓄保險！",
        st_insurer: "保險公司",
        st_currency: "貨幣",
        st_paymentTermYearly: "供款年期",
        st_yearpayment: "年供款",
        st_yearmaturity: "年期滿",
        st_maturity: "期滿",
        st_shortTermSavingsScore: "10Life評分",
        st_guaranteedBreakevenShorttoLong: "保證回本期 (快至慢)",
        st_guaranteedReturnRatioHightoLow: "第$num年保證回報比率 (高至低)",
        st_guaranteedIRRHightoLow: "第$num年內部回報率 (高至低)",
        st_mostPopularHightoLow: "最受歡迎 (高至低)",
        st_userRatingHightoLow: "用戶評分 (高至低)",
        st_guaranteedBreakeven: "保證回本期",
        st_targetPeriodGuaranteedIRR: "第$num年保證IRR",
        st_guaranteedReturnRatio: "第$num年保證回報比率",
        st_guaranteedIRRatyr1surrender: "首年退保保證IRR",
        st_premiumTerm: "供款年期",
        st_policyTerm: "保障年期",
        st_issueAge: "投保年齡",
        st_minimumTotalPremium: "最低全期供款",
        st_maximumTotalPremium: "最高全期供款",
        st_onlinePurchase: "網上投保",
        st_deathBenefit: "身故賠償",
        st_overview: "概括",
        st_guaranteedReturn: "保證回報",
        st_guaranteedIRREarlySurrenderoratMaturity: "保證內部回報率(IRR) (退保或期滿)",
        st_1Year: "1 年",
        st_2Year: "2 年",
        st_3Year: "3 年",
        st_4Year: "4 年",
        st_5Year: "5 年",
        st_guaranteedReturnRatioGuaranteedBenefitPremiumPaid: "保證回報比率 (= 保證收入 / 已繳保費)",
        st_gbey_immediate: '即時',
        st_targetPeriod: '目標年期',
      },
      wlci: {
        wlci_categoryName: "終身危疾",
        wlci_pageTitle: "比較終身危疾保險",
        wlci_quickGuide: "終身危疾保險懶人包",
        wlci_result: "我們找到<strong>$num</strong>個符合篩選條件的終身危疾保險！",
        wlci_insurer: "保險公司",
        wlci_gender: "性別",
        wlci_age: "年齡",
        wlci_smokerStatus: "吸煙狀況",
        wlci_smoker: "吸煙",
        wlci_nonSmoker: "非吸煙",
        wlci_3major: "多次危疾（三大疾病）",
        wlci_specific: "多次危疾（指定疾病）",
        wlci_all: "多次危疾（所有疾病）",
        wlci_multipleClaims: "多次危疾",
        wlci_currency: "貨幣",
        wlci_sumAssured: "保額",
        wlci_multipleCI: "多次危疾保障",
        wlci_5StarRatings: "5星評級",
        wlci_cICoverageScoreHightoLow: "條款評分 (高至低)",
        wlci_firstYearPremiumLowtoHigh: "首年保費 (低至高)",
        wlci_10yearAvgPremiumLowtoHigh: "10年平均年保費 (低至高)",
        wlci_mostPopularHightoLow: "最受歡迎 (高至低)",
        wlci_userRatingHightoLow: "用戶評分 (高至低)",
        wlci_wholeLifeCICoverageScore: "終身危疾保障評分",
        wlci_overallScore: "10Life評分",
        wlci_termsScore: "條款評分",
        wlci_premiumTermYears: "供款年期",
        wlci_annualPremium: "每年保費",
        wlci_firstYearPremium: "首年保費",
        wlci_overview: "概括",
        wlci_coverageScore: "保障評分",
        wlci_paymentTermYears: "供款年期",
        wlci_numberofCoveredMajorIllnesses: "涵蓋嚴重危疾數目",
        wlci_numberofCoveredEarlyIllnesses: "涵蓋早期危疾數目",
        wlci_deathBenefit: "身故賠償",
        wlci_maximumPayoutBasicSumAssured: "最高賠償百分比 (基本保額)",
        wlci_waitingPeriod: "等候期",
        wlci_issueAge: "投保年齡",
        wlci_cICoverage: "危疾保障",
        wlci_cITerms: "危疾條款",
        wlci_return: "回報",
        wlci_singleCICoverageScore: "單次危疾保障評分",
        wlci_cancerPayoutBasicSumAssured: "癌症賠償百分比 （基本保額）",
        wlci_earlyCancerCarcinomainsituPayoutBasicSumAssured: "早期癌症 – 原位癌 賠償百分比 （基本保額）",
        wlci_heartDiseasePayoutBasicSumAssured: "心臟病賠償百分比 （基本保額）",
        wlci_earlyHeartDiseaseTreatmentAngioplastyPayoutBasicSumAssured: "早期心臟疾病治療 - 通波仔 賠償百分比 （基本保額）",
        wlci_strokePayoutBasicSumAssured: "中風賠償百分比 （基本保額）",
        wlci_protectionAmountBooster: "增額保障",
        wlci_coverUnknownDiseases: "為未知疾病提供保障",
        wlci_multipleCICoverageScore: "多次危疾保障評分",
        wlci_continuousCancerCashBenefit: "持續癌症現金津貼",
        wlci_maximumPayouton5YearCancerTreatmentPeriodBasicSumAssured: "最高賠償百分比（癌症5年治療期）",
        wlci_maximumPayouton20YearCancerTreatmentPeriodBasicSumAssured: "最高賠償百分比（癌症20年治療期）",
        wlci_maximumPayouton3xHeartDiseaseBasicSumAssured: "最高賠償百分比（患上心臟病3次）",
        wlci_maximumPayouton2xHeartDiseaseand1xStrokeBasicSumAssured: "最高賠償百分比（患上心臟病2次及中風1次）",
        wlci_maximumPayouton2xStrokeand1xHeartDiseaseBasicSumAssured: "最高賠償百分比（患上中風2次及心臟病1次）",
        wlci_maximumPayouton3xStrokeBasicSumAssured: "最高賠償百分比（患上中風3次）",
        wlci_otherMajorCISpecificDiseases: "其他嚴重危疾保障 - 指定危疾",
        wlci_otherMajorCIAllDiseases: "其他嚴重危疾保障 - 所有危疾",
        wlci_cancerTerms: "癌症條款",
        wlci_noofEarlyCancerCarcinomainsituPayout: "早期癌症 – 原位癌 賠償次數",
        wlci_cancerNoVagueExclusionBorderlineMalignancyorLowMalignantPotential: "癌症：無含糊定義來不保（交界性或低惡性潛力的腫瘤）",
        wlci_cancerNoVagueDefinitionNonlifeThreateningCancers: "癌症：無含糊定義來不保（非致命的癌症）",
        wlci_recurrenceCancerWaitingPeriod: "復發癌等候期",
        wlci_newCancerWaitingPeriod: "新癌症等候期",
        wlci_continuousCancerWaitingPeriod: "持續性癌症等候期",
        wlci_heartandStrokeTerms: "心臟病及中風條款",
        wlci_noofEarlyHeartDiseaseTreatmentAngioplastyPayout: "早期心臟疾病治療 - 通波仔 賠償次數",
        wlci_clearDefinitionofCoronaryArteryDiseaseCADwithNoSpecifiedSurgeriesorProceduresRequired: "清晰界定冠心病，無要求受保人進行指定手術或療程",
        wlci_angioplastyElectrocardiogramECGChangesNotRequired: "血管成形術（通波仔）：無須心電圖作醫學證明",
        wlci_strokeDoesNotSpecificallyExcludeAccidentsTraumaorTraumaticInjuryInducedStroke: "中風：無將「意外及創傷性腦受損所引致的中風」當作不保",
        wlci_strokeDoesNotSpecificallyExcludeMigraineInducedStroke: "中風：無將「偏頭痛所引致的中風」當作不保",
        wlci_strokeDoesNotSpecificallyExcludeVasculitisorInfectionInducedStroke: "中風：無將「血管炎及感染所引致的中風」當作不保",
        wlci_coversonlyHeartAttackunderMultipleCIHeartDiseaseCoverage: "心臟危疾多重賠償只限於嚴重心臟病",
        wlci_generalTerms: "一般條款",
        wlci_noDeductionfromMajorPayoutafterEarlystagePayout: "早期危疾賠償不會預支原保額",
        wlci_ageLimitforMultipleCICover: "多重危疾保障年齡上限",
        wlci_guaranteedLevelPremium: "全期保費保證不變",
        wlci_guaranteedReturnRatio: "保證回報比率",
        wlci_10thPolicyYear: "第10年",
        wlci_20thPolicyYear: "第20年",
        wlci_30thPolicyYear: "第30年",
        wlci_projectedReturnRatio: "預期回報比率",
        wlci_nolimit: "不設上限",
        wlci_yearpayment: "年供款",
      },
      termci: {
        tci_categoryName: "定期危疾",
        tci_pageTitle: "比較定期危疾保險",
        tci_quickGuide: "定期危疾保險懶人包",
        tci_result: "我們找到<strong>$num</strong>個符合篩選條件的定期危疾保險！",
        tci_insurer: "保險公司",
        tci_gender: "性別",
        tci_age: "年齡",
        tci_cICoverage: "危疾保障",
        tci_renewalTerm: "續保期",
        tci_10yearAvgPremiumHKD: "10年平均年保費",
        tci_underwritingMethod: "核保方法",
        tci_sumAssured: "保額",
        tci_termCIScore: "10Life評分",
        tci_5StarRatings: "5星評級",
        tci_firstYearPremiumLowtoHigh: "首年保費 (低至高)",
        tci_10yearAvgPremiumLowtoHigh: "10年平均年保費 (低至高)",
        tci_mostPopularHightoLow: "最受歡迎 (高至低)",
        tci_userRatingHightoLow: "用戶評分 (高至低)",
        tci_firstYearPremium: "首年保費",
        tci_10yearAvgPremium: "10年平均年保費",
        tci_overview: "概括",
        tci_cancer: "癌症",
        tci_cancerCoverage: "癌症保障",
        tci_heart: "心臟病",
        tci_heartCoverage: "心臟病保障",
        tci_stroke: "中風",
        tci_strokeCoverage: "中風保障",
        tci_otherCriticalIllness: "其他危疾",
        tci_otherCriticalIllnessCoverage: "其他危疾保障",
        tci_generalTermsScore: "一般條款評分",
        tci_numberofCoveredMajorIllnesses: "涵蓋嚴重危疾數目",
        tci_numberofStandardCriticalIllnessesCovered: "涵蓋建議嚴重危疾數目",
        tci_issueAge: "投保年齡",
        tci_maximumCoveredAge: "最高受保年齡",
        tci_onlinePurchase: "網上投保",
        tci_cancerCoverageScore: "癌症評分",
        tci_heartCoverageScore: "心臟病評分",
        tci_strokeCoverageScore: "中風評分",
        tci_otherCriticalIllnessCoverageScore: "其他危疾保障評分",
        tci_premium: "保費",
        tci_cancerScore: "癌症評分",
        tci_cancerTerms: "癌症條款",
        tci_noVagueExclusionNonLifeThreateningCancers: "無含糊定義來不保：非致命的癌症",
        tci_payoutofMajorCancerinSumAssured: "癌症賠償保額比率",
        tci_colorectumCancer: "大腸癌",
        tci_lungCancer: "肺癌",
        tci_breastCancer: "乳腺癌",
        tci_prostateCancerExcludeT1aT1bT1cExclusion: "前列腺癌 (不包括T1a、T1b和T1c癌症分級)",
        tci_liverCancer: "肝癌",
        tci_stomachCancer: "胃癌",
        tci_nonmelanomaSkinCancer: "非黑色素瘤皮膚癌",
        tci_corpusUteriCancer: "子宮體癌",
        tci_nonHodgkinLymphomaCancer: "非霍奇金淋巴瘤癌",
        tci_thyroidCancerExcludeT1N0M0Exclusion: "甲狀腺癌 (不包括T1N0M0癌症分級)",
        tci_payoutofEarlyCancerinSumAssured: "早期癌症賠償保額比率",
        tci_carcinomainsitu: "原位癌",
        tci_heartScore: "心臟病評分",
        tci_heartTerms: "心臟病條款",
        tci_cleardefinitionofCoronaryArteryDiseaseCADwithNoSpecifiedSugeriesorProceduresRequired: "清晰界定冠心病，無要求受保人進行指定手術或療程",
        tci_coversHeartDiseasesotherthanHeartAttack: "保障多種心臟和血管相關危疾",
        tci_payoutofEarlyHeartDiseaseinSumAssured: "早期心臟病賠償保額比率",
        tci_angioplasty: "通波仔",
        tci_strokeScore: "中風評分",
        tci_strokeTerms: "中風條款",
        tci_doesNotSpecificallyExcludeAccidentsTraumaorTraumaticInjuryInducedStroke: "無將「意外及創傷性腦受損所引致的中風」當作不保",
        tci_doesNotSpecificallyExcludeMigraineInducedStroke: "無將「偏頭痛所引致的中風」當作不保",
        tci_doesNotSpecificallyExcludeVasculitisorInfectionInducedStroke: "無將「血管炎及感染所引致的中風」當作不保",
        tci_notRequiredSurvivalPeriodafterDiagnosis: "沒有存活期要求(確診危疾後)",
        tci_fullcoverageforMajorCIAfterEarlyCIclaim: "早期危疾後確診主要危疾全額保障",
        tci_secondCICoverage: "第二次危疾保障",
        tci_secondCancer: "第二次癌症保障",
        tci_secondHeart: "第二次心臟病保障",
        tci_secondStroke: "第二次中風保障",
        tci_secondOtherCriticalIllness: "第二次其他嚴重保障",
        tci_10YearAverageAnnualPremium: "10年平均年保費",
        tci_1stYearPremium: "首年保費",
        tci_2ndYearPremium: "第2年保費",
        tci_3rdYearPremium: "第3年保費",
        tci_4thYearPremium: "第4年保費",
        tci_5thYearPremium: "第5年保費",
        tci_6thYearPremium: "第6年保費",
        tci_7thYearPremium: "第7年保費",
        tci_8thYearPremium: "第8年保費",
        tci_9thYearPremium: "第9年保費",
        tci_10thYearPremium: "第10年保費",
      },
      termcancer: {
        tcr_categoryName: "定期癌症保險",
        tcr_pageTitle: "比較定期癌症保險",
        tcr_quickGuide: "定期癌症保險懶人包",
        tcr_result: "我們找到<strong>$num</strong>個符合篩選條件的定期癌症保險！",
        tcr_insurer: "保險公司",
        tcr_gender: "性別",
        tcr_age: "年齡",
        tcr_renewalTerm: "續保期",
        tcr_renewalUnit: "每$num年續保",
        tcr_underwritingMethod: "核保方法",
        tcr_sumAssured: "保額",
        tcr_termCancerScore: "10Life評分",
        tcr_firstYearPremiumLowtoHigh: "首年保費 (低至高)",
        tcr_avgPremiumLowtoHigh: "平均年保費 (低至高)",
        tcr_mostPopularHightoLow: "最受歡迎 (高至低)",
        tcr_userRatingHightoLow: "用戶評分 (高至低)",
        tcr_earlyCancerCoverage: "早期癌症保障",
        tcr_firstYearPremium: "首年保費",
        tcr_tenYearAvgPremium: "10年平均年保費",
        tcr_overview: "概括",
        tcr_issueAge: "投保年齡",
        tcr_maximumCoveredAge: "最高受保年齡",
        tcr_onlinePurchase: "網上投保",
        tcr_cancerCoverageScore: "癌症評分",
        tcr_premium: "保費",
        tcr_cancerScore: "癌症評分",
        tcr_cancerTerms: "癌症條款",
        tcr_noVagueExclusionNonLifeThreateningCancers: "無含糊定義來不保：非致命的癌症",
        tcr_continuousCancerTreatmentBenefit: "持續癌症治療保障",
        tcr_fullCoverageforCancerafterCISClaim: "原位癌後確診主要癌症全額保障",
        tcr_notRequiredSurvivalPeriodafterDiagnosis: "沒有存活期要求(確診癌症後)",
        tcr_payoutofMajorCancerinSumAssured: "癌症賠償保額比率",
        tcr_colorectumCancer: "大腸癌",
        tcr_lungCancer: "肺癌",
        tcr_breastCancer: "乳腺癌",
        tcr_prostateCancerExcludeT1aT1bT1cExclusion: "前列腺癌 (不包括T1a、T1b和T1c癌症分級)",
        tcr_liverCancer: "肝癌",
        tcr_stomachCancer: "胃癌",
        tcr_nonmelanomaSkinCancer: "非黑色素瘤皮膚癌",
        tcr_corpusUteriCancer: "子宮體癌",
        tcr_nonHodgkinLymphomaCancer: "非霍奇金淋巴瘤癌",
        tcr_thyroidCancerExcludeT1N0M0Exclusion: "甲狀腺癌 (不包括T1N0M0癌症分級)",
        tcr_payoutofEarlyCancerinSumAssured: "早期癌症賠償保額比率",
        tcr_carcinomainsitu: "原位癌",
        tcr_10yearAvgPremium: "平均年保費",
        tcr_10YearAverageAnnualPremium: "10年平均年保費",
        tcr_1stYearPremium: "首年保費",
        tcr_2ndYearPremium: "第2年保費",
        tcr_3rdYearPremium: "第3年保費",
        tcr_4thYearPremium: "第4年保費",
        tcr_5thYearPremium: "第5年保費",
        tcr_6thYearPremium: "第6年保費",
        tcr_7thYearPremium: "第7年保費",
        tcr_8thYearPremium: "第8年保費",
        tcr_9thYearPremium: "第9年保費",
        tcr_10thYearPremium: "第10年保費",
        tcr_earlyCancer: "早期癌症保障",
      },
      annuity: {
        as_categoryName: "扣稅年金",
        as_pageTitle: "比較扣稅年金",
        as_quickGuide: "年金懶人包",
        as_result: "我們找到<strong>$num</strong>個符合篩選條件的扣稅年金！",
        as_objective: "年金目標",
        as_issueAge: "投保年齡",
        as_paymentTermYearly: "供款年期",
        as_annuityStartAge: "年金開始年齡",
        as_incomeTerm: "年金領取期",
        as_guaranteedBenefitPerPremiumPaidAtMaturity: "期滿保證回本率",
        as_totalPremium: "總供款",
        as_currency: "貨幣",
        as_insurer: "保險公司",
        as_savingsAnnuityScore: "10Life評分",
        as_guaranteedInitialAnnualIncomeHightoLow: "首年保證年金 (高至低)",
        as_5StarRatings: '5星評級',
        as_guaranteedReturnRatioatAge60HightoLow: "60歲保證回報比率 (高至低)",
        as_projectedReturnRatioatAge60HightoLow: "60歲預期回報比率 (高至低)",
        as_guaranteedBreakevenYearShorttoLong: "保證回本年 (短至長)",
        as_mostPopularHightoLow: "最受歡迎 (高至低)",
        as_userRatingHightoLow: "用戶評分 (高至低)",
        as_guaranteedInitialAnnualIncome: "首年保證年金",
        as_guaranteedReturnRatioatAge60: "60歲保證回報比率",
        as_projectedReturnRatioatAge60: "60歲預期回報比率",
        as_guaranteedBreakevenYear: "保證回本年",
        as_10LifeScenario: "概括",
        as_productFacts: "產品資料",
        as_guaranteedReturn: "保證回報",
        as_projectedReturn: "預期回報",
        as_earlySurrenderCoverage: "早期退保保障比率(保證)",
        as_firstYearPremium: "首年供款",
        as_premiumTerm: "供款期",
        as_totalPremiumPaid: "總供款",
        as_projectedInitialAnnualIncome: "首年預期年金",
        as_percentageofTotalIncomeBeingGuaranteed: "保證入息佔總入息的百分比",
        as_currencyChoice: "保單貨幣",
        as_guaranteedInternalRateofReturnIRR: "保證內部回報率(IRR)",
        as_guaranteedInternalRateofReturnIRRWith17TaxSavings: "保證內部回報率(IRR)以17%扣稅率計算",
        as_totalGuaranteedIncomeAge: "$num歲",
        as_totalGuaranteedIncomeAge4549: "45 - 49歲保證收入總額",
        as_totalGuaranteedIncomeAge5054: "50 - 54歲保證收入總額",
        as_totalGuaranteedIncomeAge5559: "55 - 59歲保證收入總額",
        as_totalGuaranteedIncomeAge6064: "60 - 64歲保證收入總額",
        as_totalGuaranteedIncomeAge6569: "65 - 69歲保證收入總額",
        as_totalGuaranteedIncomeAge5059: "50-59歲保證年金總收入",
        as_totalGuaranteedIncomeAge6069: "60-69歲保證年金總收入",
        as_totalGuaranteedIncomeAge7079: "70-79歲保證年金總收入",
        as_totalGuaranteedIncomeAge8089: "80-89歲保證年金總收入",
        as_totalGuaranteedIncomeAge9099: "90-99歲保證年金總收入",
        as_projectedBreakevenYear: "預期回本年",
        as_projectedInternalRateofReturnIRR: "預期內部回報率(IRR)",
        as_surrenderatage: "於$num歲時退保",
        as_projectedInternalRateofReturnIRRwith17TaxSavings: "預期內部回報率(IRR)以17%扣稅率計算",
        as_totalProjectedIncomeAge: "$num歲",
        as_totalProjectedIncomeAge4549: "45 - 49歲預期收入總額",
        as_totalProjectedIncomeAge5054: "50 - 54歲預期收入總額",
        as_totalProjectedIncomeAge5559: "55 - 59歲預期收入總額",
        as_totalProjectedIncomeAge6064: "60 - 64歲預期收入總額",
        as_totalProjectedIncomeAge6569: "65 - 69歲預期收入總額",
        as_totalProjectedIncomeAge5059: "50-59歲預期年金總收入",
        as_totalProjectedIncomeAge6069: "60-69歲預期年金總收入",
        as_totalProjectedIncomeAge7079: "70-79歲預期年金總收入",
        as_totalProjectedIncomeAge8089: "80-89歲預期年金總收入",
        as_totalProjectedIncomeAge9099: "90-99歲預期年金總收入",
        as_guaranteedBenefitPerPremiumPaidIncludingCashValueReceivedIncomeCashValuePremiumPaid: "保證回本率: (已收取入息 + 現金價值) ÷ 已付保費",
        as_surrenderatThe5thPolicyYear: "於投保後5年退保",
        as_surrenderatThe10thPolicyYear: "於投保後10年退保",
        as_surrenderatThe20thPolicyYear: "於投保後20年退保",
        as_surrenderatTheNumthPolicyYear: "於投保後$num年退保",
        as_annuityStart: "年金開始",
        as_annuityEnd: "保單已完結",
        ar_issueAge: "投保年齡",
        ar_paymentTermYearly: "供款年期",
        ar_annuityStartAge: "年金開始年齡",
        ar_incomeTerm: "年金領取期",
        ar_totalPremium: "總供款",
        ar_currency: "貨幣",
        ar_insurer: "保險公司",
        ar_retirementAnnuityScore: "10Life評分",
        ar_guaranteedInitialAnnualIncomeHightoLow: "首年保證年金 (高至低)",
        ar_guaranteedReturnRatioatAgeHightoLow: "$num歲保證回報比率 (高至低)",
        ar_guaranteedReturnRatioatAge75HightoLow: "75歲保證回報比率 (高至低)",
        ar_guaranteedReturnRatioatAge85HightoLow: "85歲保證回報比率 (高至低)",
        ar_projectedReturnRatioatAgeHightoLow: "$num歲預期回報比率 (高至低)",
        ar_projectedReturnRatioatAge75HightoLow: "75歲預期回報比率 (高至低)",
        ar_projectedReturnRatioatAge85HightoLow: "85歲預期回報比率 (高至低)",
        ar_guaranteedBreakevenYearShorttoLong: "保證回本年 (快至慢)",
        ar_mostPopularHightoLow: "最受歡迎 (高至低)",
        ar_userRatingHightoLow: "用戶評分 (高至低)",
        ar_guaranteedReturnRatioatAge: "$num歲保證回報比率",
        ar_guaranteedReturnRatioatAge75: "75歲保證回報比率",
        ar_guaranteedReturnRatioatAge85: "85歲保證回報比率",
        ar_guaranteedReturnRatioatAge105: "105歲保證回報比率",
        ar_projectedReturnRatioatAge: "$num歲預期回報比率",
        ar_projectedReturnRatioatAge75: "75歲預期回報比率",
        ar_projectedReturnRatioatAge85: "85歲預期回報比率",
        ar_projectedReturnRatioatAge105: "105歲預期回報比率",
        ar_guranteedBreakevenYear: "保證回本年",
        ar_overview: "概括",
        ar_guaranteedReturn: "保證回報",
        ar_projectedReturn: "預期回報",
        ar_earlySurrenderCoverage: "早期退保保障",
        ar_10LifeRatings: "10Life評分",
        ar_firstYearPremium: "首年供款",
        ar_premiumTerm: "供款期",
        ar_totalPremiumPaid: "總供款",
        ar_guaranteedBenefitPerPermiumPaidatMaturity: "期滿保證回本率",
        ar_guaranteedInitialAnnualIncome: "首年保證年金",
        ar_projectedInitialAnnualIncome: "首年預期年金",
        ar_percentageofTotalIncomeBeingGuaranteed: "保證入息佔總入息的百分比",
        ar_guaranteedBreakevenYear: "保證回本年",
        ar_guaranteedInternalRateofReturnIRR: "保證內部回報率(IRR)",
        ar_guaranteedReturnRatioatAgeNum: "$num歲保證回報比率",
        ar_projectedReturnRatioatAgeNum: "$num歲預期回報比率",
        ar_surrenderatAge: "於$num歲時退保",
        ar_guaranteedInternalRateofReturnIRRwith17TaxSavings: "保證內部回報率(IRR)以17%扣稅率計算",
        ar_totalGuaranteedIncomeAge: "$num歲",
        ar_totalGuaranteedIncomeAge4549: "45 - 49歲保證收入總額",
        ar_totalGuaranteedIncomeAge5054: "50 - 54歲保證收入總額",
        ar_totalGuaranteedIncomeAge5559: "55 - 59歲保證收入總額",
        ar_totalGuaranteedIncomeAge6064: "60 - 64歲保證收入總額",
        ar_totalGuaranteedIncomeAge6569: "65 - 69歲保證收入總額",
        ar_totalGuaranteedIncomeAge5059: "50-59歲保證年金總收入",
        ar_totalGuaranteedIncomeAge6069: "60-69歲保證年金總收入",
        ar_totalGuaranteedIncomeAge7079: "70-79歲保證年金總收入",
        ar_totalGuaranteedIncomeAge8089: "80-89歲保證年金總收入",
        ar_totalGuaranteedIncomeAge9099: "90-99歲保證年金總收入",
        ar_totalGuaranteedIncomeAge100109: "100-109歲保證年金總收入",
        ar_totalProjectedIncomeAge: "$num歲",
        ar_totalProjectedIncomeAge4549: "45 - 49歲預期收入總額",
        ar_totalProjectedIncomeAge5054: "50 - 54歲預期收入總額",
        ar_totalProjectedIncomeAge5559: "55 - 59歲預期收入總額",
        ar_totalProjectedIncomeAge6064: "60 - 64歲預期收入總額",
        ar_totalProjectedIncomeAge6569: "65 - 69歲預期收入總額",
        ar_totalProjectedIncomeAge5059: "50-59歲預期年金總收入",
        ar_totalProjectedIncomeAge6069: "60-69歲預期年金總收入",
        ar_totalProjectedIncomeAge7079: "70-79歲預期年金總收入",
        ar_totalProjectedIncomeAge8089: "80-89歲預期年金總收入",
        ar_totalProjectedIncomeAge9099: "90-99歲預期年金總收入",
        ar_totalProjectedIncomeAge100109: "100-109歲預期年金總收入",
        ar_surrenderatThe10thPolicyYear: "於投保後10年退保",
        ar_surrenderatThe20thPolicyYear: "於投保後20年退保",
        ar_totalGuaranteedIncomeAge5564: "55 - 64歲保證收入總額",
        ar_totalGuaranteedIncomeAge6574: "65 - 74歲保證收入總額",
        ar_totalGuaranteedIncomeAge7584: "75 - 84歲保證收入總額",
        ar_projectedBreakevenYear: "預期回本年",
        ar_projectedInternalRateofReturnIRR: "預期內部回報率(IRR)",
        ar_projectedInternalRateofReturnIRRwith17TaxSavings: "預期內部回報率(IRR)以17%扣稅率計算",
        ar_totalProjectedIncomeAge5564: "55 - 64歲預期收入總額",
        ar_totalProjectedIncomeAge6574: "65 - 74歲預期收入總額",
        ar_totalProjectedIncomeAge7584: "75 - 84歲預期收入總額",
        ar_guaranteedBenefitPerPremiumPaid: "保證回本率",
        ar_surrenderatthe10thpolicyyear: "於投保後10年退保",
        ar_surrenderatthe20thpolicyyear: "於投保後20年退保",
        al_issueAge: "投保年齡",
        al_paymentTermYearly: "供款年期",
        al_annuityStartAge: "年金開始年齡",
        al_incomeTerm: "年金領取期",
        al_totalPremium: "總供款",
        al_currency: "貨幣",
        al_insurer: "保險公司",
        al_longevityAnnuityScore: "10Life評分",
        al_guaranteedInitialAnnualIncomeHightoLow: "首年保證年金 (高至低)",
        al_guaranteedReturnRatioatAge85HightoLow: "85歲保證回報比率 (高至低)",
        al_guaranteedReturnRatioatAge105HightoLow: "105歲保證回報比率 (高至低)",
        al_projectedReturnRatioatAge85HightoLow: "85歲預期回報比率 (高至低)",
        al_projectedReturnRatioatAge100HightoLow: "100歲預期回報比率 (高至低)",
        al_projectedReturnRatioatAge105HightoLow: "105歲預期回報比率 (高至低)",
        al_annuityPeriodShorttoLong: "年金領取期 (短至長)",
        al_mostPopularHightoLow: "最受歡迎 (高至低)",
        al_userRatingHightoLow: "用戶評分 (高至低)",
        al_guaranteedInitialAnnualIncome: "首年保證年金",
        al_guaranteedReturnRatioatAge85: "85歲保證回報比率",
        al_guaranteedReturnRatioatAge100: "100歲保證回報比率",
        al_projectedReturnRatioatAge85: "85歲預期回報比率",
        al_projectedReturnRatioatAge100: "100歲預期回報比率",
        al_annuityPeriod: "年金領取期",
        al_firstYearPremium: "首年供款",
        al_premiumTerm: "供款期",
        al_totalPremiumPaid: "總供款",
        al_projectedInitialAnnualIncome: "首年預期年金",
        al_percentageofTotalIncomeBeingGuaranteed: "保證入息佔總入息的百分比",
        al_overview: "概括",
        al_guaranteedReturn: "保證回報",
        al_projectedReturn: "預期回報",
        al_lengthofCoverageTerm: "保障期長度",
        al_earlySurrenderCoverage: "早期退保保障",
        al_earlyDeathCoverage: "早期身故保障",
        al_guaranteedAnnuityBreakevenYear: "保證回本年",
        al_guaranteedAnnuityInternalRateofReturnIRR: "保證年金內部回報率(IRR)",
        al_deathatAge: "於$num歲時身故",
        al_totalGuaranteedIncomeAge4554: "45 - 54歲保證收入總額",
        al_totalGuaranteedIncomeAge5564: "55 - 64歲保證收入總額",
        al_totalGuaranteedIncomeAge6574: "65 - 74歲保證收入總額",
        al_totalGuaranteedIncomeAge7584: "75 - 84歲保證收入總額",
        al_totalGuaranteedIncomeAge8594: "85 - 94歲保證收入總額",
        al_totalGuaranteedIncomeAge95104: "95 - 104歲保證收入總額",
        al_totalGuaranteedIncomeAge105114: "105 - 114歲保證收入總額",
        al_guaranteedYearlyIncomeatAge105: "105歲",
        al_projectedAnnuityBreakevenYear: "預期回本年",
        al_projectedAnnuityInternalRateofReturnIRR: "預期年金內部回報率(IRR)",
        al_totalProjectedIncomeAge4554: "45 - 54歲預期收入總額",
        al_totalProjectedIncomeAge5564: "55 - 64歲預期收入總額",
        al_totalProjectedIncomeAge6574: "65 - 74歲預期收入總額",
        al_totalProjectedIncomeAge7584: "75 - 84歲預期收入總額",
        al_totalProjectedIncomeAge8594: "85 - 94歲預期收入總額",
        al_totalProjectedIncomeAge95104: "95 - 104歲預期收入總額",
        al_totalProjectedIncomeAge105114: "105 - 114歲預期收入總額",
        al_incomePeriod: "入息期",
        al_guaranteedBenefitPerPremiumPaid: "保證回本率",
        al_surrenderatThe10thPolicyYear: "於投保後10年退保",
        al_surrenderatThe20thPolicyYear: "於投保後20年退保",
        al_guaranteedBenefitPerPremiumPaidIncludingDeathBenefitReceivedIncomeDeathBenefitPremiumPaid: "保證回本率，包含身故賠償: (已收取入息 + 身故賠償) ÷ 已付保費",
        al_deathatThe10thPolicyYear: "於投保後10年身故",
        al_deathatThe20thPolicyYear: "於投保後20年身故",
        al_wholelife: "終身",
        ai_categoryName: "即期年金",
        ai_pageTitle: "比較即期年金",
        ai_quickGuide: "年金懶人包",
        ai_result: "我們找到<strong>$num</strong>個符合篩選條件的即期年金！",
        ai_incomeTerm: "年金領取期",
        ai_totalPremium: "總供款",
        ai_currency: "貨幣",
        ai_insurer: "保險公司",
        ai_immediateAnnuityScore: "10Life評分",
        ai_5StarRatings: "5星評級",
        ai_guaranteedInitialAnnualIncomeHightoLow: "首年保證年金 (高至低)",
        ai_guaranteedReturnRatioatAge105HightoLow: "105歲保證回報比率 (高至低)",
        ai_projectedReturnRatioatAge105HightoLow: "105歲預期回報比率 (高至低)",
        ai_annuityPeriodShorttoLong: "年金領取期 (短至長)",
        ai_mostPopularHightoLow: "最受歡迎 (高至低)",
        ai_userRatingHightoLow: "用戶評分 (高至低)",
        ai_longevityAnnuityScore: "10Life評分",
        ai_guaranteedIRRatAge85: "85歲保證IRR",
        ai_1stYearGuaranteedIncome: "首年保證年金",
        ai_1stYearExpectedIncome: "首年預期年金",
        ai_annuityPeriod: "年金領取期",
        ai_firstYearPremium: "首年供款",
        ai_premiumTerm: "供款期",
        ai_totalPremiumPaid: "總供款",
        ai_percentageofTotalIncomeBeingGuaranteed: "保證入息佔總入息的百分比",
        ai_overview: "概括",
        ai_guaranteedReturn: "保證回報",
        ai_projectedReturn: "預期回報",
        ai_lengthofCoverageTerm: "保障期長度",
        ai_earlySurrenderCoverage: "早期退保保障",
        ai_earlyDeathCoverage: "早期身故保障",
        ai_guaranteedAnnuityBreakevenYear: "保證年金回本年",
        ai_guaranteedAnnuityInternalRateofReturnIRR: "保證年金內部回報率(IRR)",
        ai_deathatAge: "於$num歲時身故",
        ai_totalGuaranteedIncomeAge4554: "45 - 54歲保證收入總額",
        ai_totalGuaranteedIncomeAge5564: "55 - 64歲保證收入總額",
        ai_totalGuaranteedIncomeAge6574: "65 - 74歲保證收入總額",
        ai_totalGuaranteedIncomeAge7584: "75 - 84歲保證收入總額",
        ai_totalGuaranteedIncomeAge8594: "85 - 94歲保證收入總額",
        ai_totalGuaranteedIncomeAge95104: "95 - 104歲保證收入總額",
        ai_totalGuaranteedIncomeAge105114: "105 - 114歲保證收入總額",
        ai_guaranteedYearlyIncomeatAge105: "105歲",
        ai_projectedAnnuityBreakevenYear: "預期年金回本年",
        ai_projectedAnnuityInternalRateofReturnIRR: "預期年金內部回報率(IRR)",
        ai_totalProjectedIncomeAge4554: "45 - 54歲預期收入總額",
        ai_totalProjectedIncomeAge5564: "55 - 64歲預期收入總額",
        ai_totalProjectedIncomeAge6574: "65 - 74歲預期收入總額",
        ai_totalProjectedIncomeAge7584: "75 - 84歲預期收入總額",
        ai_totalProjectedIncomeAge8594: "85 - 94歲預期收入總額",
        ai_totalProjectedIncomeAge95104: "95 - 104歲預期收入總額",
        ai_totalProjectedIncomeAge105114: "105 - 114歲預期收入總額",
        ai_incomePeriod: "入息期",
        ai_guaranteedBenefitPerPremiumPaid: "保證回本率",
        ai_surrenderatThe10thPolicyYear: "於投保後10年退保",
        ai_surrenderatThe20thPolicyYear: "於投保後20年退保",
        ai_guaranteedBenefitPerPremiumPaidIncludingDeathBenefitReceivedIncomeDeathBenefitPremiumPaid: "保證回本率，包含身故賠償: (已收取入息 + 身故賠償) ÷ 已付保費",
        ai_deathatThe10thPolicyYear: "於投保後10年身故",
        ai_deathatThe20thPolicyYear: "於投保後20年身故",
        ai_wholelife: "終身年金",
        ai_singlePremium: "整付保費",
        objective_savings: "儲蓄",
        objective_retirement: "退休收入",
        objective_stable_retirement: "穩定型收入",
        objective_longevity: "對沖長壽風險",
        objective_growth_retirement_income: "增長型收入",
        twenty_years_above: "20年以上"
      },
      protection: {
        pt_categoryName: "終身人壽",
        pt_pageTitle: "比較終身人壽保障",
        pt_quickGuide: "終身人壽保險懶人包",
        pt_result: "我們找到$num個符合篩選條件的終身人壽保障產品！",
        pt_yearpayment: "年供款",
        pt_paymentTermYearly: "供款年期",
        pt_currency: "貨幣",
        pt_gender: "性別",
        pt_age: "年齡",
        pt_totalPremium: "總供款",
        pt_insurer: "保險公司",
        pt_wholeLifeProtectionScore: "10Life評分",
        pt_5StarRatings: "5星評級",
        pt_age35_54AverageDeathBenefitHightoLow: "首20年平均身故賠償 (高至低)",
        pt_age55_84AverageDeathBenefitHightoLow: "21-50年平均身故賠償 (高至低)",
        pt_mostPopularHightoLow: "最受歡迎 (高至低)",
        pt_userRatingHightoLow: "用戶評分 (高至低)",
        pt_age35_54AverageDeathBenefit: "首20年平均身故賠償",
        pt_age55_84AverageDeathBenefit: "21-50年平均身故賠償",
        pt_annualPremium: "每年供款",
        pt_totalPremiumPaid: "總供款",
        pt_guaranteedBreakevenYear: "保證回本年",
        pt_overview: "概括",
        pt_guaranteedDeathBenefit: "保證身故賠償",
        pt_policyValue: "保單價值",
        pt_age35_44: "35-44歲",
        pt_age35: "35歲",
        pt_age36: "36歲",
        pt_age37: "37歲",
        pt_age38: "38歲",
        pt_age39: "39歲",
        pt_age40: "40歲",
        pt_age41: "41歲",
        pt_age42: "42歲",
        pt_age43: "43歲",
        pt_age44: "44歲",
        pt_age45_54: "45-54歲",
        pt_age45: "45歲",
        pt_age46: "46歲",
        pt_age47: "47歲",
        pt_age48: "48歲",
        pt_age49: "49歲",
        pt_age50: "50歲",
        pt_age51: "51歲",
        pt_age52: "52歲",
        pt_age53: "53歲",
        pt_age54: "54歲",
        pt_age55_64: "55-64歲",
        pt_age55: "55歲",
        pt_age56: "56歲",
        pt_age57: "57歲",
        pt_age58: "58歲",
        pt_age59: "59歲",
        pt_age60: "60歲",
        pt_age61: "61歲",
        pt_age62: "62歲",
        pt_age63: "63歲",
        pt_age64: "64歲",
        pt_age65_74: "65-74歲",
        pt_age65: "65歲",
        pt_age66: "66歲",
        pt_age67: "67歲",
        pt_age68: "68歲",
        pt_age69: "69歲",
        pt_age70: "70歲",
        pt_age71: "71歲",
        pt_age72: "72歲",
        pt_age73: "73歲",
        pt_age74: "74歲",
        pt_age75_84: "75-84歲",
        pt_age75: "75歲",
        pt_age76: "76歲",
        pt_age77: "77歲",
        pt_age78: "78歲",
        pt_age79: "79歲",
        pt_age80: "80歲",
        pt_age81: "81歲",
        pt_age82: "82歲",
        pt_age83: "83歲",
        pt_age84: "84歲",
        pt_age85_94: "85-94歲",
        pt_age85: "85歲",
        pt_age86: "86歲",
        pt_age87: "87歲",
        pt_age88: "88歲",
        pt_age89: "89歲",
        pt_age90: "90歲",
        pt_age91: "91歲",
        pt_age92: "92歲",
        pt_age93: "93歲",
        pt_age94: "94歲",
        pt_age95_99: "95-99歲",
        pt_age95: "95歲",
        pt_age96: "96歲",
        pt_age97: "97歲",
        pt_age98: "98歲",
        pt_age99: "99歲",
        pt_IRR: "第30年的內部回報率 (IRR)",
        pt_30year: "第30年",
        pt_cannotBreakeven: "不能回本",
        pt_year: "$year年",
        pt_yearRange: "$from-$to年",
        pt_first10Year: "首10年",
        pt_ordinalNumber: "第$num",
        pt_specialFeature: "特色保障",
        pt_earlyYearsBooster: "增額保障",
        pt_accidentalDeathBenefit: "意外身故保障",
        pt_terminalIllnessBenefit: "末期疾病保障",
        pt_premiumHoliday: "保費假期",
        pt_guaranteedInsurabilityOption: "保證可保權益",
        pt_premiumWaiver: "保費豁免保障",
        pt_deathSettlementOptions: "身故賠償選項",
        pt_options: "種"
      },
      deferredAnnuity: '尋找住院醫療保險',
      home: {
        hm_categoryName: "家居保",
        hm_ownerType: "物業用途",
        hm_tenant: "租客",
        hm_landlord: "業主",
        hm_owner: "業主自住",
        hm_buildingType: "物業類別",
        hm_mb: "私人樓宇",
        hm_vh: "村屋",
        hm_dh: "獨立屋",
        hm_sdh: "半獨立屋",
        hm_buildingAge: "樓齡 (年)",
        hm_buildingAgeFeature: "{replace_age}年樓齡",
        hm_grossArea: "建築面積 (平方呎)​",
        hm_grossAreaFeature: "建築面積{replace_range}平方呎",
        hm_saleableArea: "實用面積（平方呎）",
        hm_saleableAreaFeature: "實用面積{replace_range}平方呎",
        hm_planType: "保障範圍",
        hm_basic: "基本",
        hm_overallScore: "10Life評分",
        hm_homeContentsScore: "家居財物評分",
        hm_liabilityScore: "法律責任評分",
        hm_personalAccidentScore: "個人意外評分",
        hm_annualPremium: "每年保費 (HKD)",
        hm_firstYearPremium: "首年保費 (HKD)",
        hm_result: "我們找到$num個符合篩選條件的家居保險產品",
        hm_overview: "概括",
        hm_hcMaximumProtection: "家居財物總保障額",
        hm_llMaximumProtection: "法律責任保障額",
        hm_paMaximumProtection: "個人意外保障額",
        hm_propertyAgeMax: "受保樓齡上限（年）​",
        hm_propertyType: "受保樓宇類別",
        hm_homeContents: "家居財物",
        hm_legalLiabilities: "法律責任",
        hm_personalAccident: "個人意外",
        hm_maximumCoverage: "總保障額",
        hm_noAddLocCovered: "額外保障範圍",
        hm_newResidence: "新居",
        hm_perValOutdoor: "室外家居財物",
        hm_tempRes: "臨時住所",
        hm_noCausationsCovered: "承保的原因數目",
        hm_weather: "天氣",
        hm_fireLightening: "火災/閃電",
        hm_debrisRemoval: "清除碎屑",
        hm_duringTransit: "搬運途中",
        hm_burglary: "盜竊",
        hm_duringRepairs: "室內裝飾/維修期間",
        hm_malicious: "租戶惡意破壞 (只適用於業主(出租))",
        hm_severityInjuries: "所承保的傷害嚴重程度",
        hm_causationAccidents: "保障意外範圍",
        hm_keyTypesOfHomeContentsCovered: "涵蓋的主要物件類別​​",
        hm_limitPerItem: "每件限額（$）",
        hm_scoreDetails: "評分內容",
        hm_10life_selection: "10Life 精選",
        hm_nolimit: "不設上限 / 沒有指明",
      },

      travel: {
        td_result: "我們找到$num個符合篩選條件的旅遊保險產品！",
        td_sorting_adult_premium: "成人保費 (低至高)",
        td_adult_premium: "成人保費",
        td_overall_score: "10Life評分 ",
        td_medical_emergency_score: "醫療及緊急支援評分",
        td_accident_score: "個人意外評分",
        td_inconv_score: "旅程受阻評分",
        td_property_score: "個人財物評分",
        td_categoryName: "旅遊醫保",
        td_mobile_protection: "手提電話保障",
        td_physio: "物理治療覆診",
        td_car_rental: "租車自付額",
        td_extreme_sports: "極限運動醫療保障",
        td_loss_travel_doc: "遺失旅行證件",
        td_overview: "概括",
        td_section_medical_emergency: "醫療及緊急支援",
        td_section_property: "個人財物",
        td_section_trip_inconv: "旅程受阻",
        td_section_accident: "個人意外評分​",
        td_subscore_detail: "10Life評分概要",
        td_medical_score: "醫療評分",
        td_emergency_score: "緊急支援評分",
        td_delay_score: "延誤評分",
        td_trip_cancel_score: "旅程取消及提早完結評分",
        td_laptop_protection: "手提電腦保障",
        td_camera_protection: "相機及錄影器材保障",
        td_credit_card: "信用卡被盜用保障",
        td_oversea_hospital: "海外住院現金",
        td_chinese_med: "跌打覆診費用",
        td_chiro: "脊醫覆診費用",
        td_addition_loss_of_travel_doc: "因遺失旅行證件而衍生的額外交通及／或住宿費用",
        td_airline_cancel: "因航空公司倒閉被迫取消行程保障",
        td_agent_bankrupt: "因旅行社倒閉被迫取消行程保障",
        td_home_burglary: "因居所被入室盜竊",
        td_product_feature: "產品特色",
        td_dayUnit: "日",
        td_hourUnit: "小時",
        td_detail_chinese_med: "跌打保障詳情",
        td_detail_chiro: "脊醫保障詳情",
        td_detail_physio: "物理治療保障詳情",
        td_detail_oversea_hos_cash: "海外住院現金詳情​",
        td_detail_compassionate_visit: "恩恤探訪保障詳情",
        td_detail_return_unattend_child: "兒童護送保障詳情",
        td_term_term: "條款",
        td_term_mobile: "手提電話保障條款",
        td_term_laptop: "手提電腦保障條款",
        td_term_benefit: "賠付條款",
        td_default_geo: "日本 / Japan",
        td_detail_adult_premium: "日成人保費",
        td_unlimited: "不設上限 / 實際費用",
        td_not_specified: "沒有列明，受條款所限",
        td_10life_selection: "10Life 精選",
        td_outbound: "出發",
        td_returning: "回程",
        td_feature_filter_title: "全部篩選",
        td_feature_selection: "點選你需要的保單特色",
        td_feature_reset: "清除所有選項",
        td_selected: "已篩選",
        td_number_of_feature: "項"
      },

      personal_accident: {
        pd_categoryName: "個人意外保",
        pd_occupation: '職業',
        pd_sum_assured: "保額",
        pd_flexible_sa: "可自選保額",
        pd_adult_premium: "成人保費",
        pd_sorting_adult_premium: "成人保費 (低至高)",
        pd_overall_score: "10Life評分",
        pd_death_dismember_score: "身故及斷肢保障評分",
        pd_death_dismember: "身故及斷肢保障",
        pd_medical_score: "醫療保障評分",
        pd_medical: "醫療保障",
        pd_physio_score: "物理治療保障評分",
        pd_physio_chinese: "物理治療及跌打保障​",
        pd_chinese_med: "跌打保障評分",
        pd_office_worker: "文職人員",
        pd_result: "我們找到$num個符合篩選條件的個人意外保險產品",
        pd_overview: "概括",
        pd_accident_death_score: "身故評分",
        pd_dismemberment_score: "斷肢保障評分",
        pd_death_coverage_sum_assured: "身故賠償百分比（基本保額）",
        pd_dismemberment_loss_all_toes: "喪失一隻腳所有腳趾或永久完全喪失其功能",
        pd_death_benefit: "身故賠償",
        pd_dismemberment_coverage_sum_assured: "斷肢賠償百分比（基本保額）",
        pd_dismemberment_loss_two_limbs: "喪失兩肢或永久完全喪失其功能",
        pd_dismemberment_loss_one_limb: "喪失一肢或永久完全喪失其功能",
        pd_dismemberment_loss_both_eyes: "雙目永久完全失明",
        pd_dismemberment_loss_one_eye: "單目永久完全失明",
        pd_dismemberment_loss_both_ears: "雙耳永久完全喪失聽覺",
        pd_dismemberment_loss_one_ear: "單耳永久完全喪失聽覺",
        pd_dismemberment_loss_speech: "永久完全喪失語言能力",
        pd_dismemberment_loss_thumbs_fingers_main: "喪失一隻拇指及四隻手指或永久完全喪失其功能 (主手)",
        pd_dismemberment_loss_thumbs_fingers_another: "喪失一隻拇指及四隻手指或永久完全喪失其功能 (副手)​",
        pd_medical_expenses: "醫療總保障額​",
        pd_medical_estimated_coverage: "各傷病的預計保障",
        pd_medical_case_1_recovery_rate: "骨科專科 (需MRI掃描)​",
        pd_medical_case_2_recovery_rate: "腦科專科 (需CT掃描)​",
        pd_medical_case_3_recovery_rate: "普通門診 (割傷，需打破傷風類毒素針)​",
        pd_medical_case_4_recovery_rate: "前十字韌帶手術",
        pd_physio_follow_up: "物理治療保障詳情",
        pd_physio_limit_per_policy_year: "物理治療次數限額",
        pd_physio_limit_per_visit: "物理治療保障每日限額",
        pd_physio_total_limit: "物理治療總保障額",
        pd_chinese_medical_follow_up: "跌打保障詳情",
        pd_bonesetter_limit_per_policy_year: "跌打次數限額",
        pd_bonesetter_limit_per_visit: "跌打保障每日限額",
        pd_bonesetter_total_limit: "跌打總保障額",
        pd_not_specified: "沒有指明",
        pd_na: "不適用",
        pd_firstYearPremium: "首年保費",
        pd_sorting_firstYear_premium: "首年保費 (低至高)",
        pd_min_issue_age: "最低投保年齡",
        pd_max_issue_age: "最高投保年齡",
        pd_max_cover_age: "最高續保年齡"
      },
    },
    product_promotion: {
      pop_up_text: '了解詳情',
      last_call: '優惠即將結束',
    },
    product_promotion_detail: {
      download: '下載',
      related_documents: '相關文件'
    },
    productShortcut: {
      happyKids: "開心保寶認證",
      fulfilmentRatio: "分紅實現率公司"
    },
    lifeStage: {
      card: {
        monthly: '每月',
        up: '起'
      }
    },
    scoring_methodology: {
      articleAnchor: '評分重點',
    },
    transitionPage: {
      goingToPart1: "正在前往",
      goingToPart2: "網站"
    },
    faqPage: {
      fp_pageTitle: "常見問題",
      fp_general: "一般問題",
      fp_methodology: "評分方法問題"
    },
    fiveStar: {
      awardProductSection: '得獎產品',
      awardProductButton: '了解更多',
      lastUpdateDate: '更新日期',
      filterMenuAll: '所有',
      relatedDoc: '相關文件',
      relatedDocButtonText: '下載'

    },

    datePicker: {
      title: '旅程',
      placeholder: '請選擇旅程日期'
    },
    h1_text: {
      homepage: '10Life：香港保險比較平台｜買保險 有比較 比較好',
      lifeInsurerOfTheYear: '年度人壽保險公司｜香港5星保險評級大獎 2022',
      healthInsurerOfTheYear: '年度健康保險公司｜香港5星保險評級大獎 2022',
      vhis: '自願醫保｜香港5星保險評級大獎 2022',
      highEndMedical: '高端醫療保險｜香港5星保險評級大獎 2022',
      wholeLifeCriticalIllness: '終身危疾保險｜香港5星保險評級大獎 2022',
      termCriticalIllness: '定期危疾保險｜香港5星保險評級大獎 2022',
      savingInsuranceForEducation: '儲蓄保險（教育升學）｜香港5星保險評級大獎 2022',
      savingInsuranceForRetirement: '儲蓄保險（退休規劃）｜香港5星保險評級大獎 2022',
      savingInsuranceForLegacy: '儲蓄保險（財富傳承）｜香港5星保險評級大獎 2022',
      wholeLifeProtection: '終身人壽保險｜香港5星保險評級大獎 2022'
    },

    filter: {
      dropDownSelect: "選取",
      dropDownRemove: "移除",
      no_result: "沒有相符的結果，請重新輸入。"
    },

    breadcrumb: {
      home: "主頁",
      products: {
        title: "保險比較",
        medical: "醫療保險",
        termCriticalIllness: "定期危疾保險",
        wholeLifeCriticalIllness: "終身危疾保險",
        termCancer: "定期癌症保險",
        shortTermSavings: "短期儲蓄保險",
        longTermSavings: "長期儲蓄保險",
        immediateAnnuity: "即期年金",
        deferredAnnuity: {
          default: "扣稅年金",
          // default: "扣稅年金（儲蓄）",
          retirement: "扣稅年金（退休）",
          longevity: "扣稅年金（長壽）"
        },
        termLife: "定期人壽保險",
        wholeLifeProtection: "終身人壽保險",
        personalAccident: "個人意外保險",
        home: "家居保險",
        travel :"旅行保險"
      },
      insurer: {
        title: "保險公司"
      },
      advisorService: {
        title: "顧問服務"
      },
      fiveStarProducts: {
        title: "5星保險大獎",
        InsurerOfTheYear: "年度保險公司",
        methodology: "評分方法"
      },
      happyKids: {
        title: "開心保寶認證",
        methodology: "認證方法"
      },
      scoringMethodology: {
        title: "評分方法",
        medicalCoverage: "醫療保險",
        wholeLifeCriticalIllness: "終身危疾保險",
        termCriticalIllness: "定期危疾保險",
        termCancer: "定期癌症保險",
        wholeLifeProtection: "終身人壽保險",
        termLife: "定期人壽保險",
        shortTermSavings: "短期儲蓄保險",
        longTermSavings: "長期儲蓄保險",
        retirementQdap: "退休年金 QDAP",
        savingsQdap: "儲蓄年金 QDAP",
        longevityQdap: "長壽年金 QDAP",
        immediateAnnuity: "即期年金",
        personalAccident: "人身意外保險",
        homeInsurance: "家居保險",
        travelSingleTrip :"旅行保險"
      },
      aboutUs: {
        title: "關於10Life"
      },
      latestPromotion: {
        title: "近期推廣"
      },
      media: {
        title: "傳媒報道"
      },
      faq: {
        title: "常見問題"
      },
      career: {
        title: "加入我們"
      },
      blog: {
        title: "網誌",
        search: "搜尋結果"
      },
      terms: {
        title: "使用條款"
      },
      privacy: {
        title: "私隱條款"
      },
      cookies: {
        title: "Cookie政策"
      }
    },
    insurerPage: {
      knowAbout: "認識",
      aboutRelatedProducts: "了解相關保險產品",
      moreProducts: "更多產品",
      products: "產品",
      faq: "常見問題",
    },
    happyKids: {
      product: {
        filter: {
          label: "篩選保險公司",
          all: "所有"
        }
      }
    },
    qdap: {
      questionnaire: {
        title: '扣稅年金點揀好？',
        desc: '市場上扣稅年金計劃花多眼亂，唔容易比較。立即回答幾條簡單問題，幫你篩選符合特定理財目標的產品！',
        disclaimer: {
          title: '重要聲明',
          content: '以上資訊僅供參考，並根據所輸入的資料及相關假設進行篩選生成。資訊並沒有考慮閣下之個人需要，閱覽有關資訊亦不應被視為正在進行個人合適性評估，亦不足以構成買入任何保險產品決定之基礎。無論你是否參考此資訊，在作出投保決定前，你必須衡量個人可承受風險的程度及你的財政狀況，徵詢持牌顧問的意見，並以保險公司提供的資料為準。你不應純粹單靠此資訊作出任何投保決定。'
        },
        btnStart: '立即開始',
        btnPrev: '上一步',
        btnNext: '下一步',
        btnReset: '重新開始',
        loadingMsg: '請等等，好快有結果',
        errorMsg: '沒有相應的產品，請重新輸入你的資料。',
        questions: [
          {
            question: '你今年幾歲？​',
            questionRemark: '以剛過去的生日計算​',
            answer: ['歲'],
            placeholder: '請選擇',
            tips: {
              title: '扣稅年金小貼士',
              content: '投保人須持有效香港身份證，年齡介乎18歲至80歲，視乎產品而定。每名納稅人的年金扣稅上限為每年60,000港元，以薪俸稅最高稅率17%計算，每年最多可慳稅10,200港元。'
            }
          },
          {
            question: '你買年金的目標？​',
            questionRemark: '除扣稅外​',
            answer: ['回本快​', '收長糧​'],
            tips: {
              title: '扣稅年金小貼士',
              content: '除了慳稅，扣稅年金用途包括儲蓄、為退休生活「自製長糧」及對沖長壽風險等。年金保費可滾存增值，同時讓受保人退休後有穩定收入來源。'
            }
          },
          {
            question: '你想幾歲開始拎？​',
            questionRemark: '預計的退休年齡或需要現金流的歲數​',
            answer: ['50歲', '55歲', '60歲', '65歲'],
            placeholder: '請選擇​理想的年金開始年齡',
            tips: {
              title: '扣稅年金小貼士',
              content: '保監局要求延期年金計劃的領取年齡為50歲以上。一般來說，滾存期越長，年金的累積金額就越大，受保人可領取的年金收入亦越高。'
            }
          },
          {
            question: '你想拎幾多年？​',
            questionRemark: '預計需要年金收入的年期',
            placeholder: '請選擇​理想的年金領取期',
            answer: ['10年', '20年', '20年以上'],
            tips: {
              title: '扣稅年金小貼士',
              content: '保監局規定延期年金的年金領取期最短為10年，部份計劃的年金領取期是直至100歲，以對沖長壽風險。不過，一般來說，年金期愈長，每月年金可能相對減少。所以，投保人因考慮個人的預期壽命、財務狀況，選擇合適的年金領取期。'
            }
          },
        ],
        productCard: {
          year: '年',
          around: '約',
          promotion: '限時優惠',
          btnWhatapp: 'WhatsApp 投保',
          incomeMsg: '以下扣稅年金的估算金額，年金期由{X}歲開始，可派發年金{Y}。',
          totalIncome: '10年總供款',
          errorMsg: {
            min: '因應保費最低要求，10年總供款不應低於180,000港元',
            max: '如需處理$1,000萬港元以上的10年供款，請與10Life顧問聯絡',
            maxBtnReadMore: '→ WhatsApp 查詢',
            numberOnly: '請輸入數字',
          },
          breakdown: {
            year: '保證回本年',
            guaranteedIrrY8: '第8年保證 IRR',
            guaranteedIrrY8ToolTip: '第8年提早退保的保證內部<span style="display: inline-block;">回報率（IRR）</span>',
            projectedIrrY8: '第8年預期 IRR',
            projectedIrrY8ToolTip: '第8年提早退保的預期內部<span style="display: inline-block;">回報率（IRR）</span>',
          },
          income: {
            firstYearAnnuity: '預期首年年金​',
            totalGuaranteedIncome: '{Y}保證總<em>年金</em>',
            totalProjectedIncome: '{Y}預期總<em>年金</em>',
            greaterThan20yP: '全期',
          },
          whatsappMsg: {
            breakdown: '回本快',
            income: '收長糧',
            incomeAge: '年金開始年齡：{A}歲',
            incomePeriod: '年金領取期：{Y}',
            tenYearsIncomePeriod: '10年總供款：HKD {Y},000',
            tenYearsIncomePeriodInquiry: '10年總供款：>HKD {Y},000',
            msgLine1: '我看到「扣稅年金點揀好？」，想了解 {N}',
            msgLine1Inquiry: '我看到「扣稅年金點揀好？」，想了解更多',
            msgLine2: '年齡：{A}歲',
            msgLine3: '年金目標：{G}',
          }
        },
        remarks: {
          title: '註：',
          content: ['以上顯示回報數字，僅供參考之用，一切應以保險公司資料為準。實際回報數字受年齡、投保額、保單貨幣和匯率、稅務狀況等因素影響。', '以上回報數字假設保單持有人是 45 歲非吸煙男性、 5 年供款期、按產品當時提供的折扣優惠後（如有）總供款為 30 萬港元（以美元保單為基準折算，並未計及保費徵費），以及保單持有人於供款期間每年可節省10,200 港元稅款的情景下，計算保證及預期內部回報率。​']
        },
        assumption: {
          title: '註：',
          content: ['以上顯示金額為估算金額，僅供參考之用，一切應以保險公司資料為準。實際年金金額受年齡、投保額、保單貨幣和匯率、滾存及入息期等因素影響。​​', '顯示金額假設保單持有人於特定歲數開始，分 10 年合共供款指定金額，並未計及保費徵費及任何優惠。','如選取派發年期 20 年以上，「全期」指至 105 歲（如適用）的總年金以作比較。'],
        }
      }
    }
  }
}
