import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      anyModalIsOn: 'modal/anyModalIsOn',
    }),
  },

  beforeMount() {
    this.addScrollTopEventHandler();
  },

  beforeDestroy() {
    this.removeScrollTopEventHandler();
  },

  mounted() {
    this.$watch('anyModalIsOn', (newVal) => {
      if (newVal === true) {
        document.querySelector('body').style.height = 'auto';
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.height = '100%';
        document.querySelector('body').style.overflow = 'visible';
      }
    }, {
      immediate: true
    })
  },

  methods: {
    addScrollTopEventHandler() {
      window.addEventListener(
        'scroll',
        this.$_.debounce(this.handleScroll, 150)
      );
    },

    removeScrollTopEventHandler() {
      window.removeEventListener('scroll', this.handleScroll);
    },

    handleScroll() {
      if (this.anyModalIsOn) return;

      if (document.documentElement.scrollTop >= 10) {
        this.$refs.headerComponent?.toggleWhiteStyle(true, false);
      } else {
        this.$refs.headerComponent?.toggleWhiteStyle(false, true);
      }
    },
  },
}