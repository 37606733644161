export default {
  mounted() {
  },
  
  created() {
    this.trackQS()
  },

  methods: {
    trackQS() {
      /** For tracking ad conversion to whatsapp and external with LGM
       * 1. ads and lgmads are the id for tracking ad conversion to whatsapp;
       * 2. ads, lgmads and utm_visual are the id for tracking ad conversion to external with LGM;
      */
      const adsId = this.$route.query.ads;
      const lgmadsId = this.$route.query.lgmads;
      const utmVisual = this.$route.query.utm_visual;
      if (adsId || lgmadsId) {
        this.removeCookies('_10life_ads');
        this.removeCookies('_10life_lgmads');
        this.removeCookies('_10life_utmVisual');
        if (adsId) {
          this.setCookies('_10life_ads', adsId, 7);
        }
        if (lgmadsId) {
          this.setCookies('_10life_lgmads', lgmadsId, 7);
        }
        if (utmVisual) {
          this.setCookies('_10life_utmVisual', utmVisual, 7);
        }
      }
    },
    getCookies(key) {
      return this.$cookies.get(key);
    },
    setCookies(key, value, days) {
      const maxAgeInSecond = days * 24 * 60 * 60;
      this.$cookies.set(key, value, { 
        maxAge: maxAgeInSecond,
        path: '/', });
    },
    removeCookies(key) {
      this.$cookies.remove(key);
    }
  },
}