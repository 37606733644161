
  export default {
    name: 'HomepageVideoListMobile',
  
    props: {
      data: {
        type: Array,
        default: () => ({
        }),
      },
    },
  
    data() {
      return {
        length: 3
      }
    },
    
    computed: {
      showLoadMore() {
        return this.length < this.data.length;
      },
  
      videosComputed() { 
        return this.data.slice(0, this.length)
      }
    },
    methods: {
      loadMore() {
        this.length = this.length + 3
      },
    },
  }
  