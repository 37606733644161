export default (i18n) => {
  return {
    ordinalSuffix(i) {
      const j = i % 10;
      const k = i % 100;
      if (j === 1 && k !== 11) {
        return i + 'st';
      }
      if (j === 2 && k !== 12) {
        return i + 'nd';
      }
      if (j === 3 && k !== 13) {
        return i + 'rd';
      }
      return i + 'th';
    },

    formatScore(score, place = 1, type) {
      if (!isNaN(score)) {
        if(!isNaN(place)){
          const multiplier = Math.pow(10, place || 0);
          if (type == 'wlci') {
            return (Math.round(score * multiplier)/ multiplier).toFixed(1);
          }
          return Math.round(score * multiplier) / multiplier;
          // return score.toFixed(place);
        }
        else return -1;
      }
      else {
        return -1;
      }
    },

    formatPercentage(number) {
      if (!isNaN(number)) {
        return (number * 100).toFixed(1) === '-0.0' ? '0.0%' : (number * 100).toFixed(1) + '%';
      } else {
        return -1;
      }
    },

    formatPercentageDP(number, dp) {
      if (!isNaN(number)) {
        return (number * 100).toFixed(dp) + '%';
      } else {
        return -1;
      }
    },

    formatMultiplier(multiplier) {
      return multiplier.toFixed(1) + 'X';
    },

    // !-- problematic line below -->
    formatPrice(price, type) {
      if (isNaN(price)) {
        return -1;
      } else if (type == 'travel') {
        return price.toFixed(2).toLocaleString('en-US');
      } else return parseInt(price.toFixed(0)).toLocaleString('en-US');
    },

    formatSymbol(value) {
      if (value === undefined || value === null) {
        return value;
      } else if (
        value.toString().toLowerCase() === 'true' ||
        value.toString().toLowerCase() === 'yes' ||
        value.toString() === '1'
      ) {
        return true;
      } else if (
        value.toString().toLowerCase() === 'false' ||
        value.toString().toLowerCase() === 'no' ||
        value.toString() === '0'
      ) {
        return false;
      } else {
        return value;
      }
    },

    formatShowFiveStarBadge(value) {
      if (value) {
        return parseInt(value) === 5;
      } else {
        return false;
      }
    },

    formatAge(locale, age, lblAge) {
      if (locale === 'en') {
        return lblAge + ' ' + age;
      } else if (locale === 'zh-Hant-HK') {
        return age + ' ' + lblAge;
      }
    },

    formatAgeRange(locale, from, to, lblAge, lblTo) {
      if (locale === 'en') {
        return lblAge + ' ' + from + ' ' + lblTo + ' ' + lblAge + ' ' + to;
      } else if (locale === 'zh-Hant-HK') {
        return from + lblTo + to + lblAge;
      }
    },

    formatIssueAgeRangeByUnit(locale, from, fromUnit, fromLabel, to, toUnit, toLabel, lblTo) {
      let tmpFrom = 0;
      let tmpTo = 0;
      let fromStr = '';
      let toStr = '';

      if (fromUnit === 'year') {
        tmpFrom = from / 365;
        if (locale === 'en') {
          fromStr = fromLabel + ' ' + tmpFrom;
        } else {
          fromStr = tmpFrom + ' ' + fromLabel;
        }
      } else if (fromUnit === 'month') {
        tmpFrom = from / 30;
        if (locale === 'en') {
          fromStr = tmpFrom + ' ' + fromLabel;
        } else {
          fromStr = tmpFrom + ' ' + fromLabel;
        }
      } else if (fromUnit === 'day') {
        tmpFrom = from;
        if (locale === 'en') {
          fromStr = tmpFrom + ' ' + fromLabel;
        } else {
          fromStr = tmpFrom + ' ' + fromLabel;
        }
      }

      if (toUnit === 'year') {
        tmpTo = to / 365;
        if (locale === 'en') {
          toStr = toLabel + ' ' + tmpTo;
        } else {
          toStr = tmpTo + ' ' + toLabel;
        }
      } else if (toUnit === 'month') {
        tmpTo = to / 30;
        if (locale === 'en') {
          toStr = tmpTo + ' ' + toLabel;
        } else {
          toStr = tmpTo + ' ' + toLabel;
        }
      } else if (toUnit === 'day') {
        tmpTo = to;
        if (locale === 'en') {
          toStr = tmpTo + ' ' + toLabel;
        } else {
          toStr = tmpTo + ' ' + toLabel;
        }
      }
      return fromStr + ' ' + lblTo + ' ' + toStr;
    },



    generateWhatsappCTALink(category, insurer, productName, extraData) {
      // const enMessage = 'Hi 10Life, I am interested in {replace_with_category} ({replace_with_product_name})';
      // const zhMessage = 'Hello 10Life，我想查詢{replace_with_category} （{replace_with_product_name}）';
      const whatsappAPITemplate =
        'https://api.whatsapp.com/send/?phone=85237051599&text={replace_with_message}&type=phone_number&app_absent=0';
      let message = i18n.t('products.whatsapp_enquiry_message');
      let encodedMessage = '';

      if (extraData?.page === 'blog-slug') {
        message = i18n.t('products.whatsapp_enquiry_message_blog');

        if(extraData?.link) {
          encodedMessage = encodeURIComponent(
            message.replace('{replace_with_link}', extraData?.link).replace('{replace_with_category}', category).replace('{replace_with_insurer}', insurer).replace('{replace_with_product_name}', productName)
          );
        } else {
          // if no link
          encodedMessage = encodeURIComponent(
            message.replace('{replace_with_category}', category).replace('{replace_with_insurer}', insurer).replace('{replace_with_product_name}', productName)
          );
        }
      } else {
        encodedMessage = encodeURIComponent(
          message.replace('{replace_with_category}', category).replace('{replace_with_insurer}', insurer).replace('{replace_with_product_name}', productName)
        );
      }

      return whatsappAPITemplate.replace('{replace_with_message}', encodedMessage);
    },

    adjustCTALink(link, insurerCode) {
      if (!link?.includes('whatsapp.com') && !link?.includes('wa.me') && !link?.includes(process.env.SALES_EXTERNAL_URL)) {
        return `/${this.$i18n.locale}/transition-checkout?insurer=${insurerCode}&link=${encodeURIComponent(link)}`;
      }
      return link;
    },
  };
};
