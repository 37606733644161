
export default {
  name: 'HomepageUniqueness',
  props: {
    data: {
      type: Object,
      default: () => ({
        Title: undefined,
      }),
    },
  },
  data() {
    return { 
      activeItemId: 0,
      activeMediaLogoContainer: false,
      circleAnimationToggle: [],
      activeItem: true,
      title: '測試一二三測試一二三',
      strapiData: [
        {
          id: 0,
          title: 'title1',
          description: 'description1 descriptiondescription descriptiondescription description',
          link: 'https://google.com',
        },
        {
          id: 1,
          title: 'title2',
          description: 'description2 descriptiondescription descriptiondescription description',
          link: 'https://google.com',
        },
        {
          id: 2,
          title: 'title3',
          description: 'description3 descriptiondescription descriptiondescription description',
          link: 'https://google.com',
        },
      ]
      
    }
  },
  computed: {
    backgroundImage() {
      if (this.data?.BackgroundImage?.url) {
        return {
          backgroundImage: `url(${this.data?.BackgroundImage?.url})`,
        }
      }
      return {};
    },
  },
  methods: {
    toggleActiveItem(i) {
      if (i === this.activeItemId) {
        this.activeItemId = -1
      } else {
        this.setActiveItem(i)
      }
    },

    setActiveItem(i) {
      this.activeItemId = i
    }
  },
}
