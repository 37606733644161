import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { default as Scheme$bc76 } from '/app/schemes/tenlifeScheme.js'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": true,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/",
    "logout": "/",
    "home": "/",
    "callback": "callback"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": false,
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "tenlife",
  "token": {
    "prefix": "_token.",
    "global": true
  }
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // tenlife
  $auth.registerStrategy('tenlife', new Scheme$bc76($auth, {
  "idToken": {
    "property": "id_token",
    "maxAge": 1800,
    "prefix": "_id_token.",
    "expirationPrefix": "_id_token_expiration."
  },
  "token": {
    "property": "access_token",
    "maxAge": 60
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 108000
  },
  "backendToken": {
    "property": "apollo_token"
  },
  "user": {
    "property": false
  },
  "scope": [
    "openid",
    "profile"
  ],
  "responseType": "code",
  "endpoints": {
    "configuration": "https://auth.10life.com/.well-known/openid-configuration",
    "login": {
      "authorization": "https://strapi-cms.10life.com/api/connect/tenlife",
      "backend": "https://strapi-cms.10life.com/api/auth/tenlife/callback",
      "token": "https://strapi-cms.10life.com/api/auth/tenlife/refresh",
      "userInfo": "https://strapi-cms.10life.com/api/users/me",
      "method": "get"
    }
  },
  "logoutRedirectUri": "https://www.10life.com",
  "autoLogout": false,
  "name": "tenlife"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
