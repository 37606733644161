
  import functionBarQuery from '~/apollo/queries/function-bar/functionBar';
  import eventTrackingMixins from '~/mixins/eventTrackingMixins.js';

  export default {
    name: 'FunctionBar',
    mixins: [eventTrackingMixins],
    apollo: {
      $loadingKey: 'loading',
      functionBars: {
        query: functionBarQuery,
        prefetch: true,
        variables() {
          return { currentlocale: this.$normalizeLocale(this.$i18n.locale) };
        },
        update(data) {
          return this.$normalizeStrapi(data.functionBars.data);
        }
      }
    },

    data() {
      return {
        loading: 0,
        functionBarActive: false,
        currentLocale: this.$normalizeLocale(this.$i18n.locale),
        functionBars: null,
        isAdvisorBlog: false,
        activeBar: false,
        lastScrollPosition: 0
      }
    },
    computed: {
      sortedFunctionBars() {
        if(!this.loading) {
          return this.functionBars?.filter((item) => item.show === true).slice().sort((a, b) => a.index - b.index);
        }
        else {
          return null;
        }
      },
      isHomepage() {
        return this.$route.fullPath === '/en/' || this.$route.fullPath === '/zh-HK/' || this.$route.fullPath === '/zh-CN/';
      }
    },
    mounted() {
        // check if the blog is advisor blog or not
        const urlParams = new URLSearchParams(window.location.search);
        this.isAdvisorBlog = urlParams.has('adid');

      this.onScroll();
      window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
      jumpToTop() {
        window.scrollTo(0, 0);
      },
      elementOffsetTop(el) {
        if ( el.length > 0 ) {
          return el[0].offsetTop;
        }
      },
      onScroll () {
        let offset = 80;

        if ( this.isHomepage ) {
          const homepageElementTop = this.elementOffsetTop(document.getElementsByClassName('homepage-media-recognition'));
          offset = Math.floor(homepageElementTop) - window.innerHeight + this.$refs.functionBar.clientHeight;
        }

        this.activeBar = window.scrollY > offset;
      },
      async handleClick(item) {
        const targetLink = item?.redirect_url;
        const eventAction = item?.TrackingParams?.event_action;
        const openInNewTab = item.open_new_tab === true;

        try {
          await this.handleTracking(targetLink, eventAction).then((targetUrl) => {
            if (openInNewTab)  {
              console.debug('new window');
              setTimeout(() => {
                window.open(targetUrl, '_blank');
              });
            } else {
              console.debug('self');
              window.location.href = targetUrl;
            }
          });
        } catch (error) {
          console.error('handleClick error:', error);
        }
      },
      async handleTracking(targetUrl, event_action) {
        const eventTrackingParams = this.initEventTrackingParams();
        const targetLink = targetUrl;
        const eventAction = event_action || '';

        console.debug('eventTrackingParams:', eventTrackingParams);
        console.debug('eventTrackingParams eventAction:', eventAction);
        if ( eventAction) {
          eventTrackingParams.a = eventAction;
          eventTrackingParams.l = targetLink;
          eventTrackingParams.c = 'function_bar';
          
          await this.sendEventTracking(eventTrackingParams, true);
        }

        return targetLink;
      }
    },
  };
  